import Payment from "../hooks/Payment.js";
import React from "react";
import { useState } from "react";
import '../cssStyling/layout.css';

interface ProcessPaymentProps {
    paymentPlan: any;
    setDisplayPayment: any;
    setDisplayPaymentSuccess: any;
    name: string;
    email: string;
    handleChangePlan: any;
    OrganizationSiteID: number;
    info: any;
    changeCardInfo: any;
}

const ProcessPayment: React.FC<ProcessPaymentProps> = ({changeCardInfo, info, handleChangePlan, paymentPlan, setDisplayPayment, setDisplayPaymentSuccess, name, email, OrganizationSiteID}) => {
    console.log({paymentPlan});
    const [paymentAmount, setPaymentAmount] = useState(paymentPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase() === 'payment amount').BotPlanRuleValue);
    const [planName, setPlanName] = useState(paymentPlan.BotPlanName);
    
    return (
        <>
            <div>
                <h1
                    style={{
                        backgroundColor: 'var(--dark-blue)',
                        border: '3px solid var(--light-blue)',
                        textShadow: '-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)',
                        color: 'white',
                        width: '100%',
                        textAlign: 'center',
                        padding: '10px',
                        marginTop: '0px'
                    }}
                    className="orgadmin-heading"
                >
                    Payment
                </h1>
                <p style={{color: 'var(--light-blue)'}}>Rest assured, your payment information will not be stored. We use Stripe, a highly trustworthy third-party service, to process your payments in a safe and secure manner.</p>

                <div className="subscription-summary-container">
                    <h1 className="apply-font-sub">Your subscription</h1>
                    <div className="subscription-summary-plan-container">
                        <h2 className="apply-font-sub apply-font-2">{planName}</h2>
                        <h2 className="apply-font-sub apply-font-2">${paymentAmount} USD billed monthly</h2>
                    </div>
                    <div className="subscription-summary-total-container">
                        <h1 className="apply-font-sub">Total</h1>
                        <h2 className="apply-font-sub">${paymentAmount} USD</h2>
                    </div>
                </div>
                <Payment changeCardInfo={changeCardInfo} OrganizationID={info.user.OrganizationID} handleChangePlan={handleChangePlan} BotPlanID={paymentPlan.BotPlanID} paymentAmount={paymentAmount} setDisplayPayment={setDisplayPayment} setDisplayPaymentSuccess={setDisplayPaymentSuccess} name={name} email={email} />
            </div>
        </>
    );
}

export default ProcessPayment;
