import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, SetupIntentResult } from '@stripe/stripe-js';
import config from '../config';

const stripePromise = loadStripe('pk_test_51PdHQX2NRyWcEYIdXYKMF7XSFPW3C16Uxi0XEgxXDI2fFRUzUVlMA47f28KsM0OkBN932vkrC6k0cpoMXgRkR39n00MZVRWqow');

interface UpdatePaymentMethodProps {
    customerId: string;
    setDisplayPaymentChange: any;
    setDisplayConfirmCard: any;
    changeCardInfo: any;
}

const UpdatePaymentMethod: React.FC<UpdatePaymentMethodProps> = ({ changeCardInfo, customerId, setDisplayConfirmCard, setDisplayPaymentChange }) => {
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    useEffect(() => {
        // Fetch the client secret for the setup intent
        fetch(`${config.apiUrl}/create-setup-intent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customerId }),
        })
        .then(res => res.json())
        .then(data => setClientSecret(data.clientSecret));
    }, [customerId]);
    return (<>
    {stripePromise && clientSecret &&
    <Elements stripe={stripePromise} options={{ clientSecret }}>
        <UpdatePaymentMethod2 changeCardInfo={changeCardInfo} customerId={customerId} clientSecret={clientSecret} setDisplayConfirmCard={setDisplayConfirmCard} setDisplayPaymentChange={setDisplayPaymentChange}/>
    </Elements>
}
    </>)
}
    
interface UpdatePaymentMethod2Props {
    customerId: string;
    clientSecret: string;
    setDisplayPaymentChange: any;
    setDisplayConfirmCard: any;
    changeCardInfo: any;

}

const UpdatePaymentMethod2: React.FC<UpdatePaymentMethod2Props> = ({changeCardInfo, customerId, clientSecret, setDisplayConfirmCard, setDisplayPaymentChange }) => {
    
    const stripe = useStripe();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState<string | null>(null);

    

    const handlePaymentMethodChange = async () => {
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
            },
            redirect: 'if_required',
        }) as SetupIntentResult;

        if (result.error) {
            setPaymentError(result.error.message || 'An error occurred');
            console.log('An error occurred', result.error.message);
        } else if (result.setupIntent) {
            console.log('Payment method updated successfully', result.setupIntent.payment_method);
            // Call your backend to update the default payment method
            const response = await fetch(`${config.apiUrl}/update-default-payment-method`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ customerId, paymentMethodId: result.setupIntent.payment_method }),
            });

            if (response.ok) {
                console.log('Default payment method updated successfully');
                const update = await response.json();
                await changeCardInfo(update);
            } else {
                console.error('Failed to update default payment method');
                setPaymentError('Internal Server Error: Failed to update default payment method');
            }
            
            setDisplayConfirmCard(true);
            setDisplayPaymentChange(false);
           
        } else {
            console.log('An error occurred');
        }
    };

    return (
        clientSecret ? (
            
                <div>
                    <div>
                        <PaymentElement />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', placeContent: 'center'}}>
                    {paymentError && <p style={{ color: 'red', alignSelf: 'center' }}>{paymentError}</p>}
                    <button style={{padding: '8px', alignSelf: 'center', minWidth: '70%'}} className="cancel-subscription-next-button" onClick={handlePaymentMethodChange}>
                        Save Payment Method
                    </button>
                    <button style={{margin: '0'}} className="cancel-subscription-back-button" onClick={(e) => setDisplayPaymentChange(false)}>
                        Cancel
                    </button>

                    </div>
                    
                    
                </div>
          
        ) : (
            <p>Loading...</p>
        )
    );
};

export default UpdatePaymentMethod;
