import React, { useState } from 'react';
import '../cssStyling/layout.css'; // Make sure to create and link your CSS file

interface Metadata {
    id: number;
    deleted: boolean;
    edited: boolean;
  name: string;
  value: string;
}

interface MetadataComponentProps {
  metadataPairs: Metadata[];
  setMetadataPairs: React.Dispatch<React.SetStateAction<Metadata[]>>;
}

const MetadataComponent: React.FC<MetadataComponentProps> = ({ metadataPairs, setMetadataPairs }) => {
  console.log("MetadataComponent");
  console.log({metadataPairs});
 
 

  const handleAddMetadataPair = () => {
    setMetadataPairs([...metadataPairs, { id: -1, edited: false, deleted: false, name: '', value: '' }]);
  };

  const handleDeleteMetadataPair = (index: number) => {
    setMetadataPairs(metadataPairs.map((meta, i) => (i === index ? { ...meta, deleted: true } : meta)));
  };

  const handleNameChange = (index: number, newName: string) => {
    const newMetadataPairs = [...metadataPairs];
    newMetadataPairs[index].name = newName;
    newMetadataPairs[index].edited = true;
    setMetadataPairs(newMetadataPairs);
  };

  const handleValueChange = (index: number, newValue: string) => {
    const newMetadataPairs = [...metadataPairs];
    newMetadataPairs[index].value = newValue;
    newMetadataPairs[index].edited = true;
    setMetadataPairs(newMetadataPairs);
  };

  return (
    <div className='orgadmin-metadata-container'>
      
      <div className='orgadmin-metadata-heading'>
        <h4>Name</h4>
        <h4>Value</h4>
      </div>
      {metadataPairs.map((metadata, index) => {
        if (metadata.deleted) return null;
        return (
        <div className='orgadmin-metadata-input' key={index}>
          <input className='orgadmin-metadata-input-name'
            type="text"
            placeholder="Enter here..."
            maxLength={49}
            value={metadata.name}
            onChange={(e) => handleNameChange(index, e.target.value)}
          />
          <input
            className='orgadmin-metadata-input-value'
            type="text"
            maxLength={249}
            placeholder="Enter here..."
            value={metadata.value}
            onChange={(e) => handleValueChange(index, e.target.value)}
          />
          <div className='orgadmin-metadata-add-or-sub'>
            <p className="orgadmin-metadata-sub" onClick={() => handleDeleteMetadataPair(index)}>-</p>
          </div>
        </div>
)})}
      <button style={{alignSelf: 'center', width: '65%', padding: '3px', height: 'auto'}} className='orgadmin-button-save' onClick={handleAddMetadataPair}>Add Metadata</button>
    </div>
  );
};

export default MetadataComponent;
