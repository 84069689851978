import React, { useEffect, useState } from 'react';
import config from '../config';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import '../cssStyling/layout.css';

interface DraggableListProps {
  type: number;
  info: any;
  setLoading: (loading: boolean) => void;
  handleNewInfo: any;

}

interface SortableItemProps {
  id: string;
  index: number;
  orgIndex: number;
  isDeleted: boolean;
  handleEnableSite: (organizationSiteID: number) => void;
  
}

interface Site {
  name: string;
  OrganizationSiteID: number;
  PriorityOrder: number;
  isDeleted: boolean;
}

const SortableItem: React.FC<SortableItemProps> = ({ id, index, isDeleted, orgIndex, handleEnableSite }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled: isDeleted });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`list-item ${isDeleted ? 'disabled' : ''}`}
    >
      {isDeleted ? (
        <>
          <span>{id} - disabled</span>
          <button onClick={() => handleEnableSite(orgIndex)}>enable</button>
        </>
      ) : (
        <span>{index + 1}. {id}</span>
      )}
    </div>
  );
};

const DraggableList: React.FC<DraggableListProps> = ({  handleNewInfo, type, info, setLoading }) => {
  console.log('info at sortable:', info);
  const [priorityList, setPriorityList] = useState<Site[]>([]);
  const [deletedSites, setDeletedSites] = useState<any>(null);

  const handleGetPriorityList = async (): Promise<Site[]> => {
    const res = await fetch(`${config.apiUrl}/getDeletedOrgSites`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        OrganizationID: info.user.OrganizationID,
      }),
    });
    const deletedOrgSites: { OrganizationSiteName: string, OrganizationSiteID: number }[] = await res.json();
    

    let activeSites = info.user.OrganizationSite.map((site: { OrganizationSiteName: string; OrganizationSiteID: number; PriorityOrder: number }) => ({
      name: site.OrganizationSiteName,
      OrganizationSiteID: site.OrganizationSiteID,
      PriorityOrder: site.PriorityOrder,
      isDeleted: false,
    }));

    activeSites = activeSites.sort((a: any, b: any) => a.PriorityOrder - b.PriorityOrder);

    const deletedSites = deletedOrgSites.map((site) => ({
      name: site.OrganizationSiteName,
      OrganizationSiteID: site.OrganizationSiteID, 
      PriorityOrder: 0, // Deleted sites are not sorted by priority
      isDeleted: true,
    }));

    setDeletedSites(deletedOrgSites);

    return activeSites.concat(deletedSites);
  };

  useEffect(() => {
    const fetchPriorityList = async () => {
      const list = await handleGetPriorityList();
     
      setPriorityList(list);
    };
    fetchPriorityList();
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPriorityList((items) => {
        const oldIndex = items.findIndex(item => item.name === active.id);
        const newIndex = items.findIndex(item => item.name === over.id);
        const newPriorityList = arrayMove(items, oldIndex, newIndex);

        // Update priority order based on new position
        const updatedPriorityList = newPriorityList.map((item, index) => ({
          ...item,
          PriorityOrder: index + 1
        }));

         // Update info object
         info.user.OrganizationSite = info.user.OrganizationSite.map((site: any) => {
          const updatedSite = updatedPriorityList.find((item) => item.OrganizationSiteID === site.OrganizationSiteID);
          if (updatedSite) {
            return { ...site, PriorityOrder: updatedSite.PriorityOrder };
          }
          return site;
        });
       

        // Send updated priority to the backend
        fetch(`${config.apiUrl}/setPriorityNumbers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            OrganizationID: info.user.OrganizationID,
            priorities: updatedPriorityList.map(item => ({
              name: item.name,
              id: item.OrganizationSiteID,
              PriorityOrder: item.PriorityOrder,
            })),
          }),
        }).then(response => response.json())
          .then(data => {
            console.log(data.message);
          })
          .catch(error => {
            console.error('Error updating priorities:', error);
          });

        return updatedPriorityList;
      });
    }
    handleNewInfo(info);
  };

  const handleEnableSite = async (organizationSiteID: number) => {
    if (Number(info.user.BotPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase().includes('sites')).BotPlanRuleValue) <= info.user.OrganizationSite.length) {
      alert('You have reached the maximum number of sites allowed by your plan. Please upgrade your plan to enable more sites.');
      return;
    }
    setLoading(true);
    try {
      // Send the request to enable the site
      const response = await fetch(`${config.apiUrl}/enableSite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ OrganizationSiteID: organizationSiteID }),
      });

      info = await response.json();

      if (!response.ok) {
        setLoading(false);
        throw new Error('Failed to enable site');
      }

      // Update the info object to reflect the enabled site
      const updatedInfo = { ...info };
      const siteIndex = deletedSites.findIndex(
        (site: any) => site.OrganizationSiteID === organizationSiteID
      );

      

      console.log('siteIndex:', siteIndex);

      if (siteIndex !== -1) {
        // Enable the site and assign the next available priority order
        deletedSites[siteIndex].isDeleted = false;
        const maxPriorityOrder = Math.max(...updatedInfo.user.OrganizationSite.map((site: any) => site.PriorityOrder), 0);
        deletedSites[siteIndex].PriorityOrder = maxPriorityOrder + 1;

        // Update the state
        setPriorityList((prevPriorityList) =>
          prevPriorityList.map((site) =>
            site.OrganizationSiteID === organizationSiteID
              ? {
                  ...site,
                  isDeleted: false,
                  PriorityOrder: maxPriorityOrder + 1,
                }
              : site
          )
        );
      }
    } catch (error) {
      setLoading(false);
      console.error('Error enabling site:', error);
    }
    setLoading(false);
    handleNewInfo(info);  
  };

  return (
    <>
    { priorityList.length === 0 ? <div style={{color: 'var(--light-blue)'}}>Loading...</div> :
      
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={priorityList.map(item => item.name)}
        strategy={verticalListSortingStrategy}
      >
        <div className="list-container">
          {priorityList.map((item, index) => (
            <SortableItem 
              orgIndex={item.OrganizationSiteID}
            handleEnableSite={handleEnableSite} key={item.name} id={item.name} index={index} isDeleted={item.isDeleted} />
          ))}
        </div>
      </SortableContext>
    </DndContext>}
    </>
  );
};

export default DraggableList;
