import { useEffect, useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm.js";
import config from "../config.ts"; // Adjust the import if you're using a config file
import { Elements } from "@stripe/react-stripe-js";

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const hasRequestBeenMade = useRef(false); // useRef to track the request status

  useEffect(() => {
    fetch(`${config.apiUrl}/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      console.log(publishableKey);
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    console.log("use ref at payment.js");
    console.log(hasRequestBeenMade.current);
    if (!hasRequestBeenMade.current) {
      hasRequestBeenMade.current = true; // Set the ref to true before making the request
      console.log("WE ARE HERE!!!");
      fetch(`${config.apiUrl}/create-payment-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentAmount: props.paymentAmount,
          name: props.name,
          email: props.email,
        }),
      }).then(async (r) => {
        const { clientSecret, customerID, nextBillingDate } = await r.json();
        console.log(clientSecret);
        setClientSecret(clientSecret);
        setCustomerID(customerID);
        setNextBillingDate(nextBillingDate);
      });
    }
  }, [
    props.paymentAmount,
    props.name,
    props.email,
    props.OrganizationSiteID,
  ]);

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
    },
    business: {
      name: "Cyber Assistant",
    },
  };

  return (
    <>
      {stripePromise && clientSecret && customerID && nextBillingDate && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            nextBillingDate={nextBillingDate}
            customerID={customerID}
            OrganizationID={props.OrganizationID}
            changeCardInfo={props.changeCardInfo}
            handleChangePlan={props.handleChangePlan}
            BotPlanID={props.BotPlanID}
            setDisplayPayment={props.setDisplayPayment}
            setDisplayPaymentSuccess={props.setDisplayPaymentSuccess}
          />
        </Elements>
      )}
    </>
  );
}

export default Payment;
