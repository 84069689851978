import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import customerSupport from '../logos/customerSupport2.png';
import serviceRep from '../logos/serviceRep.png';
import bot from '../logos/android.png';
import SmartAssistName from '../logos/SmartAssistAINameR.png';
import SmartAssist from '../logos/SALogo.png';

function Features() {
  const [showNav, setShowNav] = useState(false);
    return (
        <>
         <header className="home-header">
         <div className="home-logo">
          <img src={SmartAssist} alt="SmartAssist Logo" />
          <img src={SmartAssistName} alt="SmartAssist AI" />
        </div>
        <nav className="home-nav">
          <div>
          <Link  style={{color: 'var(--pure-white)', fontSize: '1.2rem'}}  to="/">Home</Link>

          </div>
          
          <div>
          <Link style={{fontWeight: 'bold', color: 'var(--pure-white)', fontSize: '1.3rem'}} to="/features">Features</Link>
          <hr style={{border: '1px solid var(--pure-white)', width: '60%', marginTop: '0'}} />

          </div>
          <Link style={{color: 'var(--pure-white)', fontSize: '1.2rem'}} to="/contact">Contact Us</Link>
        </nav>
        <div className="home-auth-buttons">
          <Link to="/signup" className="home-signin-button">Sign Up</Link>
          <Link to="/login" className="home-signup-button">Login</Link>
        </div>
        <div onClick={(e) => setShowNav(!showNav)} className="home-header-responsive-menu">☰
        </div>
      </header>

      {showNav && (
        <nav className="home-responsive-nav">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "calc(100vw - 60px)",
              marginBottom: "30px",
              alignSelf: "center",
            }}
          >
            <div className="home-logo">
              <img src={SmartAssist} alt="SmartAssist Logo" />
              <img src={SmartAssistName} alt="SmartAssist AI" />
            </div>
            <div
              onClick={(e) => setShowNav(!showNav)}
              className="home-header-responsive-menu"
            >
              ☰
            </div>
          </div>

          <Link
            className="home-landing-page-responsive-link"
            style={{
              color: "var(--pure-white)",
              fontSize: "1.6rem",
              marginLeft: "30px",
            }}
            to="/"
          >
            Home
          </Link>
          <Link
            className="home-landing-page-responsive-link"
            
            style={{
              marginBottom: "15px",
              fontWeight: "bold",
              color: "var(--pure-white)",
              fontSize: "1.9rem",
              marginLeft: "30px",
              marginTop: '15px'
            }}
            to="/features"
          >
            Features
          </Link>
          <Link
            className="home-landing-page-responsive-link"
            style={{
              marginBottom: "15px",
              color: "var(--pure-white)",
              fontSize: "1.6rem",
              marginLeft: "30px",
            }}
            
            to="/contact"
          >
            Contact Us
          </Link>
        </nav>
      )}

      <section>
        <div className="features-customer-support">
        <div>
        <h1>Smart Customer Support Chatbot</h1>
        <p>Transform your customer service with SmartAssist AI. Our advanced chatbot ensures prompt and accurate responses, handling a wide range of queries to keep your customers satisfied and engaged. With customizable options, you can tailor the bot to reflect your brand's voice and standards.</p>
      </div>
      <img className="features-img" src={serviceRep} alt="customer support robot" width={390}/>
      </div>

      <div className="features-customer-support features-customer-support-contrast">
        <div>
            <h1 style={{textAlign: 'right'}} >Efficient Service Representative Assistance</h1>
            <p style={{textAlign: 'right'}}>Enhance your service team's efficiency with our intelligent assistant. By inputting your company's data, service representatives can quickly retrieve relevant information, streamline workflows, and provide superior service. SmartAssist AI acts as a reliable partner, making sure your team has the information they need at their fingertips</p>
        </div>
        <img className="features-img" src={customerSupport} alt="service representative" width={390}/>
      </div>

      <div className="features-customer-support">
        <div>
        <h1>Specialized Training Bots</h1>
        <p>Leverage SmartAssist AI for specialized training and support. Whether it's for educational platforms, technical support, or niche industry needs, our AI can be trained on specific content to provide precise and helpful guidance. Facilitate learning, improve user experience, and deliver expert assistance effortlessly.</p>
      </div>
      <img className="features-img" src={bot} alt="customer support robot" width={390}/>
      </div>

        </section>

        
<section className="home-get-in-touch home-my-get-in-touch">
    
    <button onClick={(e) => window.location.href='/contact'}>Contact Us</button>
    <div className="home-get-in-touch-text">
        <h2 style={{textAlign: 'right'}}>Get in Touch With Us</h2>
        <p style={{textAlign: 'right'}}>Have questions or need assistance? Contact us today!</p>
    </div>
</section>

<footer className="home-footer-content">
    <div >
        <div className="home-footer-logo">
        <img className="home-footer-logo-logo-img" src={SmartAssist} alt="SmartAssist Logo" />
        <img className="home-footer-logo-logo-text" src={SmartAssistName} alt="SmartAssist AI" />
        </div>
        <nav className="home-footer-nav">
        <Link className="home-footer-link"  to="/">Home</Link>
        <Link className="home-footer-link" to="/features">Features</Link>
        <Link className="home-footer-link" to="/contact">Contact Us</Link>
        <Link className="home-footer-link home-footer-link-signin"  to="/login">Sign In</Link>

        </nav>
        
        
    </div>
    <hr style={{border: '1px solid var(--pure-white)', width: '100%', margin: '20px auto', marginBottom: '5px'}} />
    <div className="home-footer-bottom">
        
    <p style={{color: 'var(--pure-white)'}}>&copy; 2024 SmartAssist AI. All rights reserved.</p>
    <div className="home-footer-socials">
    <svg xmlns="http://www.w3.org/2000/svg" style={{cursor: 'pointer'}} href='https://www.linkedin.com/in/mzmtechnologies/' height="25" width="22" viewBox="0 0 448 512"><path fill="#ffffff" d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"/></svg>
        </div>
        </div>
</footer>
        </>
    );
}

export default Features;