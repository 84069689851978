import React, { useEffect } from 'react';
import icon from '../logos/CyberChatIconWhite.png';
import waterDrop from '../logos/WaterDropNoBackground.png';
import '../cssStyling/layout.css';
import {useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../config';

function SignUpContinue() {
    const location = useLocation();
    let info = location.state?.info;
    console.log("Info arrived at signupcont: ", {info});
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [error, setError] = useState('');
    const [siteName, setSiteName] = useState('');
    const [siteDomain, setSiteDomain] = useState('');
    const [siteDescription, setSiteDescription] = useState('');
    const navigate = useNavigate();
    const [temperature, setTemperature] = useState<string>('0.01');
    const [topP, setTopP] = useState<string>('0.3');
    const [sites, setSites] = useState<any[]>([]);
    const [currentStep, setCurrentStep] = useState(2);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const sites =  await fetch(`${config.apiUrl}/getSites`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const sitesData = await sites.json();
            setSites(sitesData);
            console.log("Sites recieved!");
            console.log({sitesData});
        }
        fetchData();    
    }, []);
    if (!location.state) { 
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <h1 style={{fontSize: '30px'}}>Unauthorized</h1>
            </div>
        );
    }

    const validateDomain = (domain: string) => {
        const corsDomainRegex = /^(https?:\/\/)([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        return corsDomainRegex.test(domain);
      };
           

    const handleSignUp = async () => {
        setIsLoading(true);

        if (siteDomain && !validateDomain(siteDomain)) {
            setError('Invalid domain name. Valid formats include https://example.com and http://example.com');
            setIsLoading(false);
            return;
        }

        const standardizeDomain = (domain: string) => {
            // Remove protocol if present (http:// or https://)
            domain = domain.replace(/^(https?:\/\/)?(www\.)?/, '');
            // Convert to lowercase to ensure case-insensitive comparison
            return domain.toLowerCase();
        };
        
        for (const site of sites) {
            const standardizedSiteDomain = standardizeDomain(site.OrganizationSiteURL);
            const standardizedInputDomain = standardizeDomain(siteDomain);
        
            console.log("Sites: ", site);
            console.log("Standardized Site Domain: ", standardizedSiteDomain);
            console.log("Standardized Input Domain: ", standardizedInputDomain);
        
            if (standardizedSiteDomain === standardizedInputDomain) {
                setError('This domain is already in use.');
                setIsLoading(false);
                return;
            }
        }
        
        
        const validateForm = () => {
            if (
                !siteName.trim() ||
                !siteDomain.trim() ||
                !siteDescription.trim() 
            ) {
                setError('All fields are required.');
                return false;
            }
            setError('');
            return true;
        };
        if (!validateForm()) {
            setIsLoading(false);
            return;
        }

       

        const code = await fetch (`${config.apiUrl}/sendEmailCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: info.UserEmail,
            }),
        }).then((res) => res.json()).then((data) => {
            setIsLoading(false);
            return data.code;
        });
        const info1 ={
            UserBotPlanID: selectedPlan,
            OrganizationSiteName: siteName,
            OrganizationSiteURL: siteDomain,
            OrganizationSiteDescription: siteDescription,
            UserFirstName: info.UserFirstName,
            UserPassword: info.UserPassword,
            UserLastName: info.UserLastName,
            UserJobTitle: info.UserJobTitle,
            OrganizationName: info.OrganizationName,
            OrganizationDescription: info.OrganizationDescription,
            UserEmail: info.UserEmail, 
            botPlans: info.botPlans,
            code: code,
            temperature: temperature,
            topP: topP,

        }
        setIsLoading(false);
        navigate('/validateemail', { state: { info1 } });
    }
return (
    <>
        <div style={{cursor: isLoading? 'wait' : 'default'}} className='signup-container'>
            <div className='signup-container-left'>
                <img src={waterDrop} alt="Water Drop" className="signup-water-drop-img" />
                <h1>Let's elevate your business, <br />T O G E T H E R.</h1>
            </div>
             {/* Side Progress Panel */}
             <div className="signup-progress-panel">
                    <ul className="signup-progress-steps">
                    <li className={currentStep >= 1 ? 'completed' : ''}>
  Personal Information<br /> <br />
  Organizational Information
</li>

                        <li style={{color: 'var(--pure-white)'}} className={currentStep >= 2 ? 'active' : ''}>Sites <br /> <br /> Bot Configurations</li>
                        <li className={currentStep >= 3 ? 'active last-signup-progress-steps' : 'last-signup-progress-steps'}>Verify Your Account</li>
                    </ul>
                </div>
            <div className='signup-container-right'>

            <div className='signup-header'>
                    <div className="signup-logo-container">
                        <img src={icon} alt="Cyber Chat Icon" className="signup-icon-img" />
                    </div>
                    <p className='signup-signin-text'>Already have an account? <a href="/login">Sign in here!</a></p>
                </div>
                
                <div className='signup-form'>
                    <h1 style={{lineHeight: '0px', paddingBottom: '25px', marginTop: '30px'}}>Almost There...</h1>
                    {error && <p style={{ color: 'red', fontSize: '0.8rem', margin: '0px' }} className="error-message">{error}</p>}
                   

                    <h5 style={{ marginTop: '0px' }}>Sites</h5>
                    <div>

                        
                        <div>
                            <label>Site 1 Name</label>
                            <input value={siteName} onChange={(e) => setSiteName(e.target.value)} maxLength={99} type="text" />
                        </div>
                        <div>
                            <label>Site 1 Domain URL (https://example.com)</label>
                            <input value={siteDomain} onChange={(e) => setSiteDomain(e.target.value)} maxLength={249} type="text" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>Site 1 Description</label>
                            <input style={{width: '202%'}} value={siteDescription} maxLength={249}  onChange={(e) => setSiteDescription(e.target.value)} type="text" />
                        </div>
                       
                    </div>
                    <h5 style={{ marginTop: '0px' }}>Bot Configurations</h5>
                    <div>
                    <div>
                    <label style={{marginLeft: '20px', marginTop: '10px'}}>
                        <span  className="tooltip">?
                            <span style={{backgroundColor: 'var(--pure-white)', color: 'var(--light-blue)', fontSize: '0.8rem'}} className="tooltip-text">Regulates the creativity of the response. The closer this is to 0 the more focused the response will be and the bot will more closely stick to its training data. Conversely, values closer to 1 allows for more diverse responses.</span>
                        </span> Temperature
                    </label>
                            <input type="range" min="0.01" max="1" step="0.01" onChange={(e) => setTemperature(e.target.value)} value={temperature} />
                        </div>
                        <div >
                            <label style={{marginLeft: '20px', marginTop: '10px'}} >
                            <span className="tooltip">?
                            <span style={{backgroundColor: 'var(--pure-white)', color: 'var(--light-blue)', fontSize: '0.8rem'}} className="tooltip-text">Controls the randomness of the language used. The lower this is the more the model will use the most probable words from its trained data set which increases the repetition between answers. The higher the value the more the model will consider a wide variety of words for use.</span>
                        </span> Top P
                            </label>
                            <input type="range" min="0.01" max="1" step="0.01" onChange={(e) => setTopP(e.target.value)} value={topP} />
                            </div> 
                       
                    </div>
                    
                    
                    
                    <div className="signup-buttons-container">
                        <button style={{cursor: isLoading? 'not-allowed' : 'default'}} disabled={isLoading} className='signup-buttons-signup' onClick={handleSignUp}>Next</button>
                        <button className='signup-buttons-cancel' onClick={(e) => window.location.href = '/login'}>Cancel</button>
                    </div>

                </div>
                
            </div>
        </div>

    </>
);
}
export default SignUpContinue;