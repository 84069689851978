import React, { useState, useEffect } from 'react';

interface Props {
  numSitesAvailable: number;
  info: {
    user: {
      OrganizationSite: any;
    };
  };
  handleSiteSelection: any;
}

const SiteSelection: React.FC<Props> = ({ numSitesAvailable, info, handleSiteSelection }) => {
  const [selectedSites, setSelectedSites] = useState<string[]>([]);

  useEffect(() => {
    // Preselect the sites based on the PriorityOrder
    const preselected = info.user.OrganizationSite
      .sort((a: any, b: any) => a.PriorityOrder - b.PriorityOrder)
      .slice(0, numSitesAvailable)
      .map((site: any) => site.OrganizationSiteID);

    setSelectedSites(preselected);
  }, [info.user.OrganizationSite, numSitesAvailable]);

  const onSiteChange = (siteID: string) => {
    if (!selectedSites.includes(siteID)) {
      // If a new site is selected and the maximum number of sites is reached,
      // replace the first selected site with the new one.
      if (selectedSites.length === numSitesAvailable) {
        setSelectedSites(prevSelectedSites => 
          prevSelectedSites.slice(1).concat(siteID)
        );
      } else {
        // If less than the maximum, simply add the new site
        setSelectedSites(prevSelectedSites => 
          [...prevSelectedSites, siteID]
        );
      }
    }
  };

  useEffect(() => {
    // Calculate the OrganizationSiteIDs that are not selected
    const deselectedSiteIDs = info.user.OrganizationSite
      .filter((site:any) => !selectedSites.includes(site.OrganizationSiteID))
      .map((site:any) => site.OrganizationSiteID);
  
    // Trigger the selection handler with deselected OrganizationSiteIDs
    handleSiteSelection(deselectedSiteIDs);
  }, [selectedSites, info.user.OrganizationSite]);
  

  return (
    <div>
      <p style={{ color: 'var(--light-blue)' }}>
        Choose {numSitesAvailable} sites you would like to keep, the rest will be automatically disabled.
      </p>
      {info.user.OrganizationSite.map((site: any) => (
        <div
          style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', marginLeft: '30px' }}
          key={site.OrganizationSiteID}
        >
          <input
            checked={selectedSites.includes(site.OrganizationSiteID)}
            style={{ width: '20px', marginRight: '10px' }}
            type="checkbox"
            id={site.OrganizationSiteID}
            name="site"
            value={site.OrganizationSiteID}
            onChange={() => onSiteChange(site.OrganizationSiteID)}
          />
          <label htmlFor={site.OrganizationSiteID}>{site.OrganizationSiteName}</label>
        </div>
      ))}
    </div>
  );
};

export default SiteSelection;
