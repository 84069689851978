import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import contactUs from '../logos/contactUsPage.png';
import SmartAssistName from '../logos/SmartAssistAINameR.png';
import SmartAssist from '../logos/SALogo.png';
import config from '../config';

const Contact: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [showNav, setShowNav] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const bod = { name, email, message };
            let response = await fetch (`${config.apiUrl}/contactUs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bod),
            });
            response = await response.json();
            console.log('Form submitted successfully:', response);
            // Handle success (e.g., display a success message or clear the form)
            setShowSuccess(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again later.');
            // Handle error (e.g., display an error message)
            setShowError(true);
        }
    };

    return (
        <>
         <header className="home-header">
         <div className="home-logo">
          <img src={SmartAssist} alt="SmartAssist Logo" />
          <img src={SmartAssistName} alt="SmartAssist AI" />
        </div>
        <nav className="home-nav">
        
          <Link style={{color: 'var(--pure-white)', fontSize: '1.2rem'}}  to="/">Home</Link>
          

          
          
          <Link style={{color: 'var(--pure-white)', fontSize: '1.2rem'}} to="/features">Features</Link>
          <div>
          <Link style={{fontWeight: 'bold', color: 'var(--pure-white)', fontSize: '1.3rem'}} to="/contact">Contact Us</Link>

          <hr style={{border: '1px solid var(--pure-white)', width: '70%', marginTop: '0'}} />
          </div>
        </nav>
        <div className="home-auth-buttons">
          <Link to="/signup" className="home-signin-button">Sign Up</Link>
          <Link to="/login" className="home-signup-button">Login</Link>
        </div>
        <div onClick={(e) => setShowNav(!showNav)} className="home-header-responsive-menu">☰
        </div>
      </header>

      {showNav && (
        <nav className="home-responsive-nav">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "calc(100vw - 60px)",
              marginBottom: "30px",
              alignSelf: "center",
            }}
          >
            <div className="home-logo">
              <img src={SmartAssist} alt="SmartAssist Logo" />
              <img src={SmartAssistName} alt="SmartAssist AI" />
            </div>
            <div
              onClick={(e) => setShowNav(!showNav)}
              className="home-header-responsive-menu"
            >
              ☰
            </div>
          </div>

          <Link
            className="home-landing-page-responsive-link"
            style={{
                marginBottom: "15px",
                color: "var(--pure-white)",
                fontSize: "1.6rem",
                marginLeft: "30px",
              }}
            to="/"
          >
            Home
          </Link>
          <Link
            className="home-landing-page-responsive-link"
            style={{
              marginBottom: "15px",
              color: "var(--pure-white)",
              fontSize: "1.6rem",
              marginLeft: "30px",
            }}
            to="/features"
          >
            Features
          </Link>
          <Link
            className="home-landing-page-responsive-link"
            style={{
                marginBottom: "15px",
                fontWeight: "bold",
                color: "var(--pure-white)",
                fontSize: "1.9rem",
                marginLeft: "30px",
                marginTop: '0px'
              }}
            to="/contact"
          >
            Contact Us
          </Link>
        </nav>
      )}
       
        <div className="contact-us-background">

{showSuccess && (
        <div className="payment-success-container">
      <div className="payment-success-card">
        <h1 style={{fontSize: '1.5rem'}}>Email Sent Successfully 🎉</h1>
        <p>Thank you for reaching out to us, we will get back to you soon.</p>
      </div>
    </div>
)}

{showError && (
        <div className="payment-success-container">
      <div className="payment-success-card">
        <h1 style={{fontSize: '1.5rem'}}>Email Failed to Send ❌</h1>
        <p>Sorry, something went wrong. Please try again later.</p>
      </div>
    </div>
)}
          
      {!showSuccess &&  !showError &&  (  <div className="contact-us-container">
            <h2 className="contact-get-in-touch-text">Get In Touch</h2>
            <p className="contact-get-in-touch-text-para">We are here for you! How can we help?</p>
            <div className="contact-card-container" >
                <div style={{width: '70%'}} className="contact-us-page-form">
                
                <form onSubmit={handleSubmit} >
                    <div style={{ marginBottom: '15px' }}>
                        <input
                        className="custom-input"
                            type="text"
                            placeholder="Enter your name..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            maxLength={499}
                            required
                        />
                    </div>
                    <div  style={{ marginBottom: '15px' }}>
                        <input
                            type="email"
                            className="custom-input"
                            placeholder="Enter your email..."
                            value={email}
                            maxLength={499}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <textarea
                        className="custom-input"
                            placeholder="Go ahead, we are listening..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <button type="submit" className="contact-button-submit">Submit</button>
                    </div>
                </form>
                </div>
                <div style={{ marginTop: '30px', textAlign: 'center' }}>
                    <img src={contactUs} className="contact-us-page-image-writing" alt="Illustration" style={{ width: '70%', height: 'auto' }} />
                    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}>
                        {/*
                        <div className="contact-icon-wrapper-container">
                            <div style={{border: '2px solid var(--light-blue)', borderRadius: '150%', width: '30px', height: '30px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512" style={{marginTop: '5px'}}><path fill="#779cc0" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                            </div>
                            <p style={{color: 'var(--light-blue)', marginLeft: '15px'}}>602-216-4143</p>
                        </div>
*/}
                        <div className="contact-icon-wrapper-container">
                            <div className="contact-page-email-div" style={{border: '2px solid var(--light-blue)', borderRadius: '150%', width: '30px', height: '30px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512" style={{marginTop: '5px'}}><path fill="#779cc0" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                            </div>
                            <p className="contact-page-email-text" style={{color: 'var(--light-blue)', marginLeft: '15px'}}>support@smartassistai.com</p>
                        </div>
                        
                    </div>
                </div>
                </div>
                
            </div>
              )}
   
        </div>
        
        </>
    );
};

export default Contact;
