// TransactionHistoryTable.tsx

import React, { useState, useEffect } from 'react';
import '../cssStyling/layout.css';
import config from '../config';

interface TransactionRecord {
    PaymentHistoryID: number;
    BotPlan: string;
    Amount: number;
    Currency: string;
    PaymentStatus: string;
    TransactionDate: string;
    PaymentMethod: string;
}

interface TransactionHistoryTableProps {
    organizationID: number;
    info: any;
}

const TransactionHistoryTable: React.FC<TransactionHistoryTableProps> = ({ organizationID, info }) => {
    const [data, setData] = useState<TransactionRecord[]>([]);
    const [fetched, setFetched] = useState(false);
    const [emptyArray, setEmptyArray] = useState(false);

    useEffect(() => {
        setFetched(false);
        console.log("AT TRANSACTION HISTORY DATA FETCHING");

        async function fetchData() {
            const response = await fetch(`${config.apiUrl}/getTransactionHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ organizationID }),
            });

            const data = await response.json();
            console.log("AT TRANSACTION HISTORY DATA FETCHED", { data });

            data.transactions.forEach((transaction: any) => {
                console.log('Raw Transaction Date:', transaction.TransactionDate);
            });
            
            const transactions = data.transactions.map((transaction: any) => ({
                PaymentHistoryID: transaction.PaymentHistoryID,
                BotPlan: info.botPlans.find((plan: any) => plan.BotPlanID === transaction.BotPlanID).BotPlanName,
                Amount: transaction.PaymentAmount,
                Currency: transaction.PaymentCurrency.toUpperCase(),
                PaymentStatus: transaction.PaymentStatus,
                TransactionDate: isNaN(Date.parse(transaction.PaymentTransactionDate))
                    ? 'Invalid Date'
                    : new Date(transaction.PaymentTransactionDate).toISOString().split('T')[0],
                PaymentMethod: transaction.PaymentMethod,
            }));

            console.log("AT TRANSACTION HISTORY DATA PROCESSED AND FETCHED", { transactions });
            setData(transactions);

            if (transactions.length === 0) {
                setEmptyArray(true);
            }

            setFetched(true);
        }

        fetchData();
    }, [organizationID]);

    if (!fetched) {
        return <div>Loading...</div>;
    }

    if (emptyArray) {
        return <div className="empty-message">No transaction history available.</div>;
    }

    return (
        <table className="transaction-history-table">
            <thead>
                <tr>
                    
                    <th>Transaction Date</th>
                    <th>Bot Plan</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Status</th>
                    <th>Method</th>
                </tr>
            </thead>
            <tbody>
                {data.map((transaction) => (
                    <tr key={transaction.PaymentHistoryID}>
                         <td className={transaction.TransactionDate === 'Invalid Date' ? 'invalid-date' : ''}>
                            {transaction.TransactionDate}
                        </td>
                        <td>{transaction.BotPlan}</td>
                        <td>{transaction.Amount}</td>
                        <td>{transaction.Currency}</td>
                        <td className={transaction.PaymentStatus.toLowerCase().includes('failed') ? 'invalid-date' : ''}>
                            {transaction.PaymentStatus.toLowerCase()}</td>
                       
                        <td>{transaction.PaymentMethod}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TransactionHistoryTable;

