import logo from "../logos/CyberChatIconWhite.png";
import "../cssStyling/layout.css";
import config from "../config";
import { ReactNode, useState } from "react";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState<ReactNode>(null);
    const validateEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const handleSubmit = async () => {
        console.log('Submitting forgot password form');
        if (!email) {
            setError('Email is required');
            return;
        } else if (!validateEmail(email)) {
            setError('Invalid email');
            return;
        }
        const res = await fetch(`${config.apiUrl}/forgotPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
            }),
        });

        if (res.ok) {
            alert('Password reset email sent');
            window.location.href = '/login';
        } else {
            setError('Email not found');
        }

    }
    return (
        <><div className="login-container forgot-password-background">
            <div className="forgot-password-container">
                <img style={{ width: '150px' }} src={logo} alt="CyberChat" />
                <h3>Enter the email associated with your account and we will send you a temporary password you can use to login.</h3>
                <label>Email</label>
                <input maxLength={99} type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                {error && <div className="error">{error}</div>}
                <button onClick={handleSubmit}>Submit</button>
                <h3>Don't have an account? <a href="/signup">Sign up</a></h3>
            </div>
            </div></>);
}

export default ForgotPassword;