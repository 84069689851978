import React, { useEffect, useState } from 'react';
//import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import UpdatePaymentMethod from '../hooks/ChangeCard';
import SiteSelection from './SiteSelection';
import config from '../config';

interface DowngradePlanProps {
    currentBotPlan: any;
    newBotPlan: any;
    info: any;
    setDisplayBotPlan: any;
    setDisplayDowngradePlan: any;
    handleChangePlan: any;
    changeCardInfo: any;
    handleNewInfo: any;
    handleNewOrgSite: any;
}

const stripePromise = loadStripe('pk_test_51PdHQX2NRyWcEYIdXYKMF7XSFPW3C16Uxi0XEgxXDI2fFRUzUVlMA47f28KsM0OkBN932vkrC6k0cpoMXgRkR39n00MZVRWqow');

const DowngradePlan: React.FC<DowngradePlanProps> = ({handleNewOrgSite, handleNewInfo, changeCardInfo, handleChangePlan, currentBotPlan, newBotPlan, info, setDisplayBotPlan, setDisplayDowngradePlan }) => {
    const [totalPages, setTotalPages] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    const [displayConfirmCard, setDisplayConfirmCard] = useState(false);    
    const [changed, setChanged] = useState(false);
    const [amount, setAmount] = useState(0);
    const [selectedSiteId, setSelectedSiteId] = useState<any>(null);
    const [displayPaymentChange, setDisplayPaymentChange] = useState(false);
    const [numSitesAvailable, setNumSitesAvailable] = useState(Number(newBotPlan.BotPlanRule.filter((rule: any) => rule.BotPlanRuleName === 'Maxmium Number of Sites')[0].BotPlanRuleValue) - info.user.OrganizationSite.length );

    const handleSiteSelection = (event: React.ChangeEvent<HTMLInputElement>) => {

        setSelectedSiteId(event);
    };
    
    const handleDowngradePlan = async () => {
        const res = await fetch(`${config.apiUrl}/downgrade-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                OrganizationID: info.user.OrganizationID,
                paymentAmount: Number(newBotPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase() === 'payment amount').BotPlanRuleValue),
                nonPrioritySites: selectedSiteId,  // Use the selected site ID here
            }),
        });
        const data = await res.json();
        const BotPlanID = data.BotPlanID;
        await handleChangePlan(BotPlanID);
        setAmount(newBotPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase() === 'payment amount').BotPlanRuleValue);
        //change the info object by removing all the sites that are not selected from info.user.OrganizationSite
        info.user.OrganizationSite = info.user.OrganizationSite.filter((site: any) => !selectedSiteId.includes(site.OrganizationSiteID));
        handleNewInfo(info);
        handleNewOrgSite(info.user.OrganizationSite[0]);
        setCurrentPage(currentPage + 1);
    };
    useEffect(() => {
        console.log('chanegd has been changed: ', changed);
        console.log('current page has been changed: ', currentPage);
    },[changed, currentPage]);
    useEffect(() => {
        if (numSitesAvailable >= 0) {
            
            setTotalPages(3);
            setChanged(true);
        }
    }, [numSitesAvailable]);

    return (
        <>
            {currentPage === 1 && !changed ? <>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Priority Sites</h3>
                <SiteSelection numSitesAvailable={Number(newBotPlan.BotPlanRule.filter((rule: any) => rule.BotPlanRuleName === 'Maxmium Number of Sites')[0].BotPlanRuleValue)} info={info} handleSiteSelection={handleSiteSelection} />

                <div style={{alignSelf: 'center'}}>
                                 <button style={{ marginTop: '20px', padding: '7px', width: '90px' }} className="cancel-subscription-next-button" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>

                                 <button style={{ marginTop: '20px', marginLeft: '10px' }} className="cancel-subscription-back-button" onClick={(e) => {setDisplayBotPlan(true)
                                    setDisplayDowngradePlan(false)
                                 }}>Back</button>

                </div>
                 </div>
            
            </> : null}

            {displayConfirmCard && currentPage === 1 && changed ? (
                <>
                <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Card</h3>
                <h3 style={{color: 'var(--light-blue)'}}>Your Payment Method was Changed Sucessfully 🎉</h3>
                <button  style={{width: '40%', alignSelf: 'center', padding: '10px'}} className="cancel-subscription-next-button" onClick={(e) => setDisplayConfirmCard(false)}>Back</button>
                </>
            ) : null}

            {!displayPaymentChange && (changed && currentPage === 1 || !changed && currentPage === 2)&& !displayConfirmCard? (
                <>
                            <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Card</h3>
                            <div className="orgadmin-card-container" style={{marginTop: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
              <p className="orgadmin-card-information">{info.payment.PaymentMethod.toUpperCase()} <span className="orgadmin-card-information-inner-text">ending in {info.payment.CustomerCardPartialInfo}</span></p>
              <p className="orgadmin-card-information-inner-text">Expires {info.payment.CustomerCardExpirationDate}</p>
              </div>
              <u onClick={(e) => setDisplayPaymentChange(true)} className="orgadmin-card-information-inner-text" style={{cursor: 'pointer'}}><span className="orgadmin-card-information" style={{margin: '0', padding: '0', fontSize: '1.28rem'}}>✎</span> Process payments with a different card</u>
            
            </div>
            <div style={{display: 'flex', placeContent: 'center'}}>
            <div style={{alignSelf: 'center'}}>
                                 <button style={{ marginTop: '20px', padding: '7px', width: '90px' }} className="cancel-subscription-next-button" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>

                                 <button style={{ marginTop: '20px', marginLeft: '10px' }} className="cancel-subscription-back-button" onClick={() => setCurrentPage(currentPage - 1)}>Back</button>

                </div>

            </div>
              </>
            ) : null}

            {displayPaymentChange ? (
                <>
<h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Card</h3>
<p style={{color: 'var(--light-blue)'}}>Rest assured, your payment information will not be stored. We use Stripe, a highly secure third-party service, to process your payments safely.</p>
{/* PAYMENT ELEMENT HERE */}
<UpdatePaymentMethod changeCardInfo={changeCardInfo} customerId={info.payment.PaymentProcessorCustomerID} setDisplayConfirmCard={setDisplayConfirmCard} setDisplayPaymentChange={setDisplayPaymentChange}/>
</>

            ) : null}

            {(currentPage === 2 && changed) || (!changed && currentPage === 3)? (<>
                <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Change</h3>
                <p style={{color: 'var(--light-blue)'}}>Are you sure you would like to downgrade your plan? Note there is no going back. </p>
                <div style={{display:'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center'}}>
                    <button style={{ marginTop: '20px', padding: '7px', alignSelf: 'center', width: '40%' }} className="cancel-subscription-next-button" onClick={handleDowngradePlan}>Downgrade my plan</button>

                    <button style={{ marginTop: '20px', marginLeft: '10px', margin: '0' }} className="cancel-subscription-back-button" onClick={() => setCurrentPage(currentPage - 1)}>Back</button>
                </div>

            </>) : null}

            {currentPage === 3 && changed ? (
                <>
                <div style={{display: 'flex', placeContent: 'center', flexDirection: 'column'}}>
                <h3 style={{ marginBottom: '0px', alignSelf: 'center' }} className="orgadmin-subheading">Your plan has been downgraded successfully 🎉</h3>
                <h3 style={{alignSelf: 'center', color: 'var(--light-blue)'}}>From the next payment cycle, you will be charged ${amount} USD amount.</h3>
                <button style={{width: '40%', alignSelf: 'center', padding: '10px'}} className="cancel-subscription-next-button" onClick={(e) => {setDisplayDowngradePlan(false)
                    setDisplayBotPlan(true)
                }}>Close</button>
                </div>
                </>
            ) : null}

{currentPage === 4 && !changed ? (
                <>
                <div style={{display: 'flex', placeContent: 'center', flexDirection: 'column'}}>
                <h3 style={{ marginBottom: '0px', alignSelf: 'center' }} className="orgadmin-subheading">Your plan has been downgraded successfully 🎉</h3>
                <h3 style={{alignSelf: 'center', color: 'var(--light-blue)'}}>From the next payment cycle, you will be charged ${amount} USD amount.</h3>
                <button style={{width: '40%', alignSelf: 'center', padding: '10px'}} className="cancel-subscription-next-button" onClick={(e) => {setDisplayDowngradePlan(false)
                    setDisplayBotPlan(true)
                }}>Close</button>
                </div>
                </>
            ) : null}

            <Pagination currentPage={currentPage} totalPages={totalPages} />
        </>
    );
};


/*
const ConfirmCardComponent: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardError, setCardError] = useState<string | null>(null);

    const handleChangeCard = async () => {
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setCardError(error.message || 'An error occurred');
        } else {
            // Handle successful card verification and change
            console.log('Card verified successfully:', paymentMethod);
        }
    };

    return (
        <div>
            <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Card</h3>
            <div style={{ border: '1px solid var(--light-blue)', padding: '10px', borderRadius: '5px', marginBottom: '20px' }}>
                <CardElement />
            </div>
            <button onClick={handleChangeCard} style={{ backgroundColor: 'var(--dark-blue)', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                Change Card
            </button>
            {cardError && <p style={{ color: 'red' }}>{cardError}</p>}
        </div>
    );
};
*/ 


interface PaginationProps {
    currentPage: number;
    totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '40px',
            marginRight: '40px'
        }}>
            {Array.from({ length: totalPages - 1 }).map((_, index) => (
                <div key={index} style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: currentPage === index + 1 ? 'var(--dark-blue)' : 'lightgrey',
                    margin: '0 5px'
                }}></div>
            ))}
            <i className={`fas fa-check-circle`} style={{
                fontSize: '20px',
                marginLeft: '10px',
                color: currentPage === totalPages ? 'var(--dark-blue)' : 'lightgrey'
            }}></i>
        </div>
    );
};

export default DowngradePlan;
