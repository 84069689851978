

function PaymentSuccess() {
  return (
    <div className="payment-success-container">
      <div className="payment-success-card">
        <h1>Payment Succeeded 🎉</h1>
        <p>Thank you for your payment!</p>
      </div>
    </div>
  );
}

export default PaymentSuccess;
