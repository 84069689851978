import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import config from "../config";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePaymentSuccess = async (paymentIntent) => {
    try {
      console.log('Payment intent:');
      console.log({paymentIntent});
      const response = await fetch(`${config.apiUrl}/payment-success`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerID: props.customerID,
          paymentIntentID: paymentIntent.id,
          paymentMethod: paymentIntent.payment_method,
          currency: paymentIntent.currency,
          paymentAmount: paymentIntent.amount / 100, // Convert to major currency unit
          proratedAmount: paymentIntent.proratedAmount, // Pass prorated amount if applicable
          botPlanID: props.BotPlanID,
          organizationID: props.OrganizationID,
          nextBillingDate: props.nextBillingDate,
        }),
      });

      const data = await response.json();
    
      

      if (response.ok) {
        setMessage('Payment processed successfully');
        props.changeCardInfo(data);
        await props.handleChangePlan(props.BotPlanID);
        props.setDisplayPayment(false);
        props.setDisplayPaymentSuccess(true);
        
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage('Failed to process payment');
      console.error('Error processing payment:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // No return_url needed, redirect set to 'if_required'
      },
      redirect: 'if_required',
    });

    if (error) {
      setMessage(error.message);
    } else {
    if (paymentIntent.status === 'succeeded') {
      setMessage("Processing please wait...");
      await handlePaymentSuccess(paymentIntent);
    } else {
      // Handle other statuses accordingly
      setMessage(`Payment ${paymentIntent.status}. Please check your payment method.`);
    }
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={isProcessing} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
