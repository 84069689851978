/*DELETE HERE */
import { useNavigate } from "react-router-dom";
import useIdle from "../hooks/useIdleTimer.js";
import BotPlan from "./BotPlan";
import UserRecords from "./UserRecords";
import ProcessPayment from "./ProcessPayment";
import UpgradePlan from "./UpgradePlan";
import DowngradePlan from "./DowngradePlan";
import PaymentSuccess from "./PaymentSuccess";
import TransactionHistoryTable from "./TransactionHistoryTable";
import loadingGIF from "../logos/loading.gif";
import config from "../config";
import "../cssStyling/layout.css";
import name from "../logos/SmartAssistAINameR.png";
import icon from "../logos/SALogo.png";
import { useState, useEffect, useRef } from "react";
import { ReactNode } from "react";
import SiteSettings from "./SiteSettings";
import { useLocation } from "react-router-dom";
import MetadataComponent from "./Metadata";
import ChangePasswordComponent from "./ChangePassword";
import Analytics from "./Analytics";
import e from "express";
import DraggableList from "./SortableList";
import CancelSubscription from "./CancelSubscription";
import UpdatePaymentMethod from "../hooks/ChangeCard";

interface Detail {
  SiteUITypeDetailName: string;
  SiteUITypeDetailValue: string;
}

interface OrgAdminProps {
  firstHalf: Detail[];
  secondHalf: Detail[];
  orgSite: {
    OrganizationSiteName: string;
  };
}

function OrgAdmin() {
  interface Metadata {
    id: number;
    deleted: boolean;
    edited: boolean;
    name: string;
    value: string;
  }

  const location = useLocation();
  let validEntry: boolean = false;
  if (location.state) {
    validEntry = true;
  }
  let user: any = "";
  let info: any = "";
  let orgSite: any = "";
  let knowledgeBaseContentCharacterCount = 0;
  let orgSite1: any = "";
  if (validEntry) {
    let user1 = sessionStorage.getItem("user");
    let info1 = sessionStorage.getItem("info");
    user =
      user1 && JSON.parse(user1).user
        ? JSON.parse(user1).user
        : user1
        ? JSON.parse(user1)
        : location.state?.user;
    info = info1 ? JSON.parse(info1) : location.state?.info;
    console.log("USER ARRIVED AT ORGADMIN");
    console.log({ user });
    console.log("INFO ARRIVED AT ORGADMIN");
    console.log({ info });
    orgSite1 = sessionStorage.getItem("orgSite");
    if (!orgSite1) {
      orgSite = info.user.OrganizationSite[0];
      console.log("orgSite NOT in session storage");
      console.log({ orgSite });
      if (orgSite) {
        sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
      }
    } else {
      orgSite = JSON.parse(orgSite1);
      console.log("orgSite already in session storage");
      console.log({ orgSite });
      orgSite.SiteKnowledgeBase.forEach((doc: any) => {
        knowledgeBaseContentCharacterCount +=
          doc.SiteKnowledgeBaseContent.length;
      });
      console.log("knowledge base content character count below");
      console.log({ knowledgeBaseContentCharacterCount });
    }
  }

  const [metadataPairs2, setMetadataPairs2] = useState<Metadata[]>([
    { name: "", value: "", id: -1, edited: false, deleted: false },
  ]);
  const [showUpload, setShowUpload] = useState(false);
  const [guideline1, setGuideline1] = useState(false);
  const [isScraped, setIsScraped] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [source, setSource] = useState("");
  const [guideline2, setGuideline2] = useState(false);
  const [docIndex, setDocIndex] = useState<number>(-1);
  const [guideline3, setGuideline3] = useState(false);
  const [metadataPairs, setMetadataPairs] = useState<Metadata[]>([]);
  const [guideline4, setGuideline4] = useState(false);
  const [editDoc, setEditDoc] = useState(false);
  const [editedDoc, setEditedDoc] = useState<string>("");
  const [editProfile, setEditProfile] = useState(false);
  const [editedMetadata, setEditedMetadata] = useState<any>([]);
  const [editingDoc, setEditingDoc] = useState<any>(null);
  const [displayWelcome, setDisplayWelcome] = useState(true);
  const [displayEditPaymentMethod, setDisplayEditPaymentMethod] =
    useState(false);
  const [displayBotPlan, setDisplayBotPlan] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState<any>(null);
  const [displayEmbeddings, setDisplayEmbeddings] = useState(false);
  const [displayUpgradePlan, setDisplayUpgradePlan] = useState(false);
  const [displayDowngradePlan, setDisplayDowngradePlan] = useState(false);
  const [displayLLM, setDisplayLLM] = useState(false);
  const [displayKnowledgeBase, setDisplayKnowledgeBase] = useState(false);
  const [displayUI, setDisplayUI] = useState(false);
  const [displayProfile, setDisplayProfile] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(false);
  const [displayPayment, setDisplayPayment] = useState(false);
  const [displayPaymentSuccess, setDisplayPaymentSuccess] = useState(false);
  const [firstName, setFirstName] = useState(
    validEntry ? user.UserFirstName : ""
  );
  const [lastName, setLastName] = useState(validEntry ? user.UserLastName : "");
  const [email, setEmail] = useState(validEntry ? user.UserEmail : "");
  const [displayOrganization, setDisplayOrganization] = useState(false);
  const [organizationName, setOrganizationName] = useState(
    validEntry ? info.user.OrganizationName : ""
  );
  const [organizationDescription, setOrganizationDescription] = useState(
    validEntry ? info.user.OrganizationDescription : ""
  );
  const [jobTitle, setJobTitle] = useState(validEntry ? user.UserJobTitle : "");
  const [uploadDocument, setUploadDocument] = useState<ReactNode>(null);
  const [selectedEmbedder, setSelectedEmbedder] = useState(
    validEntry ? info.user.BotPlan.Embedder : ""
  );
  const [selectedSite, setSelectedSite] = useState(validEntry ? orgSite : "");
  const [changePassword, setChangePassword] = useState(false);
  const [chatbot, setChatbot] = useState<ReactNode>(null);
  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<Record<string, string>>({});
  const [showProfile, setShowProfiles] = useState(false);
  const [displayUserRecords, setDisplayUserRecords] = useState(false);
  const [displayAnalytics, setDisplayAnalytics] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [chatHistoryName, setChatHistoryName] = useState("");
  const [chatHistoryDate, setChatHistoryDate] = useState("");
  const [chatHistoryEmail, setChatHistoryEmail] = useState("");
  const [chatHistoryLocation, setChatHistoryLocation] = useState("");
  const [paymentPlan, setPaymentPlan] = useState<any>(info.user.BotPlan);
  const [displayCancelSubscription, setDisplayCancelSubscription] =
    useState(false);

  /*DELETE HERE */

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const initialIndex = info.botPlans.findIndex(
    (plan: any) => plan.BotPlanID === info.user.BotPlan.BotPlanID
  );
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const handlePrev = () => {
    setCurrentIndex((prevIndex: any) =>
      prevIndex > 0 ? prevIndex - 1 : info.botPlans.length - 1
    );
  };

  useEffect(() => {
    if (displayProfile) {
      setDisplayAnalytics(false);
      setDisplayUserRecords(false);
      setDisplayOrganization(false);
      setDisplaySettings(false);
      setDisplayBotPlan(false);
      setDisplayWelcome(false);
      setDisplayEmbeddings(false);
      setDisplayLLM(false);
      setDisplayKnowledgeBase(false);
      setDisplayUI(false);
      setDisplayUpgradePlan(false);
      setDisplayDowngradePlan(false);
      setDisplayCancelSubscription(false);
      setDisplayWelcome(false);
      setDisplayCancelSubscription(false);
      setDisplaySettings(false);
      setDisplayPayment(false);
      setDisplayPaymentSuccess(false);
      setDisplayEditPaymentMethod(false);
    }
  }, [displayProfile]);

  const handleProcessChangePlan = (paymentPlan: any) => {
    if (info.user.bypassPayment) {
      handleChangePlan(paymentPlan.BotPlanID);
      return;
    }
    setPaymentPlan(paymentPlan);
    if (paymentPlan.BotPlanID === info.user.BotPlan.BotPlanID) {
      alert("You are already subscribed to this plan");
    } else if (Number(info.user.BotPlan.BotPlanID) === 1) {
      setDisplayPayment(true);
      setDisplayBotPlan(false);
    } else if (paymentPlan.BotPlanID === 1) {
      setDisplayBotPlan(false);
      setDisplayCancelSubscription(true);
    } else {
      const botPlanAmount = paymentPlan.BotPlanRule.find((rule: any) =>
        rule.BotPlanRuleName.toLowerCase().includes("payment amount")
      ).BotPlanRuleValue;
      console.log("ISSUE HERE");
      console.log(info.user.BotPlan.BotPlanRule);
      const currentBotPlanAmount = info.user.BotPlan.BotPlanRule.find(
        (rule: any) =>
          rule.BotPlanRuleName.toLowerCase().includes("payment amount")
      ).BotPlanRuleValue;
      console.log({ botPlanAmount });
      console.log({ currentBotPlanAmount });
      setPaymentPlan(paymentPlan);
      if (botPlanAmount < currentBotPlanAmount) {
        setDisplayUpgradePlan(true);
        setDisplayBotPlan(false);
        return;
      } else {
        setDisplayDowngradePlan(true);
        setDisplayBotPlan(false);
      }
    }
  };

  const handleNewOrgSite = (newOrgSite: any) => {
    orgSite = newOrgSite;
    sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
    setRerender(!rerender);
  };

  const handleNewInfo = (infoo: any) => {
    sessionStorage.setItem("info", JSON.stringify(infoo));
    info = infoo;
    console.log("INFO AFTER CHANGING BELOW");
    console.log({ info });
    setRerender(!rerender);
  };

  const handleShowBotPlanPage = () => {
    setDisplayBotPlan(true);
    setDisplayEmbeddings(false);
    setDisplayLLM(false);
    setDisplayKnowledgeBase(false);
    setDisplayUI(false);
    setDisplayUpgradePlan(false);
    setDisplayDowngradePlan(false);
    setDisplayProfile(false);
    setDisplaySettings(false);
    setDisplayWelcome(false);
    setDisplayOrganization(false);
    setDisplayUserRecords(false);
    setDisplayAnalytics(false);
  };

  const changeCardInfo = (update: any) => {
    info.payment = update;
    sessionStorage.setItem("info", JSON.stringify(info));
  };

  const handleTemplateSizeChange = (e: any) => {
    const { value } = e.target;
    console.log("VALUE BELOW");
    console.log({ value });
    if (value === "small") {
      setDetails((prevDetails) => ({
        ...prevDetails,
        ["windowWidth"]: "240px",
        ["windowHeight"]: "400px",
        ["fontSize"]: "12px",
      }));
    } else if (value === "medium") {
      setDetails((prevDetails) => ({
        ...prevDetails,
        ["windowWidth"]: "290px",
        ["windowHeight"]: "500px",
        ["fontSize"]: "14px",
      }));
    } else if (value === "large") {
      setDetails((prevDetails) => ({
        ...prevDetails,
        ["windowWidth"]: "870px",
        ["windowHeight"]: "500px",
        ["fontSize"]: "16px",
      }));
    }
  };

  const handleSetTemplate = (
    colorOne: string,
    colorTwo: string,
    colorThree: string
  ) => {
    console.log({ details });

    setDetails((prevDetails) => ({
      ...prevDetails,
      ["foregroundColor"]: colorOne,
      ["backgroundColor"]: colorTwo,
      ["chatBoxColor"]: colorThree,
    }));
  };

  const handleIdle = () => {
    setShowModal(true); //show modal
    setRemainingTime(30); //set 15 seconds as time remaining
  };

  //come back and finish this
  const handleCancelSubscription = async () => {
    await fetch(`${config.apiUrl}/cancelSubscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        OrganizationID: info.user.OrganizationID,
      }),
    });

    info.user.BotPlan = await info.botPlans.find(
      (plan: any) => plan.BotPlanID === 1
    );
    await sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
    await sessionStorage.setItem("info", JSON.stringify(info));
    console.log("ORG SITE AFTER CHANGING PLAN BELOW");
    console.log({ orgSite });
    await setRerender(!rerender);
  };

  const handleDeleteAccount = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete your account? All associated data will be deleted and subscriptions cancelled. This change cannot be undone."
    );
    if (isConfirmed) {
      await fetch(`${config.apiUrl}/deleteUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: user.UserCredentialID,
        }),
      });
      sessionStorage.clear();
      window.location.href = "/login";
    }
  };

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 2.6 });
  console.log("is user idle?", isIdle);

  useEffect(() => {
    if (displayCancelSubscription) {
      setDisplayBotPlan(false);
      setDisplayEmbeddings(false);
      setDisplayLLM(false);
      setDisplayKnowledgeBase(false);
      setDisplayUI(false);
      setDisplayUpgradePlan(false);
      setDisplayDowngradePlan(false);
      setDisplayProfile(false);
      setDisplaySettings(false);
      setDisplayWelcome(false);
      setDisplayOrganization(false);
      setDisplayUserRecords(false);
      setDisplayAnalytics(false);
    } else if (
      displayWelcome === false &&
      displayBotPlan === false &&
      displayEmbeddings === false &&
      displayLLM === false &&
      displayKnowledgeBase === false &&
      displayUI === false &&
      displayProfile === false &&
      displaySettings === false &&
      displayOrganization === false &&
      displayUserRecords === false &&
      displayAnalytics === false
    ) {
      setDisplayBotPlan(true);
    }
  }, [displayCancelSubscription]);

  useEffect(() => {
    let interval: any;

    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      // alert("Time out!");
      setShowModal(false);
      sessionStorage.clear();
      navigate("/");
    }
  }, [remainingTime, showModal, navigate]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything

  const handleLogOut = () => {
    setShowModal(false);
    sessionStorage.clear();
    navigate("/");
  };

  const handleStayLoggedIn = () => {
    setShowModal(false);
  };

  function millisToMinutesAndSeconds(millis: any) {
    var minutes: any = Math.floor(millis / 60000);
    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  useEffect(() => {
    orgSite = JSON.parse(sessionStorage.getItem("orgSite") || "{}");

    if (validEntry) {
      const updatedDetails = {
        ...firstHalf.reduce(
          (acc: any, detail: any) => ({
            ...acc,
            [detail.SiteUITypeDetailName]: detail.SiteUITypeDetailValue,
          }),
          {}
        ),
        ...secondHalf.reduce(
          (acc: any, detail: any) => ({
            ...acc,
            [detail.SiteUITypeDetailName]: detail.SiteUITypeDetailValue,
          }),
          {}
        ),
      };
      setDetails(updatedDetails);
    }
  }, [rerender]);

  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    if (validEntry) {
      // Clear the previous timeout if details change before the timeout completes
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Set a new timeout to update the chatbot after 2 seconds
      timeoutRef.current = setTimeout(() => {
        console.log("Props updated", chatBotProps);
        setChatbot(
          <chat-bot-admin
            key={JSON.stringify(chatBotProps)}
            {...chatBotProps}
            org-site-name={orgSite.OrganizationSiteName}
          ></chat-bot-admin>
        );
      }, 1600);

      // Cleanup the timeout on component unmount or when details change
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [details]);

  if (!validEntry) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1 style={{ fontSize: "30px" }}>Unauthorized</h1>
      </div>
    );
  }
  let orgSite2 = orgSite;
  orgSite2.SiteUITypeDetail = orgSite2.SiteUITypeDetail.filter(
    (detail: any) => detail.SiteUITypeDetailName !== "fontImport"
  );
  const midpoint = Math.ceil(orgSite2.SiteUITypeDetail.length / 2);
  let firstHalf = orgSite2.SiteUITypeDetail.slice(0, midpoint);
  let secondHalf = orgSite2.SiteUITypeDetail.slice(midpoint);

  // Handle input change
  const handleChatbotChange2 = (e: any, detailName: string) => {
    console.log("SETTING DETAILS BELOW");
    setDetails((prevDetails) => ({
      ...prevDetails,
      [detailName]: e.target.value + "px",
    }));
  };
  const handleChatbotChange = (e: any, detailName: string) => {
    if (detailName === "fontName") {
      const { value } = e.target;
      const fontImport = info.fontName.find(
        (font: any) => font.UIFontName === value
      ).UIFontLink;
      setDetails((prevDetails) => ({
        ...prevDetails,
        [detailName]: value,
        ["fontImport"]: fontImport,
      }));
    } else {
      console.log("DETAIL NAME BELOW");
      console.log({ detailName });
      console.log("VALUE BELOW");
      console.log({ value: e.target.value });
      const { value } = e.target;
      setDetails((prevDetails) => ({
        ...prevDetails,
        [detailName]: value,
      }));
    }
  };

  const handleUIChange = async () => {
    setLoading(true);
    let siteUI: any = await fetch(`${config.apiUrl}/updateSiteUI`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        details,
        OrganizationSiteID: orgSite.OrganizationSiteID,
      }),
    });
    siteUI = await siteUI.json();
    orgSite.SiteUITypeDetail = siteUI.details;
    console.log("Changed UI Arrived....");
    console.log({ orgSite });
    sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
    setRerender(!rerender);
    setLoading(false);
  };

  const displayUIInput = (detail: any) => {
    let typeInput = detail.UITypeDetail.UICategory.UICategoryName;
    console.log("TYPE INPUT BELOW");
    console.log({ typeInput });
    if (typeInput === "Color Palette") {
      typeInput = "color";
      return (
        <input
          style={{
            minWidth: "220px",
            width: "auto",
            height: "30px",
            marginLeft: "10px",
          }}
          type={typeInput}
          value={details[detail.SiteUITypeDetailName]}
          onChange={(e) => handleChatbotChange(e, detail.SiteUITypeDetailName)}
        />
      );
    } else if (typeInput === "Text Field - restricted") {
      typeInput = "range";
      // Handle min and max for the range input type
      const minValue = detail.UITypeDetail.UITypeDetailCategoryValue1 || 0; // Default to 0 if undefined
      const maxValue = detail.UITypeDetail.UITypeDetailCategoryValue2 || 100; // Default to 100 if undefined
      // Remove 'px' from the value for the input field
      const valueWithoutPx = details[detail.SiteUITypeDetailName].replace(
        "px",
        ""
      );

      return (
        <input
          style={{
            minWidth: "220px",
            width: "auto",
            height: "30px",
            marginLeft: "10px",
          }}
          type={typeInput}
          min={minValue}
          max={maxValue}
          value={valueWithoutPx}
          onChange={(e) => handleChatbotChange2(e, detail.SiteUITypeDetailName)}
        />
      );
    } else if (
      typeInput === "Text Field" &&
      detail.SiteUITypeDetailName === "fontImport"
    ) {
      return null;
    } else if (typeInput === "Text Field") {
      typeInput = "text";
      return (
        <input
          style={{
            minWidth: "220px",
            width: "auto",
            height: "30px",
            marginLeft: "10px",
          }}
          maxLength={499}
          type={typeInput}
          value={details[detail.SiteUITypeDetailName]}
          onChange={(e) => handleChatbotChange(e, detail.SiteUITypeDetailName)}
        />
      );
    } else if (
      typeInput === "Lookup" &&
      detail.SiteUITypeDetailName === "windowAlignment"
    ) {
      console.log("LOOKUP BELOW");
      return (
        <>
          <select
            style={{
              minWidth: "220px",
              width: "auto",
              height: "30px",
              marginLeft: "10px",
            }}
            onChange={(e) =>
              handleChatbotChange(e, detail.SiteUITypeDetailName)
            }
            value={details[detail.SiteUITypeDetailName]}
          >
            {info.windowAlignment.map((alignment: any) => {
              return (
                <option
                  value={
                    alignment.HorizontalWindowAlignmentValue.toLowerCase() +
                    "-" +
                    alignment.VerticalWindowAlignmentValue.toLowerCase()
                  }
                >
                  {alignment.HorizontalWindowAlignmentValue.toLowerCase()}-
                  {alignment.VerticalWindowAlignmentValue.toLowerCase()}
                </option>
              );
            })}
          </select>
        </>
      );
    } else if (
      typeInput === "Lookup" &&
      detail.SiteUITypeDetailName == "fontName"
    ) {
      return (
        <>
          <select
            style={{
              minWidth: "220px",
              width: "auto",
              height: "30px",
              marginLeft: "10px",
            }}
            onChange={(e) =>
              handleChatbotChange(e, detail.SiteUITypeDetailName)
            }
            value={details[detail.SiteUITypeDetailName]}
          >
            {info.fontName.map((font: any) => {
              return <option value={font.UIFontName}>{font.UIFontName}</option>;
            })}
          </select>
        </>
      );
    }
    // Handle min and max for the range input type
    const minValue = detail.UITypeDetail.UITypeDetailCategoryValue1 || 0; // Default to 0 if undefined
    const maxValue = detail.UITypeDetail.UITypeDetailCategoryValue2 || 100; // Default to 100 if undefined
    console.log("MIN VALUE BELOW");
    console.log(minValue);
    console.log("MAX VALUE BELOW");
    console.log(maxValue);

    // Remove 'px' from the value for the input field
    const valueWithoutPx = details[detail.SiteUITypeDetailName].replace(
      "px",
      ""
    );

    return (
      <input
        style={{
          minWidth: "220px",
          width: "auto",
          height: "30px",
          marginLeft: "10px",
        }}
        type={typeInput}
        min={minValue}
        max={maxValue}
        value={valueWithoutPx}
        onChange={(e) => handleChatbotChange2(e, detail.SiteUITypeDetailName)}
      />
    );
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  const handleOrganizationChange = async () => {
    setLoading(true);
    console.log("ORGANIZATION CHANGE BELOW");
    console.log({ organizationName });
    console.log({ organizationDescription });
    let organization = await fetch(`${config.apiUrl}/updateOrganization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        OrganizationName: organizationName,
        OrganizationDescription: organizationDescription,
        OrganizationID: info.user.OrganizationID,
      }),
    });
    organization = await organization.json();
    info.user.OrganizationName = organizationName;
    info.user.OrganizationDescription = organizationDescription;
    sessionStorage.setItem("info", JSON.stringify(info));
    sessionStorage.setItem("user", JSON.stringify(user));
    console.log("ORGANIZATION AFTER CHANGING BELOW");
    console.log({ info });
    setDisplayOrganization(false);
    setOrganizationName(organizationName);
    setOrganizationDescription(organizationDescription);
    setRerender(!rerender);
    setLoading(false);
  };
  const handlePersonalInformationChange = async () => {
    setLoading(true);
    console.log("PERSONAL INFORMATION CHANGE BELOW");
    console.log({ firstName });
    console.log({ lastName });
    console.log({ email });
    console.log({ jobTitle });
    let user1 = await fetch(`${config.apiUrl}/updateUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserFirstName: firstName,
        UserLastName: lastName,
        UserEmail: email,
        UserJobTitle: jobTitle,
        UserID: user.UserCredentialID,
      }),
    });
    const userDum = await user1.json();
    user = userDum.user;
    sessionStorage.setItem("user", JSON.stringify(user));

    console.log("USER AFTER CHANGING PERSONAL INFORMATION BELOW");
    console.log({ user });
    setEditProfile(false);
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setJobTitle(jobTitle);
    setRerender(!rerender);
    setLoading(false);
  };
  const handleChangePlan = async (BotPlanID: number) => {
    console.log("SELECTED SITE BELOW");
    console.log({ selectedSite });
    console.log("SELECTED EMBEDDER BELOW");
    console.log({ selectedEmbedder });
    const user = await fetch(`${config.apiUrl}/updatePlan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        BotPlanID,
        OrganizationSiteID: orgSite.OrganizationSiteID,
      }),
    });
    info.user.BotPlan = await info.botPlans.find(
      (plan: any) => plan.BotPlanID === BotPlanID
    );
    await sessionStorage.setItem("info", JSON.stringify(info));
    console.log("INFO AFTER CHANGING PLAN BELOW");
    console.log({ info });
    await setRerender(!rerender);
  };

  // Function to transform property names
  const transformPropertyName = (name: string) => {
    return name.replace(/([A-Z])/g, "-$1").toLowerCase();
  };

  // Convert details object to props for the chat-bot component
  const chatBotProps = Object.keys(details).reduce((acc, key) => {
    acc[transformPropertyName(key)] = details[key];
    return acc;
  }, {} as Record<string, string>);

  const handleSiteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("SITE CHANGE EVENT BELOW");
    console.log(event.target.value);
    const newSite = info.user.OrganizationSite.find(
      (site: any) => site.OrganizationSiteName === event.target.value
    );
    setSelectedSite(newSite);
    orgSite = newSite;
    let orgSite2 = orgSite;
    orgSite2.SiteUITypeDetail = orgSite2.SiteUITypeDetail.filter(
      (detail: any) => detail.SiteUITypeDetailName !== "fontImport"
    );
    firstHalf = orgSite2.SiteUITypeDetail.slice(0, midpoint);
    secondHalf = orgSite2.SiteUITypeDetail.slice(midpoint);
    console.log("NEW SITE BELOW");
    console.log({ orgSite });
    sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
    setRerender(!rerender);
  };

  const handleSelectChange = async (event: any) => {
    const selectedIndex = event.target.options.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];

    // Extracting EmbedderName and EmbedderDescription from the selected option text
    const [selectedName, selectedDescription] = selectedOption.text.split(": ");

    // Find the selected embedder object from info.embedders
    const selectedEmbedder = info.embedders.find(
      (embedder: any) => embedder.EmbedderName === selectedName
    );

    if (selectedEmbedder) {
      setSelectedEmbedder(selectedEmbedder);

      // Make the backend call with the selected EmbedderID
      await fetch(`${config.apiUrl}/updateEmbedder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          EmbedderID: selectedEmbedder.EmbedderID,
          OrganizationSiteID: orgSite.OrganizationSiteID,
        }),
      });
    }
  };

  const isValidUrl = (urlString: string) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const handleUpload = async () => {
    setLoading(true);
    console.log(isScraped);
    let content: any = "";
    if (isScraped === true) {
      if (!isValidUrl(uploadedDocument)) {
        alert(
          "The URL you have uploaded is invalid. Please upload a valid URL."
        );
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        setIsScraped(false);
        return false;
      }

      if (source === "") {
        alert("Please enter a source for the document.");
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        setIsScraped(false);
        return false;
      }
      try {
        const response = await fetch(`${config.apiUrl}/scrape`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url: uploadedDocument }),
        });
        const data = await response.json();
        console.log(data); // Handle the response data as needed
        content = "";

        await data.map((text: any) => {
          content += "\n" + text.text;
        });
        console.log("CONTENT BELOW");
        console.log(content);

        await setUploadedDocument(content);
      } catch (error) {
        console.error("Error sending link to backend:", error);
      }
      setIsScraped(false);
    } else {
      content = uploadedDocument;
    }

    const can = document.getElementsByClassName(
      "orgadmin-KB-upload-cancel"
    )[0] as HTMLButtonElement;
    can.disabled = true;
    can.style.color = "grey";
    can.style.cursor = "wait";

    const but = document.getElementsByClassName(
      "orgadmin-KB-upload"
    )[0] as HTMLButtonElement;
    but.disabled = true;
    but.style.backgroundColor = "grey";
    but.style.color = "white";
    document.body.style.cursor = "wait";
    but.style.borderColor = "white";

    let response: any;
    let resd: any;

    if (typeof content === "string") {
      console.log("STRING DOCUMENT");

      // Step 1: Replace multiple spaces with a single space
      content = content.replace(/\s+/g, " ");

      // Step 2: Replace multiple newlines with a single newline
      content = content.replace(/(\r\n|\n|\r){2,}/g, "\n");

      console.log("HERE IS THE CHECK FOR EVERYTHING");
      console.log(content.length);
      console.log(info.user.ContentSize);
      console.log(
        Number(
          info.user.BotPlan.BotPlanRule.find((rule: any) =>
            rule.BotPlanRuleName.toLowerCase().includes("content")
          ).BotPlanRuleValue
        )
      );

      if (
        content.length + info.user.ContentSize >=
        Number(
          info.user.BotPlan.BotPlanRule.find((rule: any) =>
            rule.BotPlanRuleName.toLowerCase().includes("content")
          ).BotPlanRuleValue
        )
      ) {
        setSource("");
        console.log("uploadDocument.length: ", content.length);
        console.log("info.user.ContentSize: ", info.user.ContentSize);
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        but.disabled = false;
        but.style.backgroundColor = "revert";
        but.style.color = "revert";
        but.style.borderColor = "revert";
        document.body.style.cursor = "default";
        can.disabled = false;
        can.style.color = "revert";
        can.style.cursor = "revert";
        alert(
          `Failed to upload: You have exceeded your organization's maximum character limit for the knowledge base content. Please upgrade your plan to increase the limit.`
        );
        return false;
      } else if (content.length <= 25) {
        alert(
          "Failed to upload: Please enter at least 25 characters amount of content to upload."
        );
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        but.disabled = false;
        but.style.backgroundColor = "revert";
        but.style.color = "revert";
        but.style.borderColor = "revert";
        document.body.style.cursor = "default";
        can.disabled = false;
        can.style.color = "revert";
        can.style.cursor = "revert";
        return false;
      } else if (source === "") {
        alert("Please enter a source for the document.");
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        but.disabled = false;
        but.style.backgroundColor = "revert";
        but.style.color = "revert";
        but.style.borderColor = "revert";
        document.body.style.cursor = "default";
        can.disabled = false;
        return false;
      }

      
      response = await fetch(`${config.apiUrl}/addDocument`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          OrganizationSiteID: orgSite.OrganizationSiteID,
          SiteKnowledgeBaseSource: source,
          SiteKnowledgeBaseContent: content,
          SiteKnowledgeBaseContentCharacterCount: info.user.ContentSize,
          SiteKnowledgeBaseContentCharacterCountLimit:
            info.user.BotPlan.BotPlanRule.find((rule: any) =>
              rule.BotPlanRuleName.toLowerCase().includes("content")
            ).BotPlanRuleValue,
          SiteKnowledgeBaseMetaDataNameValue: metadataPairs2.filter(
            (meta) => meta.deleted !== true
          ),
        }),
      });
      resd = await response.json();
      knowledgeBaseContentCharacterCount +=
        resd.Document.SiteKnowledgeBaseContent.length;
        //else if its a file
    } else if (typeof content === "object") {
      console.log("FILE DOCUMENT");
      console.log({ content });
      if (!content) {
        alert("Failed to upload: Please add content to upload.");
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        but.disabled = false;
        but.style.backgroundColor = "revert";
        but.style.color = "revert";
        but.style.borderColor = "revert";
        document.body.style.cursor = "default";
        can.disabled = false;
        return false;
      } else if (source === "") {
        alert("Please enter a source for the document.");
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        but.disabled = false;
        but.style.backgroundColor = "revert";
        but.style.color = "revert";
        but.style.borderColor = "revert";
        document.body.style.cursor = "default";
        can.disabled = false;
        return false;

        // restrict file size
      } else if (content.size > 1000000) {
        alert("Failed to upload: File size must be less than 1MB.");
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        but.disabled = false;
        but.style.backgroundColor = "revert";
        but.style.color = "revert";
        but.style.borderColor = "revert";
        document.body.style.cursor = "default";
        can.disabled = false;
        return false;
      }
      console.log({ content });
      const formData = new FormData();
      formData.append("OrganizationSiteID", orgSite.OrganizationSiteID);
      formData.append("SiteKnowledgeBaseSource", source);
      formData.append("uploadedDocument", content);
      formData.append(
        "SiteKnowledgeBaseContentCharacterCount",
        String(info.user.ContentSize || 0)
      );
      formData.append(
        "SiteKnowledgeBaseContentCharacterCountLimit",
        String(
          info.user.BotPlan.BotPlanRule.find((rule: any) =>
            rule.BotPlanRuleName.toLowerCase().includes("content")
          ).BotPlanRuleValue
        )
      );
      formData.append(
        "SiteKnowledgeBaseMetaDataNameValue",
        JSON.stringify(metadataPairs2.filter((meta) => meta.deleted !== true))
      );
      response = await fetch(`${config.apiUrl}/addDocumentFile`, {
        method: "POST",
        body: formData,
      });
      resd = await response.json();
      if (resd && resd.message && resd.message === "Too large") {
        console.log("TOO LARGE DOCUMENT:", resd.amount);
        console.log("ALREADY:", info.user.ContentSize);
        console.log(
          "LIMIT:",
          info.user.BotPlan.BotPlanRule.find((rule: any) =>
            rule.BotPlanRuleName.toLowerCase().includes("content")
          ).BotPlanRuleValue
        );

        alert(
          "Failed to upload: You have exceeded the maximum character limit for the knowledge base content. Please upgrade your plan to increase the limit."
        );
        setSource("");
        // Reset states
        setUploadDocument(null);
        setShowUpload(false);
        setLoading(false);
        but.disabled = false;
        but.style.backgroundColor = "revert";
        but.style.color = "revert";
        but.style.borderColor = "revert";
        document.body.style.cursor = "default";
        can.disabled = false;
        return false;
      }
    }

    const res = resd;
    console.log("RESPONSE BELOW");
    console.log({ res });

    console.log("WE HAVE ARRIVED HERE!!");

    res.Document.SiteKnowledgeBaseContent =
      res.Document.SiteKnowledgeBaseContent.replace(/\s+/g, " ");
    res.Document.SiteKnowledgeBaseContent =
      res.Document.SiteKnowledgeBaseContent.replace(/(\r\n|\n|\r){2,}/g, "\n");
    orgSite.SiteKnowledgeBase.push(res.Document);
    info.user.OrganizationSite.find(
      (site: any) => site.OrganizationSiteID === orgSite.OrganizationSiteID
    ).SiteKnowledgeBase.push(res.Document);
    knowledgeBaseContentCharacterCount +=
      res.Document.SiteKnowledgeBaseContent.length;
    info.user.ContentSize += res.Document.SiteKnowledgeBaseContent.length;
    await sessionStorage.removeItem("orgSite");
    await sessionStorage.setItem("info", JSON.stringify(info));
    await sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
    orgSite1 = await sessionStorage.getItem("orgSite");
    console.log("ORG SITE AFTER UPLOADING DOCUMENT BELOW");
    console.log({ orgSite });
    console.log("CHANGING SOURCE TO EMPTY STRING BELOW 1");
    await setSource("");
    await setUploadDocument(null);
    await setShowUpload(false);
    but.disabled = false;
    but.style.backgroundColor = "revert";
    but.style.color = "revert";
    but.style.borderColor = "revert";
    document.body.style.cursor = "default";
    can.disabled = false;
    setIsScraped(false);
    can.style.color = "revert";
    can.style.cursor = "revert";
    setMetadataPairs2([
      { name: "", value: "", id: -1, edited: false, deleted: false },
    ]);
    setLoading(false);
  };

  const handleEditDocument = async () => {
    setLoading(true);
    const can = document.getElementsByClassName(
      "orgadmin-KB-upload-cancel"
    )[0] as HTMLButtonElement;
    can.disabled = true;
    can.style.color = "grey";
    can.style.cursor = "wait";
    const but = document.getElementsByClassName(
      "orgadmin-KB-upload"
    )[0] as HTMLButtonElement;
    but.disabled = true;
    but.style.backgroundColor = "grey";
    but.style.color = "white";
    document.body.style.cursor = "wait";
    but.style.borderColor = "white";
    console.log("CHANGING SOURCE TO EMPTY STRING BELOW 2");
    console.log({ editingDoc });

    if (
      editedDoc.length +
        info.user.ContentSize -
        editingDoc.SiteKnowledgeBaseContent.length >=
      info.user.BotPlan.BotPlanRule.find((rule: any) =>
        rule.BotPlanRuleName.toLowerCase().includes("content")
      ).BotPlanRuleValue
    ) {
      alert(
        "Failed to upload: You have exceeded the maximum character limit for the knowledge base content. Please upgrade your plan to increase the limit."
      );
      setSource("");
      // Reset states
      setEditDoc(false);
      setEditingDoc(null);
      setEditedDoc("");
      setEditedMetadata([]);
      setLoading(false);
      but.disabled = false;
      but.style.backgroundColor = "revert";
      but.style.color = "revert";
      but.style.borderColor = "revert";
      document.body.style.cursor = "default";
      can.disabled = false;
      can.style.color = "revert";
      can.style.cursor = "revert";
      return false;
      //check if source is empty
    } else if (source === "") {
      alert("Please enter a source for the document.");
      setSource("");
      // Reset states
      setEditDoc(false);
      setEditingDoc(null);
      setEditedDoc("");
      setEditedMetadata([]);
      setLoading(false);
      but.disabled = false;
      but.style.backgroundColor = "revert";
      but.style.color = "revert";
      but.style.borderColor = "revert";
      document.body.style.cursor = "default";
      can.disabled = false;
      can.style.color = "revert";
      can.style.cursor = "revert";
      return false;

      //check if editedDoc is less than 25 characters
    } else if (editedDoc.length <= 25) {
      alert(
        "Failed to upload: Please enter at least 25 characters amount of content to upload."
      );
      setSource("");
      // Reset states
      setEditDoc(false);
      setEditingDoc(null);
      setEditedDoc("");
      setEditedMetadata([]);
      setLoading(false);
      but.disabled = false;
      but.style.backgroundColor = "revert";
      but.style.color = "revert";
      but.style.borderColor = "revert";
      document.body.style.cursor = "default";
      can.disabled = false;
      can.style.color = "revert";
      can.style.cursor = "revert";
      return false;
    } else {
      knowledgeBaseContentCharacterCount =
        knowledgeBaseContentCharacterCount -
        editingDoc.SiteKnowledgeBaseContent.length +
        editedDoc.length;
      info.user.ContentSize =
        info.user.ContentSize -
        editingDoc.SiteKnowledgeBaseContent.length +
        editedDoc.length;
      //update the knowledge base content character count
      try {
        const res = await fetch(`${config.apiUrl}/updateCharacterCount`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            OrganizationSiteID: orgSite.OrganizationSiteID,
            SiteKnowledgeBaseContentCharacterCount: info.user.ContentSize,
          }),
        });
        const respons = await res.json();
        console.log("UPDATED KNOWLEDGE BASE CONTENT CHARACTER COUNT BELOW");
        console.log({ respons });
      } catch (error) {
        console.log(
          "ERROR OCCURRED WHILE UPDATING KNOWLEDGE BASE CONTENT CHARACTER COUNT"
        );
        console.log({ error });
      }
    }

    try {
      // Update the document content
      const res = await fetch(`${config.apiUrl}/updateDocument`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          OrganizationSiteID: orgSite.OrganizationSiteID,
          SiteKnowledgeBaseID: editingDoc.SiteKnowledgeBaseID,
          SiteKnowledgeBaseContent: editedDoc,
          SiteKnowledgeBaseSource: source,
        }),
      });
      const respons = await res.json();
      console.log("EDITED METADATA PAIRS BELOW");
      console.log({ metadataPairs });

      // Update metadata pairs using a traditional for loop
      for (let i = 0; i < metadataPairs.length; i++) {
        const meta = metadataPairs[i];
        try {
          console.log("for meta: ", { meta });
          if (
            meta.id === -1 &&
            meta.name.trim() !== "" &&
            meta.value.trim() !== "" &&
            meta.deleted !== true
          ) {
            console.log("ADDING METADATA");
            await fetch(`${config.apiUrl}/addMetadata`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                SiteKnowledgeBaseID: editingDoc.SiteKnowledgeBaseID,
                OrganizationSiteID: orgSite.OrganizationSiteID,
                SiteKnowledgeBaseMetaDataName: meta.name,
                SiteKnowledgeBaseMetaDataValue: meta.value,
              }),
            });
          } else if (
            meta.edited &&
            meta.deleted !== true &&
            meta.name.trim() !== "" &&
            meta.value.trim() !== ""
          ) {
            console.log("EDITING METADATA");
            await fetch(`${config.apiUrl}/editMetadata`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                SiteKnowledgeBaseMetaDataNameValueID: meta.id,
                SiteKnowledgeBaseMetaDataName: meta.name,
                SiteKnowledgeBaseMetaDataValue: meta.value,
              }),
            });
          } else if (
            meta.deleted === true &&
            meta.id !== -1 &&
            meta.name.trim() !== "" &&
            meta.value.trim() !== ""
          ) {
            console.log("DELETING METADATA");
            await fetch(`${config.apiUrl}/deleteMetadata`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                SiteKnowledgeBaseMetaDataNameValueID: meta.id,
              }),
            });
          } else {
            console.log("KEPT SAME METADATA");
          }
          if (meta.deleted === true) {
            metadataPairs.splice(i, 1);
            i--; // Adjust the index after removing an item
          }
        } catch (error) {
          console.error("Error occurred while processing metadata:", error);
        }
      }

      // Update the orgSite and editingDoc
      editingDoc.SiteKnowledgeBaseContent = editedDoc;
      editingDoc.SiteKnowledgeBaseSource = source;
      editingDoc.SiteKnowledgeBaseMetaDataNameValue = metadataPairs;
      orgSite.SiteKnowledgeBase.splice(docIndex, 1, editingDoc);
      console.log("ORG SITE AFTER EDITING DOCUMENT BELOW");
      console.log({ orgSite });

      // Update sessionStorage
      info.user.OrganizationSite.find(
        (site: any) => site.OrganizationSiteID === orgSite.OrganizationSiteID
      ).SiteKnowledgeBase = orgSite.SiteKnowledgeBase;
      sessionStorage.setItem("info", JSON.stringify(info));
      sessionStorage.removeItem("orgSite");
      sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
      orgSite1 = sessionStorage.getItem("orgSite");
      setSource("");
      // Reset states
      setEditDoc(false);
      setEditingDoc(null);
      setEditedDoc("");
      setEditedMetadata([]);
      but.disabled = false;
      but.style.backgroundColor = "revert";
      but.style.color = "revert";
      but.style.borderColor = "revert";
      document.body.style.cursor = "default";
      can.disabled = false;
      can.style.color = "revert";
      can.style.cursor = "revert";
    } catch (error) {
      console.error("Error occurred while updating document:", error);
    }
    setLoading(false);
  };

  const handleDelete = async (index: number) => {
    setLoading(true);

    await fetch(`${config.apiUrl}/deleteDocument`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        SiteKnowledgeBaseID:
          orgSite.SiteKnowledgeBase[index].SiteKnowledgeBaseID,
        OrganizationSiteID: orgSite.OrganizationSiteID,
      }),
    });
    knowledgeBaseContentCharacterCount -=
      orgSite.SiteKnowledgeBase[index].SiteKnowledgeBaseContent.length;
    info.user.ContentSize -=
      orgSite.SiteKnowledgeBase[index].SiteKnowledgeBaseContent.length;
    console.log("DELETING DOCUMENT BELOW", { orgSite });
    orgSite.SiteKnowledgeBase.splice(index, 1);
    info.user.OrganizationSite.find(
      (site: any) => site.OrganizationSiteID === orgSite.OrganizationSiteID
    ).SiteKnowledgeBase = orgSite.SiteKnowledgeBase;
    sessionStorage.setItem("info", JSON.stringify(info));
    sessionStorage.setItem("orgSite", JSON.stringify(orgSite));
    const orgSiteData = sessionStorage.getItem("orgSite");
    if (orgSiteData) {
      orgSite = JSON.parse(orgSiteData);
    } else {
      console.error("orgSite data is null");
    }

    console.log("AFTER DELETING DOCUMENT BELOW", { orgSite });

    setRerender(!rerender);
    setLoading(false);
  };

  const handleScrapeLink = () => {
    setIsScraped(true);
    setUploadDocument(
      <>
        <label>Scrape Link</label>
        <input
          maxLength={2048}
          type="text"
          value={uploadedDocument}
          onChange={(e) => setUploadedDocument(e.target.value)}
          className="orgadmin-source-text"
          placeholder="Enter a link to scrape..."
        />
      </>
    );
  };

  const uploadDoc = () => {
    setIsScraped(false);
    setUploadDocument(
      <>
        <label>Upload Document</label>
        <input
          draggable="false"
          onChange={(e) =>
            setUploadedDocument(e.target.files ? e.target.files[0] : null)
          }
          type="file"
          accept=".docx,.pdf,.txt,.csv"
          style={{
            alignSelf: "center",
            color: "white",
            marginLeft: "150px",
            width: "auto",
          }}
        />
      </>
    );
  };
  const pasteDoc = () => {
    setIsScraped(false);
    setUploadDocument(
      <>
        <label>Paste Content Below</label>
        <input
          value={uploadedDocument}
          onChange={(e) => setUploadedDocument(e.target.value)}
          type="text"
          maxLength={73741823}
          className="orgadmin-source-text"
          placeholder="Paste your document here..."
        />
      </>
    );
  };

  if (!user) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1 style={{ fontSize: "30px" }}>Unauthorized</h1>
      </div>
    );
  }

  return (
    <>
      {/* ================================= NAV HERE =================================*/}
      <nav className="org-admin-nav">
        <ul>
          <div className="icon-container">
            <img
              className="icon-real-cyberchat"
              src={icon}
              alt="cyber assistant logo"
            />
            <img className="icon-cyberchat" src={name} alt="cyber assistant" />
          </div>

          <div className="icon-container">
            <li className="icon">
              <div className="dropdown">
                <svg
                  className="dropdown-button"
                  onClick={() => setShowProfiles(!showProfile)}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>

                {showProfile && (
                  <div className="dropdown-content">
                    <a
                      onClick={(e) => {
                        setDisplayWelcome(false);
                        setDisplayBotPlan(false);
                        setDisplayEmbeddings(false);
                        setDisplayCancelSubscription(false);
                        setDisplayLLM(false);
                        setDisplayKnowledgeBase(false);
                        setDisplayUI(false);
                        setDisplayUpgradePlan(false);
                        setDisplayDowngradePlan(false);
                        setDisplayProfile(true);
                        setDisplaySettings(false);
                      }}
                    >
                      Profile Settings
                    </a>
                    <a onClick={(e) => sessionStorage.clear()} href="/">
                      Logout
                    </a>
                  </div>
                )}
              </div>
            </li>
            <li
              onClick={(e) => {
                setDisplayWelcome(false);
                setDisplayBotPlan(false);
                setDisplayEmbeddings(false);
                setDisplayCancelSubscription(false);
                setDisplayLLM(false);
                setDisplayKnowledgeBase(false);
                setDisplayUI(false);
                setDisplayUpgradePlan(false);
                setDisplayDowngradePlan(false);
                setDisplayProfile(false);
                setDisplaySettings(true);
              }}
              style={{ marginRight: "40px" }}
              className="icon"
            >
              <svg
                className="settings-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
              </svg>
            </li>
          </div>
        </ul>
      </nav>
      {/*==============================================NAV ENDS HERE======================================= */}

      {/* handle isIdle for the modal */}
      {isIdle && showModal && (
        <div className="modal">
          <div className="modal-content logout-modal">
            <h2 style={{ textDecoration: "underline", fontSize: "1.3rem" }}>
              Idle Timeout Warning
            </h2>
            <p>You are about to be logged out due to inactivity.</p>
            <svg
              className="logout-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z" />
            </svg>
            <br />
            Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
            <br />
            <div className="row">
              <button className="btn btn-danger" onClick={handleLogOut}>
                Exit
              </button>
              <button
                style={{ marginRight: "20px" }}
                className="btn btn-primary "
                onClick={handleStayLoggedIn}
              >
                Stay
              </button>
            </div>
          </div>
        </div>
      )}

      {/* cancel subscription modal */}

      {/* loading modal */}
      {loading && (
        <div className="modal">
          <div className="modal-content modal-loading-content">
            <img src={loadingGIF} alt="loading" className="loadingGIF" />
            <div className="loader"></div>
          </div>
        </div>
      )}

      {/* chat history modal */}
      {showChatHistory && (
        <div className="modal">
          <div className="modal-content chat-history-modal-content">
            <div className="chat-history-header">
              <p
                className="chat-history-exit"
                onClick={(e) => setShowChatHistory(false)}
              >
                ❌
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h1 className="chat-history-header-title">
                  Chat History For:{" "}
                  {chatHistoryName === "" ? "Anonymous" : chatHistoryName}
                </h1>
                <p className="chat-history-header-title-text">
                  {chatHistoryEmail === "" ? "" : chatHistoryEmail}
                </p>
                <p className="chat-history-header-title-text">
                  Chatting from {chatHistoryLocation}
                </p>
              </div>
              <p className="chat-history-date">{chatHistoryDate}</p>
            </div>
            <div className="chat-history-convo-container">
              {chatHistory &&
                chatHistory.map((chat: any) => {
                  return (
                    <>
                      <div className="chat-history-convo-individual-container">
                        <h2 className="chat-history-convo-individual-container-bot">
                          User:{" "}
                        </h2>
                        <p className="chat-history-convo-individual-container-chat">
                          {chat.UserQuestion}
                        </p>
                        <p className="chat-history-convo-individual-container-time">
                          {chat.CreateDate}
                        </p>
                      </div>
                      <div className="chat-history-convo-individual-container">
                        <h2 className="chat-history-convo-individual-container-bot">
                          Bot:{" "}
                        </h2>
                        <p className="chat-history-convo-individual-container-chat">
                          {chat.Response}
                        </p>
                        <p className="chat-history-convo-individual-container-time">
                          {chat.CreateDate}
                        </p>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      )}

      <div style={{ display: "flex", cursor: loading ? "wait" : "default" }}>
        <div className="orgadmin-nav-side-container">
          <h1
            style={{
              color: displayWelcome ? "var(--dark-blue)" : "var(--light-blue)",
              transform: displayWelcome ? "scale(1.3)" : "scale(1)",
              paddingLeft: displayWelcome ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayWelcome(true);
              setDisplayBotPlan(false);
              setDisplayEmbeddings(false);
              setDisplayCancelSubscription(false);
              setDisplayLLM(false);
              setDisplayKnowledgeBase(false);
              setDisplayUI(false);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayAnalytics(false);
              setDisplayUserRecords(false);
              setDisplayProfile(false);
              setDisplaySettings(false);
              setDisplayPayment(false);
              setDisplayPaymentSuccess(false);
            }}
          >
            Welcome
          </h1>
          <h1
            style={{
              color: displayBotPlan ? "var(--dark-blue)" : "var(--light-blue)",
              transform: displayBotPlan ? "scale(1.3)" : "scale(1)",
              paddingLeft: displayBotPlan ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayBotPlan(true);
              setDisplayWelcome(false);
              setDisplayEmbeddings(false);
              setDisplayCancelSubscription(false);
              setDisplayLLM(false);
              setDisplayPayment(false);
              setDisplayPaymentSuccess(false);
              setDisplayKnowledgeBase(false);
              setDisplayUI(false);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayAnalytics(false);
              setDisplayUserRecords(false);
              setDisplayProfile(false);
              setDisplaySettings(false);
            }}
          >
            Billing
          </h1>
          {/*
            <h1 style={{color: displayEmbeddings ? 'var(--dark-blue)' : 'var(--light-blue)', 
            transform: displayEmbeddings ? 'scale(1.3)' : 'scale(1)',
            paddingLeft: displayEmbeddings ? '10px' : '0px'}}
            onClick={() => {setDisplayWelcome(false)
            setDisplayBotPlan(false)
            setDisplayEmbeddings(true)
            setDisplayCancelSubscription(false);
            setDisplayLLM(false)
            setDisplayKnowledgeBase(false)
            setDisplayUI(false)
            setDisplayUpgradePlan(false);
        setDisplayDowngradePlan(false);
            setDisplayProfile(false)
            setDisplaySettings(false)
            }}
            >Embeddings</h1>
            <h1 style={{color: displayLLM ? 'var(--dark-blue)' : 'var(--light-blue)', 
            transform: displayLLM ? 'scale(1.3)' : 'scale(1)',
            paddingLeft: displayLLM ? '10px' : '0px'}}
            onClick={() => {setDisplayWelcome(false)
            setDisplayBotPlan(false)
            setDisplayEmbeddings(false)
            setDisplayCancelSubscription(false);
            setDisplayLLM(true)
            setDisplayKnowledgeBase(false)
            setDisplayUI(false)
            setDisplayUpgradePlan(false);
        setDisplayDowngradePlan(false);
            setDisplayProfile(false)
            setDisplaySettings(false)
            }}>LLM Model</h1>
            */}
          <h1
            style={{
              color: displayKnowledgeBase
                ? "var(--dark-blue)"
                : "var(--light-blue)",
              transform: displayKnowledgeBase ? "scale(1.3)" : "scale(1)",
              paddingLeft: displayKnowledgeBase ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayWelcome(false);
              setDisplayBotPlan(false);
              setDisplayEmbeddings(false);
              setDisplayCancelSubscription(false);
              setDisplayLLM(false);
              setDisplayKnowledgeBase(true);
              setDisplayUI(false);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayPayment(false);
              setDisplayPaymentSuccess(false);
              setDisplayAnalytics(false);
              setDisplayUserRecords(false);
              setDisplayProfile(false);
              setDisplaySettings(false);
            }}
          >
            Knowledge Base
          </h1>
          <h1
            style={{
              color: displayUI ? "var(--dark-blue)" : "var(--light-blue)",
              transform: displayUI ? "scale(1.3)" : "scale(1)",
              paddingLeft: displayUI ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayWelcome(false);
              setDisplayBotPlan(false);
              setDisplayEmbeddings(false);
              setDisplayCancelSubscription(false);
              setDisplayLLM(false);
              setDisplayKnowledgeBase(false);
              setDisplayUI(true);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayPayment(false);
              setDisplayPaymentSuccess(false);
              setDisplayAnalytics(false);
              setDisplayUserRecords(false);
              let orgSite2 = orgSite;
              orgSite2.SiteUITypeDetail = orgSite2.SiteUITypeDetail.filter(
                (detail: any) => detail.SiteUITypeDetailName !== "fontImport"
              );
              firstHalf = orgSite2.SiteUITypeDetail.slice(0, midpoint);
              secondHalf = orgSite2.SiteUITypeDetail.slice(midpoint);
              setRerender(true);
              console.log("FIRST HALF");
              console.log({ firstHalf });

              setDisplayProfile(false);
              setDisplaySettings(false);
            }}
          >
            User Interface
          </h1>
          <h1
            style={{
              color: displayUserRecords
                ? "var(--dark-blue)"
                : "var(--light-blue)",
              transform: displayUserRecords ? "scale(1.3)" : "scale(1)",
              paddingLeft: displayUserRecords ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayWelcome(false);
              setDisplayBotPlan(false);
              setDisplayEmbeddings(false);
              setDisplayCancelSubscription(false);
              setDisplayLLM(false);
              setDisplayPaymentSuccess(false);
              setDisplayPayment(false);
              setDisplayKnowledgeBase(false);
              setDisplayUI(false);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayProfile(false);
              setDisplayAnalytics(false);
              setDisplayUserRecords(true);
              setDisplaySettings(false);
            }}
          >
            User Records
          </h1>
          <h1
            style={{
              color: displayAnalytics
                ? "var(--dark-blue)"
                : "var(--light-blue)",
              transform: displayAnalytics ? "scale(1.3)" : "scale(1)",
              paddingLeft: displayAnalytics ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayWelcome(false);
              setDisplayBotPlan(false);
              setDisplayCancelSubscription(false);
              setDisplayEmbeddings(false);
              setDisplayLLM(false);
              setDisplayPayment(false);
              setDisplayPaymentSuccess(false);
              setDisplayKnowledgeBase(false);
              setDisplayUI(false);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayProfile(false);
              setDisplayAnalytics(true);
              setDisplayUserRecords(false);
              setDisplaySettings(false);
            }}
          >
            Analytics
          </h1>
          <h1
            style={{
              color: displayProfile ? "var(--dark-blue)" : "var(--light-blue)",
              transform: displayProfile ? "scale(1.3)" : "scale(1)",
              paddingLeft: displayProfile ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayWelcome(false);
              setDisplayBotPlan(false);
              setDisplayEmbeddings(false);
              setDisplayCancelSubscription(false);
              setDisplayLLM(false);
              setDisplayPayment(false);
              setDisplayPaymentSuccess(false);
              setDisplayKnowledgeBase(false);
              setDisplayUI(false);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayProfile(true);
              setDisplayAnalytics(false);
              setDisplayUserRecords(false);
              setDisplaySettings(false);
            }}
          >
            Profile
          </h1>
          <h1
            style={{
              color: displaySettings ? "var(--dark-blue)" : "var(--light-blue)",
              transform: displaySettings ? "scale(1.3)" : "scale(1)",
              paddingLeft: displaySettings ? "10px" : "0px",
            }}
            onClick={() => {
              setDisplayWelcome(false);
              setDisplayBotPlan(false);
              setDisplayEmbeddings(false);
              setDisplayCancelSubscription(false);
              setDisplayLLM(false);
              setDisplayPayment(false);
              setDisplayPaymentSuccess(false);
              setDisplayAnalytics(false);
              setDisplayUserRecords(false);
              setDisplayKnowledgeBase(false);
              setDisplayUI(false);
              setDisplayUpgradePlan(false);
              setDisplayDowngradePlan(false);
              setDisplayProfile(false);
              setDisplaySettings(true);
            }}
          >
            Site Settings
          </h1>
        </div>

        <div className="orgadmin-body-container">
          <h1 style={{ paddingTop: "80px" }}></h1>

          {displayWelcome ? (
            <>
              <div className="welcome-container">
                <h1
                  style={{
                    height: "44px",
                    backgroundColor: "var(--dark-blue)",
                    border: "3px solid var(--light-blue)",
                    textShadow:
                      "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "-20px",
                    paddingTop: "20px",
                  }}
                  className="orgadmin-heading"
                >
                  Welcome {user.UserFirstName}!
                </h1>
                {orgSite.OrganizationSiteWelcomeMessage ? (
                  <p style={{ color: "red", fontSize: "1.1rem" }}>
                    {orgSite.OrganizationSiteWelcomeMessage}
                  </p>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <h3 className="orgadmin-subheading">Current Site:</h3>
                  <h1
                    style={{
                      color: "var(--light-blue)",
                      marginLeft: "5px",
                      marginTop: "23px",
                      fontWeight: "bold",
                    }}
                  >
                    {orgSite.OrganizationSiteName}
                  </h1>
                  <h1
                    style={{
                      color: "var(--dark-blue)",
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "1.8rem",
                      marginTop: "11px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setDisplayWelcome(false);
                      setDisplayBotPlan(false);
                      setDisplayEmbeddings(false);
                      setDisplayCancelSubscription(false);
                      setDisplayLLM(false);
                      setDisplayAnalytics(false);
                      setDisplayUserRecords(false);
                      setDisplayKnowledgeBase(false);
                      setDisplayUI(false);
                      setDisplayUpgradePlan(false);
                      setDisplayDowngradePlan(false);
                      setDisplayPayment(false);
                      setDisplayPaymentSuccess(false);
                      setDisplayProfile(false);
                      setDisplaySettings(true);
                    }}
                  >
                    ⇆
                  </h1>
                </div>

                <h3 className="orgadmin-subheading">Instructions for Use</h3>
                <p>
                  Below are some guidelines for embedding the SmartAssist AI Bot
                  in your website. Please read them carefully before proceeding.
                </p>
                <p>
                  <b>Before Use</b>
                </p>
                <ul>
                  <li style={{ marginBottom: "12px" }}>
                    Make sure the knowledge base contains at least one training
                    document, you will not be able to access the client secret
                    key without it.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Verify that the domain name is correct in the{" "}
                    <a
                      onClick={(e) => {
                        setDisplayWelcome(false);
                        setDisplaySettings(true);
                      }}
                      className="settings-link"
                    >
                      site settings
                    </a>
                    . The chatbot will not be displayed otherwise.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Make the various configurations so the chatbot functions and
                    looks exactly how you would want it to by navigating through
                    the various links in the side panel.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Keep in mind that the chatbot will not work on your local
                    development environment because it's tied to the registered
                    domain in the site settings. It will only function correctly
                    when accessed from the production site with the correct
                    domain.
                  </li>
                </ul>
                <p>
                  <b>Embedding the Chatbot</b>
                </p>
                <ol>
                  <li>
                    Copy the client secret key from the{" "}
                    <a className="settings-link">site settings</a> page.
                  </li>
                  <li>
                    Paste the following two lines of code in the root of the
                    body of your HTML code:
                  </li>
                </ol>
                <pre>
                  <code>
                    {`<chat-bot client-secret-key="${
                      orgSite.SiteKnowledgeBase.length >= 1
                        ? orgSite.OrganizationSiteClientSecretID
                        : "Please upload documents to access the key."
                    }"></chat-bot>\n<script src="${
                      window.location.origin
                    }/scripts/v1/bot.js"></script>`}
                  </code>
                </pre>
                <ol start={3}>
                  <li>
                    The chatbot should now be displayed on your website. If you
                    have any issues, please contact us at
                    support@smartassistai.com
                  </li>
                </ol>
                <p style={{ paddingTop: "25px" }}>
                  <b className="orgadmin-subheading">Troubleshooting Guide</b>
                  <ol>
                    <li style={{ marginBottom: "15px" }}>
                      <b>Please buy from MZM Technologies:</b> make sure the
                      domain name is correct in the site settings. Another
                      reason for this message may be that you deleted the site
                      or the payments for your plan have not been processed
                      causing your site to be disabled. Please check the billing
                      section for more information.
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      <b>Please upload documents to access the bot: </b>this
                      message appears when there are no training documents in
                      the knowledge base. Please upload documents to gain access
                      to the bot.
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      <b>Your knowledge base content is too large:</b> this
                      message appears when the total character count of the
                      knowledge base exceeds the limit for your plan. Please
                      delete some documents to reduce the character count.
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      <b>You have reached the token limit for your plan: </b>
                      this message appears when the token limit for the plan has
                      been reached. Please upgrade your plan to access more
                      tokens.
                    </li>
                  </ol>
                </p>
              </div>
            </>
          ) : null}

          {displayPaymentSuccess ? <PaymentSuccess /> : null}

          {displayPayment ? (
            <ProcessPayment
              changeCardInfo={changeCardInfo}
              info={info}
              handleChangePlan={handleChangePlan}
              paymentPlan={paymentPlan}
              setDisplayPaymentSuccess={setDisplayPaymentSuccess}
              setDisplayPayment={setDisplayPayment}
              name={user.UserFirstName + " " + user.UserLastName}
              email={user.UserEmail}
              OrganizationSiteID={orgSite.OrganizationSiteID}
            />
          ) : null}

          {displayUserRecords ? (
            <UserRecords
              orgSite={orgSite}
              setDisplaySettings={setDisplaySettings}
              setDisplayUserRecords={setDisplayUserRecords}
              showChatHistory={showChatHistory}
              setShowChatHistory={setShowChatHistory}
              OrganizationSiteID={orgSite.OrganizationSiteID}
              setChatHistory={setChatHistory}
              setChatHistoryName={setChatHistoryName}
              setChatHistoryDate={setChatHistoryDate}
              setChatHistoryEmail={setChatHistoryEmail}
              setChatHistoryLocation={setChatHistoryLocation}
              setLoading={setLoading}
            />
          ) : null}
          {displayBotPlan ? (
            <>
              {rerender ? (
                setRerender(false)
              ) : (
                <>
                  <h1
                    style={{
                      backgroundColor: "var(--dark-blue)",
                      border: "3px solid var(--light-blue)",
                      textShadow:
                        "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                      color: "white",
                      width: "100%",
                      textAlign: "center",
                      padding: "10px",
                      marginTop: "0px",
                    }}
                    className="orgadmin-heading"
                  >
                    Billing
                  </h1>

                  <BotPlan
                    handleProcessChangePlan={handleProcessChangePlan}
                    selectedBotPlanID={info.user.BotPlan.BotPlanID}
                    botPlans={info.botPlans}
                  />
                  {/*
                  <div className="carousel-container">
                    <button
                      className="carousel-button prev"
                      onClick={handlePrev}
                    >
                      ◄
                    </button>
                    <div className="orgadmin-plans-container">
                      {info.botPlans.map((plan: any, index: any) => {
                        let isSelectedPlan =
                          paymentPlan &&
                          paymentPlan.BotPlanID === plan.BotPlanID;
                        const containerWidth = isSelectedPlan
                          ? 338 + 20
                          : 338;

                        return (
                          <div
                            key={plan.BotPlanID}
                            className={
                              isSelectedPlan
                                ? "orgadmin-plans-selected-container-outside"
                                : "orgadmin-plans-container-outside"
                            }
                            style={{
                              transform: `translateX(-${currentIndex * 413}px)`,
                            }}
                          >
                            {isSelectedPlan && (
                              <h1 className="orgadmin-plans-selected-container-outside-h1">
                                I want this plan
                              </h1>
                            )}
                            <div
                              onClick={(e) => setPaymentPlan(plan)}
                              className={`orgadmin-plan ${
                                plan.BotPlanID === info.user.BotPlan.BotPlanID
                                  ? "orgadmin-plan-dark"
                                  : ""
                              }`}
                            >
                              <h3
                                style={{
                                  alignSelf: "left",
                                  fontWeight: "bolder",
                                  fontSize: "16px",
                                  margin: "5px",
                                  marginBottom: "0px",
                                }}
                              >
                                {plan.BotPlanID === info.user.BotPlan.BotPlanID
                                  ? `👉🏻 ${plan.BotPlanName}`
                                  : plan.BotPlanName}
                              </h3>

                              {plan.BotPlanRule.map(
                                (rule: any, index: number) => {
                                  if (
                                    rule.BotPlanRuleName.toLowerCase() ===
                                    "payment amount"
                                  ) {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <h1
                                          key={rule.BotPlanRuleID}
                                          style={{
                                            margin: 0,
                                            fontSize: "2.3rem",
                                          }}
                                        >
                                          ${rule.BotPlanRuleValue}
                                        </h1>
                                        <p
                                          style={{
                                            margin: 0,
                                            paddingLeft: "5px",
                                            fontSize: "0.7rem",
                                          }}
                                        >
                                          per month
                                        </p>
                                      </div>
                                    );
                                  }
                                }
                              )}

                              <p>{plan.BotPlanDescription}</p>
                              <p>
                                <b>✓</b> {plan.Embedder.EmbedderName} embedder
                              </p>
                              <p>
                                <b>✓</b> {plan.Model.ModelName} LLM model
                              </p>
                              <p>
                                <b>✓ </b>supports {plan.Language.LanguageName}
                              </p>
                              {plan.BotPlanRule.map(
                                (rule: any, index: number) => {
                                  if (
                                    rule.BotPlanRuleName.toLowerCase() ===
                                      "payment required" &&
                                    rule.BotPlanRuleValue === "Yes"
                                  ) {
                                    return null;
                                  } else if (
                                    rule.BotPlanRuleName.toLowerCase() ===
                                      "payment amount" &&
                                    rule.BotPlanRuleValue !== "0"
                                  ) {
                                    return null;
                                  }
                                  return (
                                    <p key={rule.BotPlanRuleID}>
                                      <b>✓</b> {rule.BotPlanRuleValue}{" "}
                                      {rule.BotPlanRuleName}
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      className="carousel-button next"
                      onClick={handleNext}
                    >
                      ►
                    </button>
                  </div>

                  */}
                  <div className="orgadmin-plan-button-container">
                    {info.user.BotPlan.BotPlanID !== 1 &&
                      !info.user.bypassPayment && (
                        <button
                          className="orgadmin-button orgadmin-plan-button"
                          onClick={(e) => {
                            setDisplayCancelSubscription(true);
                          }}
                        >
                          Cancel Subscription
                        </button>
                      )}
                  </div>

                  {info.user.BotPlan.BotPlanID !== 1 &&
                    !info.user.bypassPayment && (
                      <>
                        <h3 className="orgadmin-subheading">
                          Card Information
                        </h3>
                        {displayEditPaymentMethod ? (
                          <UpdatePaymentMethod
                            changeCardInfo={changeCardInfo}
                            setDisplayConfirmCard={setDisplayBotPlan}
                            customerId={info.payment.PaymentProcessorCustomerID}
                            setDisplayPaymentChange={
                              setDisplayEditPaymentMethod
                            }
                          />
                        ) : (
                          <div className="orgadmin-card-container">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p className="orgadmin-card-information">
                                {info.payment.PaymentMethod.toUpperCase()}{" "}
                                <span className="orgadmin-card-information-inner-text">
                                  ending in{" "}
                                  {info.payment.CustomerCardPartialInfo}
                                </span>
                              </p>
                              <p className="orgadmin-card-information-inner-text">
                                Expires{" "}
                                {info.payment.CustomerCardExpirationDate}
                              </p>
                            </div>
                            <u
                              className="orgadmin-card-information-inner-text"
                              onClick={(e) => {
                                setDisplayEditPaymentMethod(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="orgadmin-card-information"
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  fontSize: "1.28rem",
                                }}
                              >
                                ✎
                              </span>{" "}
                              Process payments with a different card
                            </u>
                          </div>
                        )}

                        <h3
                          className="orgadmin-subheading"
                          style={{ marginBottom: "0px" }}
                        >
                          Transaction History
                        </h3>
                        <p
                          style={{
                            color: "var(--light-blue)",
                            alignSelf: "flex-start",
                            marginTop: "0px",
                            justifySelf: "flex-start",
                            textAlign: "start",
                          }}
                        >
                          Billed monthly on the 15th.
                        </p>
                        <TransactionHistoryTable
                          info={info}
                          organizationID={info.user.OrganizationID}
                        />
                      </>
                    )}
                </>
              )}
            </>
          ) : null}

          {displayCancelSubscription ? (
            <CancelSubscription
              handleCancelSubscriptionBackend={handleCancelSubscription}
              info={info}
              setDisplayCancelSubscription={setDisplayCancelSubscription}
            />
          ) : null}

          {displayEmbeddings ? (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                Embeddings
              </h1>
              <div className="orgadmin-guidelines-container">
                <h3
                  onClick={() => setGuideline1(!guideline1)}
                  className="orgadmin-subheading"
                >
                  Guidelines
                </h3>
                {!guideline1 && (
                  <h3
                    onClick={() => setGuideline1(!guideline1)}
                    className="orgadmin-subheading"
                  >
                    ▼
                  </h3>
                )}
                {guideline1 && (
                  <h3
                    onClick={() => setGuideline1(!guideline1)}
                    className="orgadmin-subheading"
                  >
                    ▲
                  </h3>
                )}
              </div>
              {guideline1 && (
                <>
                  <h4 className="orgadmin-paragraph">
                    Embeddings are like smart dictionaries that help chatbots
                    understand and respond to text more accurately. Some
                    embeddings are better because they learn from larger and
                    more varied data, making them more precise and
                    context-aware. The embedding model you choose dictates how
                    natural and effective conversations with your chatbot are.
                  </h4>
                </>
              )}
              <h3 className="orgadmin-subheading">Embedding Options</h3>
              <select onChange={handleSelectChange} className="orgadmin-select">
                <option>
                  <b>{info.user.BotPlan.Embedder.EmbedderName}:</b>{" "}
                  {info.user.BotPlan.Embedder.EmbedderDescription}
                </option>
                {info.embedders.map((embedder: any) => {
                  if (
                    embedder.EmbedderID ===
                    info.user.BotPlan.Embedder.EmbedderID
                  ) {
                    return null;
                  }
                  return (
                    <option>
                      <b>{embedder.EmbedderName}:</b>{" "}
                      {embedder.EmbedderDescription}
                    </option>
                  );
                })}
                {/* <option><b>mistral-embed:</b> The best option with advanced, high-accuracy embeddings from Mistral AI, ideal for the most natural and effective conversations.</option>
            <option><b>BAAI/bge-small-en-v1.5:</b> A reliable and easy-to-set-up option using HuggingFace's remote servers; provides good performance with an API key.
            </option>
            <option><b>all-MiniLM-L6-v2:</b> A basic, free option that runs on your machine; simple and efficient but less accurate than the others.</option>
                */}
              </select>
            </>
          ) : null}

          {displayLLM ? (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                LLM Model
              </h1>
              <div className="orgadmin-guidelines-container">
                <h3
                  onClick={() => setGuideline2(!guideline2)}
                  className="orgadmin-subheading"
                >
                  Guidelines
                </h3>
                {!guideline2 && (
                  <h3
                    onClick={() => setGuideline2(!guideline2)}
                    className="orgadmin-subheading"
                  >
                    ▼
                  </h3>
                )}
                {guideline2 && (
                  <h3
                    onClick={() => setGuideline2(!guideline2)}
                    className="orgadmin-subheading"
                  >
                    ▲
                  </h3>
                )}
              </div>
              {guideline2 && (
                <h4 className="orgadmin-paragraph">
                  Large Language Models (LLMs) are smart language models that
                  understand and generate human language. The best ones are
                  trained on huge datasets and use advanced techniques, making
                  them more accurate and context-aware for chatbot responses.
                  Superior LLMs provide better understanding and more accurate
                  answers due to their extensive training and sophisticated
                  design.
                </h4>
              )}
              <h3 className="orgadmin-subheading">LLM Options</h3>
              <select className="orgadmin-select">
                <option>Choose an option</option>
                <option>
                  <b>MISTRAL:</b> Offers high accuracy and advanced language
                  understanding for natural chatbot responses.
                </option>
                <option>
                  <b>AZURE:</b> The best option with superior accuracy and
                  language understanding, providing the most reliable and
                  precise chatbot responses.
                </option>
              </select>

              <h3 className="orgadmin-subheading">Other Configurations</h3>
              <div className="orgadmin-form-row">
                <label htmlFor="topP">
                  Top P{" "}
                  <span className="tooltip">
                    ?
                    <span className="tooltip-text">
                      Controls the randomness of the language used. The lower
                      this is the more the model will use the most probable
                      words from its trained data set which increases the
                      repetition between answers. The higher the value the more
                      the model will consider a wide variety of words for use.{" "}
                    </span>
                  </span>
                </label>
                <input
                  type="number"
                  id="topP"
                  name="topP"
                  min="0"
                  max="1"
                  step="0.01"
                />
              </div>
              <div className="orgadmin-form-row">
                <label htmlFor="temperature">
                  Temperature{" "}
                  <span className="tooltip">
                    ?
                    <span className="tooltip-text">
                      {" "}
                      Regulates the creativity of the response. The closer this
                      is to 0 the more focused the response will be and the bot
                      will more closely stick to its training data. Conversely,
                      values closer to 1 allows for more diverse responses but
                      may also lead to hallucinations.
                    </span>
                  </span>
                </label>
                <input
                  type="number"
                  min="0"
                  max="1"
                  step="0.01"
                  id="temperature"
                  name="temperature"
                />
              </div>

              <div className="orgadmin-button-container">
                <button className="orgadmin-button-save">Save</button>
                <button className="orgadmin-button-cancel">Cancel</button>
              </div>
            </>
          ) : null}

          {displayKnowledgeBase ? (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                Knowledge Base
              </h1>
              <div
                style={{
                  display: "flex",
                  alignContent: "flex-start",
                  justifyContent: "flex-start",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                <h3 className="orgadmin-subheading">Current Site:</h3>
                <h1
                  style={{
                    color: "var(--light-blue)",
                    marginLeft: "5px",
                    marginTop: "23px",
                    fontWeight: "bold",
                  }}
                >
                  {orgSite.OrganizationSiteName}
                </h1>
                <h1
                  style={{
                    color: "var(--dark-blue)",
                    marginLeft: "5px",
                    fontWeight: "bold",
                    fontSize: "1.8rem",
                    marginTop: "9px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setDisplayWelcome(false);
                    setDisplayBotPlan(false);
                    setDisplayEmbeddings(false);
                    setDisplayCancelSubscription(false);
                    setDisplayLLM(false);
                    setDisplayAnalytics(false);
                    setDisplayUserRecords(false);
                    setDisplayKnowledgeBase(false);
                    setDisplayUI(false);
                    setDisplayUpgradePlan(false);
                    setDisplayDowngradePlan(false);
                    setDisplayPayment(false);
                    setDisplayPaymentSuccess(false);
                    setDisplayProfile(false);
                    setDisplaySettings(true);
                  }}
                >
                  ⇆
                </h1>
              </div>
              <div
                className="orgadmin-guidelines-container"
                style={{ marginTop: "0" }}
              >
                <h3
                  style={{ marginTop: "0" }}
                  onClick={() => setGuideline3(!guideline3)}
                  className="orgadmin-subheading"
                >
                  Guidelines
                </h3>
                {!guideline3 && (
                  <h3
                    style={{ marginTop: "0" }}
                    onClick={() => setGuideline3(!guideline3)}
                    className="orgadmin-subheading"
                  >
                    ▼
                  </h3>
                )}
                {guideline3 && (
                  <h3
                    style={{ marginTop: "0" }}
                    onClick={() => setGuideline3(!guideline3)}
                    className="orgadmin-subheading"
                  >
                    ▲
                  </h3>
                )}
              </div>
              {guideline3 && (
                <h4 className="orgadmin-paragraph">
                  The knowledge base is a repository of information that the
                  chatbot is trained on and helps it provide accurate and
                  relevant responses. The more documents in the knowledge base,
                  the more information the chatbot has to draw from, making its
                  responses more accurate and helpful. The knowledge base is a
                  crucial part of the chatbot's intelligence.
                </h4>
              )}

              <h3 className="orgadmin-subheading">Manage Documents</h3>
              {Number(
                info.user.BotPlan.BotPlanRule.find((rule: any) =>
                  rule.BotPlanRuleName.toLowerCase().includes("content")
                ).BotPlanRuleValue
              ) -
                Number(info.user.ContentSize) <
              0 ? (
                <p className="change-this-color" style={{ color: "red" }}>
                  You have exceeded the character limit for the knowledge base.
                  Your bot is currently disabled until you remove documents.
                </p>
              ) : (
                <p style={{ margin: "0" }} className="change-this-color">
                  <b>Characters Remaining:</b>{" "}
                  {Number(
                    info.user.BotPlan.BotPlanRule.find((rule: any) =>
                      rule.BotPlanRuleName.toLowerCase().includes("content")
                    ).BotPlanRuleValue
                  ) - Number(info.user.ContentSize)}
                </p>
              )}
              <p style={{ margin: "0" }} className="change-this-color">
                <b>Characters Used for Site:</b>{" "}
                {Number(knowledgeBaseContentCharacterCount)}
              </p>

              {editDoc && (
                <>
                  <div className="orgadmin-upload-container orgadmin-documents-container">
                    <label>Source</label>
                    <input
                      maxLength={249}
                      placeholder="Enter name here..."
                      className="orgadmin-source-text"
                      type="text"
                      onChange={(e) => setSource(e.target.value)}
                      value={source}
                    />

                    <label>Content</label>
                    <textarea
                      name="blog"
                      rows={6}
                      className="orgadmin-source-text"
                      onChange={(e) => {
                        setEditedDoc(e.target.value);
                      }}
                      value={editedDoc}
                    />

                    <label>Upload Metadata</label>
                    <MetadataComponent
                      metadataPairs={metadataPairs}
                      setMetadataPairs={setMetadataPairs}
                    />
                  </div>
                  <div>
                    <div
                      style={{ marginTop: "25px" }}
                      className="orgadmin-button-container"
                    >
                      <button
                        style={{ width: "100px" }}
                        onClick={handleEditDocument}
                        className="orgadmin-button-save orgadmin-KB-upload"
                      >
                        Save
                      </button>
                      <button
                        className="orgadmin-button-cancel orgadmin-KB-upload-cancel"
                        onClick={(e) => {
                          setShowUpload(false);
                          console.log(
                            "CHANGING SOURCE TO EMPTY STRING BELOW 3"
                          );

                          setSource("");
                          setUploadDocument(null);
                          setEditDoc(false);
                          setEditingDoc(null);
                          setEditedDoc("");
                          setEditedMetadata([]);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}

              {showUpload && (
                <>
                  <div
                    className="orgadmin-upload-container orgadmin-documents-container"
                    id="orgadmin-KB-container"
                  >
                    {uploadDocument === null ? (
                      <>
                        <label>Method Of Transfer</label>
                        <div className="orgadmin-method-of-transfer-container">
                          <button onClick={handleScrapeLink}>
                            Scrape Link
                          </button>
                          <button onClick={uploadDoc}>Upload</button>
                          <button onClick={pasteDoc}>Paste</button>{" "}
                        </div>{" "}
                      </>
                    ) : null}
                    {uploadDocument}

                    <label>Upload Metadata</label>
                    <MetadataComponent
                      metadataPairs={metadataPairs2}
                      setMetadataPairs={setMetadataPairs2}
                    />
                    <label>Source</label>
                    <input
                      maxLength={249}
                      placeholder="Enter name here..."
                      className="orgadmin-source-text"
                      type="text"
                      onChange={(e) => setSource(e.target.value)}
                      value={source}
                    />
                  </div>
                  <div
                    style={{ marginTop: "25px" }}
                    className="orgadmin-button-container"
                  >
                    <button
                      style={{ width: "100px" }}
                      className="orgadmin-button-save orgadmin-KB-upload"
                      onClick={handleUpload}
                    >
                      Upload
                    </button>
                    <button
                      className="orgadmin-button-cancel orgadmin-KB-upload-cancel"
                      onClick={() => {
                        setShowUpload(false);
                        console.log("CHANGING SOURCE TO EMPTY STRING BELOW 4");

                        setSource("");
                        setUploadDocument(null);
                        setMetadataPairs2([
                          {
                            name: "",
                            value: "",
                            id: -1,
                            edited: false,
                            deleted: false,
                          },
                        ]);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}

              {!showUpload && !editDoc && (
                <>
                  <div className="orgadmin-documents-container">
                    <div className="orgadmin-grid-headings">
                      <h3 style={{ marginRight: "70px" }}>Source</h3>
                      <h3 style={{ marginRight: "170px" }}>Content</h3>
                    </div>
                    {orgSite.SiteKnowledgeBase.map(
                      (doc: any, index: number) => {
                        console.log("value of doc: ", { doc });
                        console.log("value of index: ", index);

                        return rerender ? (
                          setRerender(false)
                        ) : (
                          <div className="orgadmin-document" key={index}>
                            <div className="orgadmin-document-source-container">
                              <p>{doc.SiteKnowledgeBaseSource}</p>
                            </div>
                            <div className="orgadmin-document-content-container">
                              {/*style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} */}
                              <p>{doc.SiteKnowledgeBaseContent}</p>
                            </div>
                            <div className="orgadmin-document-modify">
                              <svg
                                onClick={() => {
                                  console.log(
                                    "Metadata Component: what is sent in"
                                  );
                                  console.log(
                                    doc.SiteKnowledgeBaseMetaDataNameValue
                                  );

                                  console.log(
                                    "Metadata Component: because of orgsite"
                                  );
                                  console.log({ orgSite });

                                  setMetadataPairs(
                                    doc.SiteKnowledgeBaseMetaDataNameValue.map(
                                      (meta: any, index: number) => {
                                        console.log("Metadata Component: id");
                                        console.log(
                                          meta.SiteKnowledgeBaseMetaDataNameValueID
                                        );
                                        return {
                                          id:
                                            meta.SiteKnowledgeBaseMetaDataNameValueID ||
                                            meta.id,
                                          edited: false,
                                          deleted: false,
                                          name:
                                            meta.SiteKnowledgeBaseMetaDataName ||
                                            meta.name,
                                          value:
                                            meta.SiteKnowledgeBaseMetaDataValue ||
                                            meta.value,
                                        };
                                      }
                                    )
                                  );
                                  console.log(
                                    "Metadata Component: what is sent out"
                                  );
                                  console.log({ metadataPairs });
                                  setDocIndex(index);
                                  console.log(
                                    "CHANGING SOURCE TO EMPTY STRING BELOW 5"
                                  );

                                  setSource(doc.SiteKnowledgeBaseSource);
                                  setEditDoc(true);
                                  setEditingDoc(doc);
                                  setEditedDoc(doc.SiteKnowledgeBaseContent);
                                  setEditedMetadata(
                                    doc.SiteKnowledgeBaseMetaDataNameValue
                                  );
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                              >
                                <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1 .8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                              </svg>
                              <svg
                                onClick={() => {
                                  handleDelete(index);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                              </svg>
                            </div>

                            {/*
               
            <div>

                <p>{doc.SiteKnowledgeBaseSource}</p>
                <div style={{width: '700px'}}>
                <p style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.9rem', fontWeight: 'normal'}}>{doc.SiteKnowledgeBaseContent}</p>
                </div>
                </div>
                <div className="orgadmin-document-modify">
                    <svg
                        onClick={() => { 
                            console.log("Metadata Component: what is sent in");
                            console.log(doc.SiteKnowledgeBaseMetaDataNameValue);

                            console.log("Metadata Component: because of orgsite");
                            console.log({orgSite});
                            
                            setMetadataPairs(doc.SiteKnowledgeBaseMetaDataNameValue.map((meta: any, index: number) => {
                                console.log("Metadata Component: id");
                                console.log(meta.SiteKnowledgeBaseMetaDataNameValueID);
                                return {id: meta.SiteKnowledgeBaseMetaDataNameValueID || meta.id, edited: false, deleted: false, name: meta.SiteKnowledgeBaseMetaDataName || meta.name, value: meta.SiteKnowledgeBaseMetaDataValue || meta.value};
                              }));
                              console.log("Metadata Component: what is sent out");
                                console.log({metadataPairs});
                              setDocIndex(index);
                              console.log("CHANGING SOURCE TO EMPTY STRING BELOW 5");

                              setSource(doc.SiteKnowledgeBaseSource);
                            setEditDoc(true); setEditingDoc(doc); setEditedDoc(doc.SiteKnowledgeBaseContent); setEditedMetadata(doc.SiteKnowledgeBaseMetaDataNameValue); }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                    >
                        <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1 .8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                    </svg>
                    <svg
                        onClick={() => {handleDelete(index)}}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                </div>
                 */}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <button
                    onClick={() => {
                      setUploadedDocument(null);
                      setShowUpload(!showUpload);
                    }}
                    className="orgadmin-upload-button"
                  >
                    Upload New Document
                  </button>{" "}
                </>
              )}
            </>
          ) : null}

          {displayProfile ? (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                Profile
              </h1>

              <ChangePasswordComponent
                fullUser={user}
                changePassword={changePassword}
                setChangePassword={setChangePassword}
              />

              {!changePassword && (
                <>
                  <div className="orgadmin-personal-info">
                    <h3 className="orgadmin-subheading">
                      Personal Information
                    </h3>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setEditProfile(true)}
                      viewBox="0 0 576 512"
                    >
                      <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1 .8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                    </svg>
                  </div>
                  <div className="orgadmin-organization-info-1">
                    <div className="orgadmin-organization-info-1-container">
                      <h5 style={{ color: "var(--dark-blue)", width: "90px" }}>
                        First Name:{" "}
                      </h5>
                      {!editProfile && <h5>{firstName}</h5>}
                      {editProfile && (
                        <>
                          <input
                            maxLength={49}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Enter first name..."
                            value={firstName}
                          />
                        </>
                      )}
                    </div>
                    <div>
                      <h5 style={{ color: "var(--dark-blue)", width: "90px" }}>
                        Last Name:{" "}
                      </h5>
                      {!editProfile && <h5>{lastName}</h5>}
                      {editProfile && (
                        <>
                          <input
                            maxLength={49}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Enter last name..."
                            value={lastName}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="orgadmin-organization-info-2">
                    <div>
                      <h5 style={{ color: "var(--dark-blue)", width: "90px" }}>
                        Email:{" "}
                      </h5>
                      <h5>{email}</h5>
                    </div>
                    <div>
                      <h5 style={{ color: "var(--dark-blue)", width: "90px" }}>
                        Job Title:{" "}
                      </h5>
                      {!editProfile && <h5>{jobTitle}</h5>}
                      {editProfile && (
                        <>
                          <input
                            maxLength={49}
                            onChange={(e) => setJobTitle(e.target.value)}
                            placeholder="Enter job title..."
                            value={jobTitle}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {editProfile && (
                    <>
                      <div
                        style={{ marginTop: "20px" }}
                        className="orgadmin-button-container"
                      >
                        <button
                          className="orgadmin-button-save"
                          onClick={handlePersonalInformationChange}
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setEditProfile(false);
                            setFirstName(user.UserFirstName);
                            setLastName(user.UserLastName);
                            setEmail(user.UserEmail);
                            setJobTitle(user.UserJobTitle);
                          }}
                          className="orgadmin-button-cancel"
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}

                  <div className="orgadmin-personal-info">
                    <h3 className="orgadmin-subheading">
                      Organization Information
                    </h3>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setDisplayOrganization(true)}
                      viewBox="0 0 576 512"
                    >
                      <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1 .8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                    </svg>
                  </div>
                  <div className="orgadmin-organization-info-1">
                    <div>
                      <h5 style={{ color: "var(--dark-blue)", width: "90px" }}>
                        Organization Name:{" "}
                      </h5>
                      {!displayOrganization && <h5>{organizationName}</h5>}
                      {displayOrganization && (
                        <>
                          <input
                            maxLength={99}
                            onChange={(e) =>
                              setOrganizationName(e.target.value)
                            }
                            placeholder="Enter name..."
                            value={organizationName}
                          />
                        </>
                      )}
                    </div>
                    <div>
                      <h5 style={{ color: "var(--dark-blue)", width: "90px" }}>
                        Description:{" "}
                      </h5>
                      {!displayOrganization && (
                        <h5>{organizationDescription}</h5>
                      )}
                      {displayOrganization && (
                        <>
                          <input
                            maxLength={249}
                            onChange={(e) =>
                              setOrganizationDescription(e.target.value)
                            }
                            placeholder="Enter description..."
                            value={organizationDescription}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  {displayOrganization && (
                    <>
                      <div
                        style={{ marginTop: "20px" }}
                        className="orgadmin-button-container"
                      >
                        <button
                          className="orgadmin-button-save"
                          onClick={handleOrganizationChange}
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setDisplayOrganization(false);
                            setOrganizationName(info.user.OrganizationName);
                            setOrganizationDescription(
                              info.user.OrganizationDescription
                            );
                          }}
                          className="orgadmin-button-cancel"
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                  {info.user.BotPlan.BotPlanID === 1 ||
                  info.user.OrganizationSite.length() < 2 ? null : (
                    <>
                      <h3
                        style={{ display: "flex" }}
                        className="orgadmin-subheading"
                      >
                        Priority Sites List{" "}
                        <span className="tooltip tooltip-priority-list">
                          ?
                          <span className="tooltip-text">
                            This list determines what site(s) get kept when
                            downgrading plans or cancelling subscription. Also,
                            in case your card doesnt go through, you will be
                            automatically switched to freemium and only the
                            first site will be kept, the rest disabled.
                          </span>
                        </span>
                      </h3>
                      <h5
                        style={{
                          color: "var(--dark-blue)",
                          marginTop: "0px",
                          textAlign: "center",
                        }}
                      >
                        Drag and drop to reorder
                      </h5>
                      <DraggableList
                        handleNewInfo={handleNewInfo}
                        setLoading={setLoading}
                        type={1}
                        info={info}
                      />
                    </>
                  )}

                  <div className="orgadmin-personal-info">
                    <h3
                      onClick={() => {
                        setChangePassword(true);
                      }}
                      className="orgadmin-subheading"
                    >
                      Change Password
                    </h3>

                    <svg
                      onClick={() => {
                        setChangePassword(true);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
                    </svg>
                  </div>

                  <div className="orgadmin-personal-info">
                    <h3 onClick={handleLogout} className="orgadmin-subheading">
                      Logout
                    </h3>

                    <svg
                      onClick={handleLogout}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
                    </svg>
                  </div>

                  <div className="orgadmin-personal-info">
                    <h3
                      onClick={handleDeleteAccount}
                      className="orgadmin-subheading"
                      style={{ color: "var(--red)" }}
                    >
                      Delete Account
                    </h3>

                    <svg
                      onClick={handleDeleteAccount}
                      style={{ fill: "var(--red)" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                  </div>
                </>
              )}
            </>
          ) : null}

          {displaySettings && (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                Site Settings
              </h1>
              <h3 className="orgadmin-subheading current-site">
                Current Site:
                <select
                  value={orgSite.OrganizationSiteName}
                  onChange={handleSiteChange}
                >
                  {info.user.OrganizationSite.map(
                    (site: any, index: number) => (
                      <option key={index} value={site.OrganizationSiteName}>
                        {site.OrganizationSiteName}
                      </option>
                    )
                  )}
                </select>
              </h3>
              {rerender
                ? setRerender(false)
                : displaySettings && (
                    <SiteSettings
                      handleNewOrgSite={handleNewOrgSite}
                      handleNewInfo={handleNewInfo}
                      setLoading={setLoading}
                      site={orgSite}
                      setSelectedSite={setSelectedSite}
                      setRerender={setRerender}
                      info={info}
                      orgSite={orgSite}
                      index={info.user.OrganizationSite.findIndex(
                        (site: any, index: number) =>
                          site.OrganizationSiteName ===
                          orgSite.OrganizationSiteName
                      )}
                    />
                  )}
            </>
          )}

          {displayAnalytics && (
            <Analytics
              setDisplaySettings={setDisplaySettings}
              handleShowBotPlanPage={handleShowBotPlanPage}
              knowledgeBaseContentCharacterCount={
                knowledgeBaseContentCharacterCount
              }
              info={info}
              user={user}
              orgSite={orgSite}
              displayAnalytics={displayAnalytics}
              setDisplayAnalytics={setDisplayAnalytics}
            />
          )}

          {displayDowngradePlan ? (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                Downgrade Plan
              </h1>

              <h4
                style={{
                  textDecoration: "underline",
                  marginBottom: "0",
                  padding: "0",
                  color: "var(--dark-blue)",
                }}
                onClick={(e) => {
                  setDisplayDowngradePlan(false);
                  setDisplayBotPlan(true);
                }}
              >
                {" "}
                &lt; Billing
              </h4>
              <DowngradePlan
                handleNewOrgSite={handleNewOrgSite}
                changeCardInfo={changeCardInfo}
                handleChangePlan={handleChangePlan}
                currentBotPlan={info.user.BotPlan}
                newBotPlan={paymentPlan}
                handleNewInfo={handleNewInfo}
                info={info}
                setDisplayBotPlan={setDisplayBotPlan}
                setDisplayDowngradePlan={setDisplayDowngradePlan}
              />
            </>
          ) : null}
          {displayUpgradePlan ? (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                Upgrade Plan
              </h1>
              <h4
                style={{
                  textDecoration: "underline",
                  color: "var(--dark-blue)",
                  marginBottom: "0",
                  padding: "0",
                }}
                onClick={(e) => {
                  setDisplayUpgradePlan(false);
                  setDisplayBotPlan(true);
                }}
              >
                {" "}
                &lt; Billing
              </h4>
              <UpgradePlan
                changeCardInfo={changeCardInfo}
                handleChangePlan={handleChangePlan}
                BotPlanID={paymentPlan.BotPlanID}
                paymentAmount={
                  paymentPlan.BotPlanRule.find((rule: any) =>
                    rule.BotPlanRuleName.toLowerCase().includes(
                      "payment amount"
                    )
                  ).BotPlanRuleValue
                }
                info={info}
                setDisplayBotPlan={setDisplayBotPlan}
                setDisplayUpgradePlan={setDisplayUpgradePlan}
              />{" "}
            </>
          ) : null}

          {displayUI ? (
            <>
              <h1
                style={{
                  backgroundColor: "var(--dark-blue)",
                  border: "3px solid var(--light-blue)",
                  textShadow:
                    "-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  marginTop: "0px",
                }}
                className="orgadmin-heading"
              >
                User Interface
              </h1>
              <div
                style={{
                  display: "flex",
                  alignContent: "flex-start",
                  justifyContent: "flex-start",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                <h3 className="orgadmin-subheading">Current Site:</h3>
                <h1
                  style={{
                    color: "var(--light-blue)",
                    marginLeft: "5px",
                    marginTop: "23px",
                    fontWeight: "bold",
                  }}
                >
                  {orgSite.OrganizationSiteName}
                </h1>
                <h1
                  style={{
                    color: "var(--dark-blue)",
                    marginLeft: "5px",
                    fontWeight: "bold",
                    fontSize: "1.8rem",
                    marginTop: "9px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setDisplayWelcome(false);
                    setDisplayBotPlan(false);
                    setDisplayEmbeddings(false);
                    setDisplayCancelSubscription(false);
                    setDisplayLLM(false);
                    setDisplayAnalytics(false);
                    setDisplayUserRecords(false);
                    setDisplayKnowledgeBase(false);
                    setDisplayUI(false);
                    setDisplayUpgradePlan(false);
                    setDisplayDowngradePlan(false);
                    setDisplayPayment(false);
                    setDisplayPaymentSuccess(false);
                    setDisplayProfile(false);
                    setDisplaySettings(true);
                  }}
                >
                  ⇆
                </h1>
              </div>
              <div
                className="orgadmin-guidelines-container"
                style={{ marginTop: "0" }}
              >
                <h3
                  onClick={() => setGuideline4(!guideline4)}
                  className="orgadmin-subheading"
                  style={{ marginTop: "0" }}
                >
                  Guidelines
                </h3>
                {!guideline4 && (
                  <h3
                    onClick={() => setGuideline4(!guideline4)}
                    className="orgadmin-subheading"
                    style={{ marginTop: "0" }}
                  >
                    ▼
                  </h3>
                )}
                {guideline4 && (
                  <h3
                    onClick={() => setGuideline4(!guideline4)}
                    className="orgadmin-subheading"
                    style={{ marginTop: "0" }}
                  >
                    ▲
                  </h3>
                )}
              </div>
              {guideline4 && (
                <h4 className="orgadmin-paragraph">
                  The bot user interface is the visual representation of the
                  chatbot that users interact with. It is generally recommended
                  that color one and color two be in contrast with each other,
                  one lighter and one darker.{" "}
                </h4>
              )}

              <div>
                <h3 className="orgadmin-subheading">Properties</h3>
                <h5 className="properties-subheading">Handpicked Templates</h5>
                <div
                  style={{
                    display: "flex",
                    alignContent: "space-between",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    onClick={(e) =>
                      handleSetTemplate("#EC7655", "#42CDB3", "#EDCBD2")
                    }
                    className="properties-template-outer-container"
                  >
                    <div className="properties-template-container">
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#EC7655" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#42CDB3" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#EDCBD2" }}
                      ></div>
                    </div>
                    <p className="properties-template-names">Warm Tropics</p>
                  </div>

                  <div
                    onClick={(e) =>
                      handleSetTemplate("#EDF4F2", "#7C8363", "#31473A")
                    }
                    className="properties-template-outer-container"
                  >
                    <div className="properties-template-container">
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#EDF4F2" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#7C8363" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#31473A" }}
                      ></div>
                    </div>
                    <p className="properties-template-names">
                      Evergreen Whispers
                    </p>
                  </div>

                  <div
                    onClick={(e) =>
                      handleSetTemplate("#00246B", "#8AB6F9", "#CADCFC")
                    }
                    className="properties-template-outer-container"
                  >
                    <div className="properties-template-container">
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#CADCFC" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#8AB6F9" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#00246B" }}
                      ></div>
                    </div>
                    <p className="properties-template-names">Ocean’s Embrace</p>
                  </div>

                  <div
                    onClick={(e) =>
                      handleSetTemplate("#1803A5", "#96FFBD", "#F2EC9B")
                    }
                    className="properties-template-outer-container"
                  >
                    <div className="properties-template-container">
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#F2EC9B" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#96FFBD" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#1803A5" }}
                      ></div>
                    </div>
                    <p className="properties-template-names">Electric Splash</p>
                  </div>

                  <div
                    onClick={(e) =>
                      handleSetTemplate("#DEDDAB", "#7F1515", "#000000")
                    }
                    className="properties-template-outer-container"
                  >
                    <div className="properties-template-container">
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#DEDDAB" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#7F1515" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#000000" }}
                      ></div>
                    </div>
                    <p className="properties-template-names">Murder Mystery</p>
                  </div>

                  <div
                    onClick={(e) =>
                      handleSetTemplate("#ffffff", "#123769", "#9E9E9E")
                    }
                    className="properties-template-outer-container"
                  >
                    <div className="properties-template-container">
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#123769" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#ffffff" }}
                      ></div>
                      <div
                        className="properties-template-color"
                        style={{ backgroundColor: "#9E9E9E" }}
                      ></div>
                    </div>
                    <p className="properties-template-names">Arctic Steel</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <label style={{ marginRight: "10px" }}>Size:</label>
                  <select
                    onChange={handleTemplateSizeChange}
                    style={{ width: "55%" }}
                  >
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                  </select>
                </div>
                <div className="orgadmin-guidelines-container">
                  <h5
                    onClick={() =>
                      setShowAdvancedSettings(!showAdvancedSettings)
                    }
                    className="properties-subheading"
                  >
                    Advanced Settings
                  </h5>
                  {!showAdvancedSettings && (
                    <h3
                      onClick={() =>
                        setShowAdvancedSettings(!showAdvancedSettings)
                      }
                      className="orgadmin-subheading properties-subheading"
                    >
                      ▼
                    </h3>
                  )}
                  {showAdvancedSettings && (
                    <h3
                      onClick={() =>
                        setShowAdvancedSettings(!showAdvancedSettings)
                      }
                      className="orgadmin-subheading properties-subheading"
                    >
                      ▲
                    </h3>
                  )}
                </div>
                {showAdvancedSettings && (
                  <div className="orgadmin-properties-container">
                    <div className="orgadmin-properties-text-container">
                      {rerender
                        ? setRerender(false)
                        : firstHalf.map((detail: any, index: any) => {
                            console.log("value of detail: ", { detail });

                            return (
                              <div
                                className="orgadmin-properties-inputs-container"
                                style={{
                                  display: "flex",
                                  alignContent: "space-between",
                                  justifyContent: "space-between",
                                }}
                                key={index}
                              >
                                <label>{detail.SiteUITypeDetailName}:</label>
                                {displayUIInput(detail)}
                              </div>
                            );
                          })}
                    </div>
                    <div className="orgadmin-properties-text-container">
                      {rerender
                        ? setRerender(false)
                        : secondHalf.map((detail: any, index: any) => {
                            console.log("value of detail: ", { detail });
                            return (
                              <div
                                className="orgadmin-properties-inputs-container"
                                style={{
                                  display: "flex",
                                  alignContent: "space-between",
                                  justifyContent: "space-between",
                                }}
                                key={index}
                              >
                                <label>{detail.SiteUITypeDetailName}:</label>
                                {displayUIInput(detail)}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                )}

                <h3 className="orgadmin-subheading">Preview</h3>

                {chatbot}
              </div>

              <div
                style={{ marginBottom: "0px", marginTop: "50px" }}
                className="orgadmin-button-container"
              >
                <button
                  className="orgadmin-button-save"
                  onClick={handleUIChange}
                >
                  Save
                </button>
                <button
                  className="orgadmin-button-cancel"
                  onClick={(e) => {
                    orgSite.SiteUITypeDetail.map((detail: any) => {
                      setDetails((prevDetails) => ({
                        ...prevDetails,
                        [detail.SiteUITypeDetailName]:
                          detail.SiteUITypeDetailValue,
                      }));
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default OrgAdmin;
