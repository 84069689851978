import React, { useState } from "react";
import "../cssStyling/layout.css";

const HomeStepsCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const steps = [
    {
      icon: (
        <div className="home-icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="54"
            width="54"
            viewBox="0 0 512 512"
          >
            <path
              fill="#fafafa"
              d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z"
            />
          </svg>
        </div>
      ),
      title: "1. Join Our Platform",
      description:
        "Sign up and become a part of the SmartAssist AI community. Gain access to all the tools and resources you need to get started.",
    },
    {
      icon: (
        <div className="home-icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="54"
            width="54"
            viewBox="0 0 576 512"
          >
            <path
              fill="#ffffff"
              d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"
            />
          </svg>
        </div>
      ),
      title: "2. Customize UI",
      description:
        "Personalize your AI assistant's interface to match your brand's unique style. Choose from a variety of colors, fonts, and layouts to create a seamless user experience.",
    },
    {
      icon: (
        <div className="home-icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="54"
            width="54"
            viewBox="0 0 384 512"
          >
            <path
              fill="#ffffff"
              d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-102.1-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31L216 408z"
            />
          </svg>
        </div>
      ),
      title: "3. Upload Training Data",
      description:
        "Easily upload your company's data, content, and any relevant information. Our platform supports various formats and methods to ensure a smooth and quick setup.",
    },
    {
      icon: (
        <div className="home-icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="54"
            width="54"
            viewBox="0 0 640 512"
          >
            <path
              fill="#ffffff"
              d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"
            />
          </svg>
        </div>
      ),
      title: "4. Deploy Your Assistant",
      description:
        "Activate your AI assistant and start enjoying the benefits. Whether for customer support, internal assistance, or specialized training, SmartAssist AI is ready to enhance your operations.",
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % steps.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + steps.length) % steps.length
    );
  };

  return (
    <div className="home-step-carousel-container">
      <div className="homestep-carousel-buttons">
        <button onClick={handlePrev}>←</button>
      </div>
      <div className="carousel">
        <div className="home-step">
          <div className="step-icon">{steps[currentIndex].icon}</div>
          <h5 className="step-title">{steps[currentIndex].title}</h5>
          <p className="step-description">{steps[currentIndex].description}</p>
        </div>
      </div>
      <div className="homestep-carousel-buttons">
        <button onClick={handleNext}>→</button>
      </div>
    </div>
  );
};

export default HomeStepsCarousel;
