import React, { useState } from 'react';

interface CancelSubscriptionProps {
    setDisplayCancelSubscription: (value: boolean) => void;
    info: any;
    handleCancelSubscriptionBackend: any;
}

const CancelSubscription: React.FC<CancelSubscriptionProps> = ({ setDisplayCancelSubscription, info, handleCancelSubscriptionBackend }) => {
    const totalPages = 3; // Set the total number of pages
    const [currentPage, setCurrentPage] = useState(1);

    const handleCancelSubscription = async () => {
        // Logic to cancel the subscription
        await handleCancelSubscriptionBackend();
        setCurrentPage(currentPage + 1);
    }


    return (
        <>
            <h1 style={{
                backgroundColor: 'var(--dark-blue)',
                border: '3px solid var(--light-blue)',
                textShadow: '-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)',
                color: 'white',
                width: '100%',
                textAlign: 'center',
                padding: '10px',
                marginTop: '0px'
            }} className="orgadmin-heading">Cancel Subscription</h1>
            <h4 style={{ textDecoration: 'underline', color: 'var(--light-blue)' }} onClick={() => setDisplayCancelSubscription(false)}> &lt; Billing</h4>
            
            {currentPage === 1 ? <>
            <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Priority Sites</h3>
            <p style={{ color: 'var(--light-blue)' }}>Choose which site you would like to keep, the rest will be automatically disabled.</p>

            {info.user.OrganizationSite.map((site: any) => {
                return (
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', marginLeft: '30px' }} key={site.OrganizationSiteID}>
                        <input defaultChecked={site.PriorityOrder === 1}  style={{ width: '20px', marginRight: '10px' }} type="radio" id={site.OrganizationSiteID} name="site" value={site.OrganizationSiteID} />
                        <label htmlFor={site.OrganizationSiteID}>{site.OrganizationSiteName}</label>
                    </div>
                );
            })}
            </>
            : null}
            {currentPage === 2 ? <>
            <h3 style={{display: 'flex', placeContent: 'center', color: 'var(--dark-blue)', fontSize: '1.1rem', fontWeight: 'bold'}} >There's no going back! Are you sure you want to cancel your subscription?</h3>
            <div style={{display: 'flex', placeContent: 'center'}} >
            <button style={{ marginTop: '20px', backgroundColor: 'var(--dark-blue)' }} className="cancel-subscription-next-button" onClick={handleCancelSubscription}>Yes, cancel my subscription.</button>
            <button style={{ marginTop: '20px', marginLeft: '10px', color: 'var(--dark-blue)' }} className="cancel-subscription-back-button" onClick={() => setCurrentPage(currentPage - 1)}>Back</button>
            </div>
            <p style={{display: 'flex', placeContent: 'center', color: 'var(--light-blue)', textAlign: 'center', marginTop: '30px'}}>To make the most of your subscription, we recommend you cancel at the end of your billing cycle, BILLING DATE HERE, as you will lose access to your subscription immediately.</p>
            
            </>
            : null}

            {currentPage === 3 ? <>
            <h3 style={{display: 'flex', placeContent: 'center', color: 'var(--dark-blue)', fontSize: '1.1rem', fontWeight: 'bold'}} >Your subscription has been successfully cancelled.</h3>
            <div style={{display: 'flex', placeContent: 'center'}} >
            <button style={{ marginTop: '20px', backgroundColor: 'var(--dark-blue)', padding: '10px' }} className="cancel-subscription-next-button" onClick={() => setDisplayCancelSubscription(false)}>Close</button>
            </div>
            </>
: null}

<div style={{ display: currentPage === 2  || currentPage === 3? 'none' : 'flex', placeContent: 'center' }} >
  <button style={{ marginTop: '20px' }} className="cancel-subscription-next-button" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
  <button style={{ marginTop: '20px', marginLeft: '10px' }} className="cancel-subscription-back-button" onClick={() => {
    if (currentPage === 1) {
        setDisplayCancelSubscription(false);
    } else {
    setCurrentPage(currentPage - 1)}
  }}>Back</button>
</div>

            <Pagination  currentPage={currentPage} totalPages={totalPages} />
        </>
    );
}

interface PaginationProps {
    currentPage: number;
    totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '40px',
            marginRight: '40px'
        }}>
            {Array.from({ length: totalPages - 1 }).map((_, index) => (
                <div key={index} style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: currentPage === index + 1 ? 'var(--dark-blue)' : 'lightgrey',
                    margin: '0 5px'
                }}></div>
            ))}
            <i className={`fas fa-check-circle`} style={{
                fontSize: '20px',
                marginLeft: '10px',
                color: currentPage === totalPages ? 'var(--dark-blue)' : 'lightgrey'
            }}></i>
        </div>
    );
}

export default CancelSubscription;
