
import React, { useEffect, useState } from 'react';
import config from '../config';

interface SiteSettingsProps {
    handleNewInfo: any;
    info: any;
    site: any;
    index: number;
    orgSite: any;
    setRerender: any;
    setSelectedSite: any;
    setLoading: any;
    handleNewOrgSite: any;
  }
  
  const SiteSettings: React.FC<SiteSettingsProps> = ({ handleNewOrgSite, handleNewInfo, setLoading, info, setRerender, setSelectedSite, site, orgSite, index }) => {
    const [editContent, setEditContent] = useState(false);
    const [email, setEmail] = useState(site.OrganizationSiteDescription);
    const [jobTitle, setJobTitle] = useState(site.LLM_Temperature);
    const [lastName, setLastName] = useState(site.OrganizationSiteURL);
    const [topP, setTopP] = useState(site.LLM_Top_P);
    const [topK, setTopK] = useState(site.LLM_Top_K);
    const [firstName, setFirstName] = useState(site.OrganizationSiteName);
    const [addNewSite, setAddNewSite] = useState(false);
    const [siteName, setSiteName] = useState('');  
    const [temperature, setTemperature] = useState('0.1');
    const [topP2, setTopP2] = useState('0.3');
    const [siteDomain, setSiteDomain] = useState('');
    const [siteDescription, setSiteDescription] = useState('');

    const validateDomain = (domain: string) => {
        const corsDomainRegex = /^(https?:\/\/)([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        return corsDomainRegex.test(domain);
      };

    useEffect(() => {
        setFirstName(site.OrganizationSiteName);
        setLastName(site.OrganizationSiteURL);
        setEmail(site.OrganizationSiteDescription);
        setJobTitle(site.LLM_Temperature);
        setTopP(site.LLM_Top_P);
        setTopK(site.LLM_Top_K);
    }, [orgSite]);

    const handleAddSite = async () => { 
        
          

        if (!siteName || !siteDomain || !siteDescription) {
            alert('Please fill out all fields.');
            return;
        } else if (!validateDomain(siteDomain)) {
            alert('Please enter a valid domain.');
            return;
        }
        
        try {
            setAddNewSite(false);
            setLoading(true);
            const res = await fetch(`${config.apiUrl}/addSite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    OrganizationID: info.user.OrganizationID,
                    OrganizationSiteName: siteName,
                    OrganizationSiteURL: siteDomain,
                    OrganizationSiteDescription: siteDescription,
                    LLM_Temperature: Number(temperature),
                    LLM_Top_P: Number(topP2),
                    LLM_Top_K: 3,
                }),
            });
            const data = await res.json();
            info = data.info;
            orgSite = info.user.OrganizationSite[info.user.OrganizationSite.length - 1];
            sessionStorage.setItem('info', JSON.stringify(info));
            sessionStorage.setItem('orgSite', JSON.stringify(orgSite));
            setFirstName(siteName);
            setLastName(siteDomain);
            setEmail(siteDescription);
            setJobTitle(0.1);
            setTopP(0.1);
            setTopK(1);
            setRerender(true);
            setLoading(false);
            handleNewInfo(info);
            
        } catch (error) {
            alert('Failed to add site. Please try again later.');
            console.error('Failed to add site', error);
        }
     };
    

   const handleDeleteSite = async () => {
        try {
            await fetch(`${config.apiUrl}/deleteSite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    OrganizationSiteID: site.OrganizationSiteID,
                }),
            });
            info.user.OrganizationSite.splice(index, 1);
            orgSite = info.user.OrganizationSite[0];
            setSelectedSite(info.user.OrganizationSite[0]);
            index = 0;
            setEditContent(false);
            setEmail(orgSite.OrganizationSiteDescription);
            setJobTitle(orgSite.LLM_Temperature);
            setLastName(orgSite.OrganizationSiteURL);
            setTopP(orgSite.LLM_Top_P);
            setTopK(orgSite.LLM_Top_K);
            setFirstName(orgSite.OrganizationSiteName);

            
            console.log("new info: ", { info });
            console.log("index: ", index);
            sessionStorage.setItem('orgSite', JSON.stringify(info.user.OrganizationSite[0]));
            setRerender(true);
            await handleNewInfo(info);
        

        } catch (error) {
            alert('Failed to delete site. Please try again later.');
            console.error('Failed to delete site', error);
            console.log(handleNewInfo);
        }
   };
    const handleSaveSiteChanges = async () => {
setLoading(true);
if (!firstName || !lastName || !email) {
    alert('Please fill out all fields.');
    setLoading(false);
    return;
}

if (topK < 1 || topK > 5) {
    alert('Top K must be between 1 and 5.');
    setLoading(false);
    return;
}

if (!validateDomain(lastName)) {
    alert('Please enter a valid domain.');
    setLoading(false);
    return;
}
        try {
        await fetch(`${config.apiUrl}/updateSite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                OrganizationSiteID: site.OrganizationSiteID,
                OrganizationSiteName: firstName,
                OrganizationSiteURL: lastName,
                OrganizationSiteDescription: email,
                LLM_Temperature: jobTitle,
                LLM_Top_P: topP,
                LLM_Top_K: topK
            }),
        });
        setEditContent(false);
        info.user.OrganizationSite[index] = { ...site, OrganizationSiteName: firstName, OrganizationSiteURL: lastName, OrganizationSiteDescription: email, LLM_Temperature: jobTitle, LLM_Top_P: topP, LLM_Top_K: topK };
        sessionStorage.setItem('info', JSON.stringify(info));
        handleNewInfo(info);
        handleNewOrgSite(info.user.OrganizationSite[index]);
        setLoading(false);

    } catch (error) {
        alert('Failed to update site. Please try again later.');
        console.error('Failed to update site', error);
        setLoading(false);
    }
    };

    const handleDelete = () => {
        if (info.user.OrganizationSite.length === 1) {
            alert('You cannot delete the last site.');
            return;
        }
        const isConfirmed = window.confirm("Are you sure you want to delete this site? This change cannot be undone.");
        if (isConfirmed) {
            handleDeleteSite(); // Assuming handleDeleteSite is defined elsewhere
        }
    };

    console.log("SiteSettingsProps: ");
    console.log(index);
    console.log({site});
    console.log({info});
    
    return (site.OrganizationSiteName === orgSite.OrganizationSiteName ?  (
        <>
        

 {addNewSite &&
  <div className="modal">
  <div className="modal-content cancel-subscription-modal-content logout-modal">
    <h1 style={{textDecoration: 'underline', fontSize: '1.3rem'}}>Add New Site</h1>
    <div>
                         <div>
                            <label>Site Name</label>
                            <input value={siteName} maxLength={99} onChange={(e) => setSiteName(e.target.value)} type="text" />
                        </div>
                        <div>
                            <label>Site Domain URL (ex. https://example.com)</label>
                            <input value={siteDomain} maxLength={249} onChange={(e) => setSiteDomain(e.target.value)} type="text" />
                        </div>
                        <div>
                            <label>Site Description</label>
                            <input maxLength={249} value={siteDescription} onChange={(e) => setSiteDescription(e.target.value)} type="text" />
                        </div>

                        <div style={{display: 'flex', alignContent: 'space-between', justifyContent: 'space-between'}}>
                    <div style={{marginLeft: '20px', width: '45%'}}>
                    <label style={{marginLeft: '20px'}}>
                        <span  className="tooltip">?
                            <span style={{backgroundColor: 'var(--light-blue)', color: 'var(--pure-white)', fontSize: '0.8rem'}} className="tooltip-text">Regulates the creativity of the response. The closer this is to 0 the more focused the response will be and the bot will more closely stick to its training data. Conversely, values closer to 1 allows for more diverse responses.</span>
                        </span> Temperature
                    </label>
                            <input type="range" min="0.01" max="1" step="0.01" onChange={(e) => setTemperature(e.target.value)} value={temperature} />
                        </div>
                        <div style={{marginRight: '20px', width: '45%'}}>
                            <label style={{marginLeft: '20px'}} >
                            <span className="tooltip">?
                            <span style={{backgroundColor: 'var(--light-blue)', color: 'var(--pure-white)', fontSize: '0.8rem'}} className="tooltip-text">Controls the randomness of the language used. The lower this is the more the model will use the most probable words from its trained data set which increases the repetition between answers. The higher the value the more the model will consider a wide variety of words for use.</span>
                        </span> Top P
                            </label>
                            <input type="range" min="0.01" max="1" step="0.01" onChange={(e) => setTopP2(e.target.value)} value={topP2} />
                            </div> 
                       
                    </div>

                    </div>
                    

    <div>
    <button style={{minWidth: '200px', padding: '7px', fontSize: '1.1rem'}} onClick={handleAddSite} className="btn btn-danger" >Create Site</button>
    <button onClick={(e) => setAddNewSite(false)} style={{backgroundColor: 'var(--pure-white)', color: 'var(--light-blue)', border: 'none', padding: '2px', fontSize: '1.1rem',margin:' 0px', minWidth: '0px'}} className="btn btn-primary" >Cancel</button>
    </div>
  </div>
</div>
}

            <div className="orgadmin-personal-info">
                {topP && <h3 onClick={() => {setEditContent(true);}} className="orgadmin-subheading">Site #{index + 1}</h3>}

                <svg  xmlns="http://www.w3.org/2000/svg" onClick={() => setEditContent(true)} viewBox="0 0 576 512">
                    <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1 .8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                </svg>
                <svg style={{marginLeft: '0px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={handleDelete}>
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                </svg>
            </div>

            <div className="orgadmin-organization-info-1">
                <div>
                    <h5 style={{color: 'var(--dark-blue)', width: '90px'}}>Name: </h5>
                    {!editContent && <h5>{firstName}</h5>}
                    {editContent && (
                        <input maxLength={99} onChange={(e) => setFirstName(e.target.value)} placeholder="Enter site name..." value={firstName} />
                    )}
                </div>
                <div>
                    <h5 style={{color: 'var(--dark-blue)', width: '90px'}}>Domain: </h5>
                    {!editContent && <h5>{lastName}</h5>}
                    {editContent && (
                        <input maxLength={249} onChange={(e) => setLastName(e.target.value)} placeholder="Enter URL..." value={lastName} />
                    )}
                </div>
            </div>

            <div className="orgadmin-organization-info-2">
                <div>
                    <h5 style={{color: 'var(--dark-blue)', width: '90px'}}>Description: </h5>
                    {!editContent && <h5>{email}</h5>}
                    {editContent && (
                        <input maxLength={249} name="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter description..." value={email} />
                    )}
                </div> 
                <div>
                    <h5 style={{color: 'var(--dark-blue)', width: '90px'}}>
                        <span className="tooltip">?
                            <span className="tooltip-text">Determines how many of the most relevant documents are considered when the bot creates a response. Lower values focus on the most relevant documents, while higher values allow the bot to include a broader range of information.</span>
                        </span> Top K:
                    </h5>
                    {!editContent && <h5>{topK}</h5>}
                    {editContent && (
                        <input type="number" min="1" max="5" step="1" placeholder="Enter top k value..." onChange={(e) => setTopK(e.target.value)} value={topK} />
                    )}
                </div>
            </div>

            <div className="orgadmin-organization-info-1">
                <div>
                    <h5 style={{color: 'var(--dark-blue)', width: '90px'}}>
                        <span className="tooltip">?
                            <span className="tooltip-text">Controls the randomness of the language used. The lower this is the more the model will use the most probable words from its trained data set which increases the repetition between answers. The higher the value the more the model will consider a wide variety of words for use.</span>
                        </span> Top P:
                    </h5>
                    {!editContent && <h5>{topP}</h5>}
                    {editContent && (
                        <input type="range" min="0.01" max="1" step="0.01" onChange={(e) => setTopP(e.target.value)} value={topP} />
                    )}
                </div>
                <div>
                    <h5 style={{color: 'var(--dark-blue)', width: '90px'}}>
                        <span className="tooltip">?
                            <span className="tooltip-text">Regulates the creativity of the response. The closer this is to 0 the more focused the response will be and the bot will more closely stick to its training data. Conversely, values closer to 1 allows for more diverse responses.</span>
                        </span> Temperature:
                    </h5>
                    {!editContent && <h5>{jobTitle}</h5>}
                    {editContent && (
                        <input type="range" min="0.01" max="1" step="0.01" onChange={(e) => setJobTitle(e.target.value)} value={jobTitle} />
                    )}
                </div>                                                                 
            </div>

            <div className="orgadmin-organization-info-1">
                <div style={{width: 'calc(50% - 40px)'}}>
                    <h5 style={{color: 'var(--dark-blue)', width: '90px'}}>
                        <span className="tooltip">?
                            <span className="tooltip-text"> This is a parameter passed into the chatbot so it works specifically for your website, using your website's information and settings. Without this key, the chatbot won't function when embedded in your site.</span>
                        </span> Client Secret Key:
                    </h5>
                    <h5>{orgSite.SiteKnowledgeBase.length >= 1 ?  site.OrganizationSiteClientSecretID : 'Please upload documents to access the key.'}</h5>
                </div>
            </div>

            {editContent && (
                <div style={{marginTop: '20px'}} className="orgadmin-button-container">
                    <button className="orgadmin-button-save" onClick={handleSaveSiteChanges}>Save</button>
                    <button onClick={() => {setEditContent(false);
                        setFirstName(site.OrganizationSiteName);
                        setLastName(site.OrganizationSiteURL);
                        setEmail(site.OrganizationSiteDescription);
                        setJobTitle(0.1);
                    }} className="orgadmin-button-cancel">Cancel</button>
                </div>
            )}
            <div style={{marginTop: '40px'}} className="orgadmin-button-container">
                    <button disabled={info.user.BotPlan.BotPlanID === 1 || Number(info.user.BotPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase().includes('sites')).BotPlanRuleValue) <= info.user.OrganizationSite.length} style={{minWidth: '500px'}}className="orgadmin-button-save" onClick={(e) => setAddNewSite(true)}>Add New Site</button>
                </div>
                <p style={{textAlign: 'center', color: 'var(--dark-blue)'}} hidden={Number(info.user.BotPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase().includes('sites')).BotPlanRuleValue) > info.user.OrganizationSite.length}>To add more sites, upgrade your plan.</p>
        </>
    ) : <></>);
};

export default SiteSettings;
