import React from "react";
import "./App.css";
import Login from "./admin/Login";
import OrgAdmin from "./admin/OrgAdmin";
import ForgotPassword from "./admin/ForgotPassword";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignUp from "./admin/SignUp";
import SignUpContinue from "./admin/SignUpContinue";
import ValidateEmail from "./admin/ValidateEmail";
import Home from "./landingPages/Home";
import Features from "./landingPages/Features";
import Contact from "./landingPages/Contact";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/orgadmin" element={<OrgAdmin />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/features" element={<Features />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signupcont" element={<SignUpContinue />} />
        <Route path="/validateemail" element={<ValidateEmail />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
      </Routes>
    </Router>
  );
}

export default App;
