import UpdatePaymentMethod from '../hooks/ChangeCard';
import { useState, useEffect } from 'react';
import config from '../config';

interface UpgradePlanProps {
    setDisplayBotPlan: any;
    setDisplayUpgradePlan: any;
    paymentAmount: number;
    info: any;
    handleChangePlan: any;
    BotPlanID: number;
    changeCardInfo: any;
}

const UpgradePlan: React.FC<UpgradePlanProps> = ({changeCardInfo, handleChangePlan, BotPlanID, info, setDisplayBotPlan, setDisplayUpgradePlan, paymentAmount}) => {
    const [totalPages, setTotalPages] = useState(2);
    const [currentPage, setCurrentPage] = useState(1);
    const [displayConfirmCard, setDisplayConfirmCard] = useState(false);    
    const [displayPaymentChange, setDisplayPaymentChange] = useState(false);

    const handleUpgradePlan = async () => {
        console.log(paymentAmount);
        const res = await fetch (`${config.apiUrl}/upgrade-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ OrganizationID: info.user.OrganizationID, paymentAmount }),
        });
        const data = await res.json();
        console.log({data});
        await handleChangePlan(BotPlanID);
        setCurrentPage(currentPage + 1);
    };
    
    return (<>
    {currentPage === 1 && !displayPaymentChange && !displayConfirmCard && <>

        <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Card</h3>
                            <div className="orgadmin-card-container" style={{marginTop: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <p className="orgadmin-card-information">{info.payment.PaymentMethod.toUpperCase()} <span className="orgadmin-card-information-inner-text">ending in {info.payment.CustomerCardPartialInfo}</span></p>
                <p className="orgadmin-card-information-inner-text">Expires {info.payment.CustomerCardExpirationDate}</p>
              </div>
              <u onClick={(e) => setDisplayPaymentChange(true)} className="orgadmin-card-information-inner-text" style={{cursor: 'pointer'}}><span className="orgadmin-card-information" style={{margin: '0', padding: '0', fontSize: '1.28rem'}}>✎</span> Process payments with a different card</u>
            
            </div>
            <div style={{display: 'flex', placeContent: 'center'}}>
            <div style={{alignSelf: 'center', minWidth: '100%', display: 'flex', placeContent: 'center'}}>
                                 <button style={{ marginTop: '20px', padding: '7px', minWidth: '30%' }} className="cancel-subscription-next-button" onClick={handleUpgradePlan}>Confirm Plan Change</button>

                                 <button style={{ marginTop: '20px', marginLeft: '10px' }} className="cancel-subscription-back-button" onClick={() => {setDisplayBotPlan(true);
                                    setDisplayUpgradePlan(false)
                                 }}>Back</button>

                </div>

            </div>
        
        </>}

        {displayPaymentChange ? (
                <>
<h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Card</h3>
<p style={{color: 'var(--light-blue)'}}>Rest assured, your payment information will not be stored. We use Stripe, a highly secure third-party service, to process your payments safely.</p>
{/* PAYMENT ELEMENT HERE */}
<UpdatePaymentMethod changeCardInfo={changeCardInfo} customerId={info.payment.PaymentProcessorCustomerID} setDisplayConfirmCard={setDisplayConfirmCard} setDisplayPaymentChange={setDisplayPaymentChange}/>
</>

            ) : null}

{displayConfirmCard && currentPage === 1 ? (
                <>
                <h3 style={{ marginBottom: '0px' }} className="orgadmin-subheading">Confirm Card</h3>
                <h3 style={{color: 'var(--light-blue)'}}>Your Payment Method was Changed Sucessfully 🎉</h3>
                <button  style={{width: '40%', alignSelf: 'center', padding: '10px'}} className="cancel-subscription-next-button" onClick={(e) => setDisplayConfirmCard(false)}>Back</button>
                </>
            ) : null}

{currentPage === 2 ? (
                <>
                <div style={{display: 'flex', placeContent: 'center', flexDirection: 'column'}}>
                <h3 style={{ marginBottom: '0px', alignSelf: 'center' }} className="orgadmin-subheading">Your plan has been upgraded successfully 🎉</h3>
                <h3 style={{alignSelf: 'center', color: 'var(--light-blue)'}}>You can now enjoy all of your new benefits.</h3>
                <button style={{width: '40%', alignSelf: 'center', padding: '10px'}} className="cancel-subscription-next-button" onClick={(e) => {setDisplayUpgradePlan(false)
                    setDisplayBotPlan(true)
                }}>Close</button>
                </div>
                </>
            ) : null}

          
    <Pagination currentPage={currentPage} totalPages={totalPages} />
    </>);
}

interface PaginationProps {
    currentPage: number;
    totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '40px',
            marginRight: '40px'
        }}>
            {Array.from({ length: totalPages - 1 }).map((_, index) => (
                <div key={index} style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: currentPage === index + 1 ? 'var(--dark-blue)' : 'lightgrey',
                    margin: '0 5px'
                }}></div>
            ))}
            <i className={`fas fa-check-circle`} style={{
                fontSize: '20px',
                marginLeft: '10px',
                color: currentPage === totalPages ? 'var(--dark-blue)' : 'lightgrey'
            }}></i>
        </div>
    );
    
};

export default UpgradePlan;