import React, { useEffect, useState } from 'react';
import '../cssStyling/layout.css'; // Assuming you will be using an external CSS file for styling
import config from '../config';
import loading  from '../logos/loading.gif';

type UserRecord = {
    name: string;
    tokenUsage: string;
    email: string;
    ipAddress: string;
    date: string;
    feedback: string;
    ChatSessionID: number;
    response: string;
    ChatSessionDetails: {
        UserQuestion: string;
        Response: string;
        NumberofPromptTokens: number;
        NumberofResponseTokens: number;
        CreateDate: string;
    }[];
    
};

interface User {
    chatSession: {
      ChatSessionID: number;
      OrganizationSiteID: number;
      ChatSessionUserName: string;
      ChatSessionUserEmail: string;
      ChatSessionIPaddress: string;
      CreateDate: Date;
    },
    chatSessionDetails?: { //did not include knowledge set
      ChatSessionDetailID: number;
      UserQuestion: string;
      Response: string;
      NumberofPromptTokens: number;
      NumberofResponseTokens: number;
      CreateDate: Date;
    }[],
    chatSessionFeedback?: {
      ChatSessionFeedbackID: number;
      FeedbackType: string;
      Response: string | null;
      CreateDate: Date;
    }[],

  }



interface UseRecordProps {
    showChatHistory: boolean;
    setShowChatHistory: (showChatHistory: boolean) => void;
    OrganizationSiteID: number;
    setChatHistory: any;
    setChatHistoryName: any;
    setChatHistoryEmail: any;
    setChatHistoryDate: any;
    setChatHistoryLocation: any;
    setLoading: any;
    setDisplaySettings: any;
    setDisplayUserRecords: any;
    orgSite: any;
}

const UserRecords: React.FC<UseRecordProps > = ({orgSite, setDisplaySettings, setDisplayUserRecords, setLoading, showChatHistory, setShowChatHistory, OrganizationSiteID, setChatHistory, setChatHistoryName, setChatHistoryEmail, setChatHistoryDate, setChatHistoryLocation}) => {
    const [data, setData] = useState<UserRecord[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fetched, setFetched] = useState(false);
    const [emptyArray, setEmptyArray] = useState(false);
    const [itemsPerPage] = useState(20);
    const [sortConfig, setSortConfig] = useState<{ key: keyof UserRecord; direction: string } | null>(null);

    useEffect(() => {
        setFetched(false);
        console.log("AT USER RECORDS DATA FETCHING");
        async function fetchData() {
            const response = await fetch (`${config.apiUrl}/getChatHistory`, {
                method: 'POST',
                headers: {  
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({OrganizationSiteID}),
            });
            const data = await response.json();
            console.log("AT USER RECORDS DATA FETCHED");
            console.log({data});
            const users = data.users.map((user: User) => {
                const chatSessionDetails = user.chatSessionDetails;
                const chatSessionFeedback = user.chatSessionFeedback;
                const userRecord: UserRecord = {
                    ChatSessionID: user.chatSession.ChatSessionID,
                    name: user.chatSession.ChatSessionUserName,
                    tokenUsage: chatSessionDetails ? String(chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0)) : '',
                    email: user.chatSession.ChatSessionUserEmail,
                    ipAddress: user.chatSession.ChatSessionIPaddress,
                    date: new Date(user.chatSession.CreateDate).toISOString().split('T')[0],
                    feedback: chatSessionFeedback && chatSessionFeedback[0] && chatSessionFeedback[0].FeedbackType ? chatSessionFeedback[0].FeedbackType : '',
                    response: chatSessionFeedback && chatSessionFeedback[0] && chatSessionFeedback[0].Response ? chatSessionFeedback[0].Response : '',
                    ChatSessionDetails: chatSessionDetails ? chatSessionDetails.map((detail) => ({
                        UserQuestion: detail.UserQuestion,
                        Response: detail.Response,
                        NumberofPromptTokens: detail.NumberofPromptTokens,
                        NumberofResponseTokens: detail.NumberofResponseTokens,
                        CreateDate: new Date(detail.CreateDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }),
                    })) : [],
                };
                return userRecord;
            });
            console.log("AT USER RECORDS DATA PROCESSED AND FETCHED");
            console.log({users});
            setData(users);
            if (users.length === 0) {
                setEmptyArray(true);
            }
            setFetched(true);
        }
        fetchData();
    }, []);

    const handleShowChatHistory = async (item: any) => {
       
        const res = await fetch(`${config.apiUrl}/getLocationFromIP`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({IP: item.ipAddress}),
        });
        const data = await res.json();
        setChatHistoryLocation(data.message);
        setChatHistory(item.ChatSessionDetails);
        setChatHistoryName(item.name);
        setChatHistoryEmail(item.email);
        setChatHistoryDate(item.date);
        
        setShowChatHistory(true);

    }

    const handleDelete = async (item: any, index: number) => {
        setLoading(true);
        const res = await fetch(`${config.apiUrl}/deleteChatSession`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({OrganizationSiteID, ChatSessionID: item.ChatSessionID}),
        });
        data.splice(index, 1);
        setData([...data]);
        setLoading(false);
    }
    

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(currentPage - 2, 1);
        const endPage = Math.min(currentPage + 2, totalPages);

        if (startPage > 1) {
            pageNumbers.push(
                <span key={1} className={`page-number ${currentPage === 1 ? 'active' : ''}`} onClick={() => paginate(1)}>1</span>
            );
            if (startPage > 2) {
                pageNumbers.push(<span key="dots1">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span key={i} className={`page-number ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>{i}</span>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push(<span key="dots2">...</span>);
            }
            pageNumbers.push(
                <span key={totalPages} className={`page-number ${currentPage === totalPages ? 'active' : ''}`} onClick={() => paginate(totalPages)}>{totalPages}</span>
            );
        }

        return pageNumbers;
    };

    const sortData = (key: keyof UserRecord) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (key === 'tokenUsage') {
                const aTokens = parseInt(a[key], 10);
                const bTokens = parseInt(b[key], 10);
                if (aTokens < bTokens) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (aTokens > bTokens) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            }

            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(data.length / itemsPerPage);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        <>
        
        <div className="user-records-container">
            <h1 style={{ backgroundColor: 'var(--dark-blue)', border: '3px solid var(--light-blue)', textShadow: '-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)', color: 'white',  textAlign: 'center', padding: '10px', marginTop: '0px', marginBottom: '20px' }} className="orgadmin-heading">User Records</h1>
            
            {data.length === 0 && !fetched ? 
            <div className='loading-analytics-container'>
            <img src={loading} alt="loading" className="loading-analytics" />
            <p className='loading-analytics-text'>Loading one moment...</p> 
            </div>
            : 
            <>
            {emptyArray ? <p style={{textAlign: 'center'}}  className='loading-analytics-text'>No records available.</p> :
            <>
             <div style={{display: 'flex', alignContent: 'flex-start', justifyContent: 'flex-start', marginBottom: '0', paddingBottom: '0'}}>
              <h3 className="orgadmin-subheading">Current Site:</h3>
              <h1 style={{color: 'var(--light-blue)', marginLeft: '5px', marginTop: '23px', fontWeight: 'bold'}}>{orgSite.OrganizationSiteName}</h1>
                <h1 style={{color: 'var(--dark-blue)', marginLeft: '5px', fontWeight: 'bold', fontSize: '1.8rem', marginTop: '11px', cursor: 'pointer'}} onClick={(e) => {
                  setDisplayUserRecords(false)
                  setDisplaySettings(true)
                }}>⇆</h1>
              </div>
            <h3 className="orgadmin-subheading" style={{marginTop: '0', paddingTop:'0'}}>Chat Session Records</h3>
            <ul className='analytics-plan-limitations-ul'>
                <li><b>Chat Session Count: </b>{data.length}</li></ul>
            <div className='user-records-table-container'>
            <table className="user-records-table">
                <thead>
                    <tr>
                        <th style={{minWidth: '115px'}} onClick={() => sortData('name')}>Name</th>
                        <th onClick={() => sortData('tokenUsage')}>Tokens</th>
                        <th onClick={() => sortData('email')}>Email</th>
                        <th style={{minWidth: '75px'}}>IP Address</th>
                        <th style={{minWidth: '75px'}} onClick={() => sortData('date')}>Date</th>
                        <th onClick={() => sortData('feedback')}>Feedback</th>
                        <th onClick={() => sortData('response')}>Response</th>
                        <th style={{minWidth: '55px'}} >{' '}</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => (
                        <tr key={index} className="user-record-row">
                            <td> <div className='user-records-entry-row'>{item.name === '' || !item.name ? 'Anonymous' : item.name}</div></td>
                            <td><div className='user-records-entry-row'>{item.tokenUsage}</div></td>
                            <td ><div className='user-records-entry-row'>{item.email === '' || !item.email ? 'Anonymous' : item.email}</div></td>
                            <td ><div className='user-records-entry-row'> {item.ipAddress} </div></td>
                            <td > <div className='user-records-entry-row'> {item.date} </div></td>
                            <td><div  className='user-records-entry-row'>{item.feedback === '' || !item.feedback ? 'N/A' : item.feedback}</div></td>
                            <td ><div  className='user-records-entry-row user-records-entry-row-overflow' >{item.response === '' || !item.response ? 'N/A' : item.response}</div></td>
                            <td className='user-records-entry-actions'>
                                <svg
                                onClick={(e) => handleShowChatHistory(item)}
                                style={{marginRight: '10px'}} 
                                className="action-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1 .9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9 .7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"/></svg>
                                <svg 
                                onClick={(e) => handleDelete(item, index)}
                                style={{height: '20px', marginRight: '0px'}}
                                className="action-button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <div className="pagination">
                <div className='page-of'>Page {currentPage} of {totalPages}</div>
                <div className="page-numbers">
                    {renderPageNumbers()}
                </div>
            </div>
            </>}
            </>
}
        </div>
        
        </>);
}

export default UserRecords;
