import { useState } from "react";
import logo from "../logos/SACompleteLogoo.png";
import "../cssStyling/layout.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { ReactNode } from "react";
import config from "../config";

function Login() {
    const navigate = useNavigate(); // Initialize useNavigate
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    
    
    const [error, setError] = useState<ReactNode>(null);
    const [password, setPassword] = useState('');
    const submitForm = async (e: any) => {
        setLoading(true);
        e.preventDefault();
        console.log('Form submitting');
        const response = await fetch(`${config.apiUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });
        console.log({ response });
        if (response.ok) {
            console.log('User logged in');
            const user = await response.json(); // Wait for the promise to resolve
            setError(null);
            // navigate here with the resolved user data
            
            let info = await fetch(`${config.apiUrl}/getBotInfo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    OrganizationID: user.OrganizationID,
                }),
            });
            info = await info.json();
            navigate('/orgadmin', { state: { user, info } });
        } else {
            setError(<p style={{ color: 'red', marginTop: '0px', paddingTop: '0px', fontSize: '13px', paddingBottom: '0px', marginBottom: '-10px' }}>Invalid name or email.</p>);
            console.log('User not logged in');
        }
        console.log('Form submitted');
        setLoading(false);
    }
    
    return (
<>
<div style={{cursor: loading? 'wait' : 'default'}} className="login-container">
    <div className="logo" ><img style={{width: '350px'}} src={logo} alt="CyberChat" /></div>

<div style={{borderTopLeftRadius: "20px", borderBottomLeftRadius: '20px'}} className="text-container">
    <a href="/" style={{color: 'white', fontSize: '13px', marginLeft: '30px', marginTop: '0px', alignSelf:"flex-start", marginBottom: '0px', cursor: 'pointer', textDecoration: 'underline'}}>← Home</a>
<h1 style={{color: 'white', fontSize: '40px', marginBottom: '15px', marginTop: '5px'}}>Welcome</h1>
<form className="login-form">
<label>Email</label>
<input value={email} maxLength={99} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
<label>Password</label>
<input maxLength={49} value={password} onChange={(e) => setPassword(e.target.value)} style={{marginBottom: '5px'}} type="password" placeholder="Password" />
<a href="/forgotPassword" style={{color: 'white', fontSize: '11px', marginTop: '0px', alignSelf:"flex-end", marginBottom: '0px', cursor: 'pointer', textDecoration: 'underline'}}>Forgot password?</a>
{error}
<button onClick={(e) => submitForm(e)} className="login-button">Login</button>
<p>Don't have an account? <a href="/signup">Sign up</a></p>
</form>
</div>
</div>


<h1 className="only-show-on-small-devices">For the best experience, please use a larger device to initiate the login process, as this screen is optimized for larger displays.</h1>



</>);
}

export default Login;