import React, { useState } from 'react';
import '../cssStyling/layout.css';

interface BotPlan {
  BotPlanID: number;
  BotPlanName: string;
  BotPlanDescription: string;
  Embedder: {
    EmbedderName: string;
  };
  Language: {
    LanguageName: string;
  };
  Model: {
    ModelName: string;
  };
  BotPlanRule: {
    BotPlanRuleID: number;
    BotPlanRuleName: string;
    BotPlanRuleValue: string;
  }[];
}

interface CarouselProps {
  botPlans: BotPlan[];
  selectedBotPlanID: number;
  handleProcessChangePlan: any;
}

const BotPlan: React.FC<CarouselProps> = ({ botPlans, selectedBotPlanID, handleProcessChangePlan }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 2, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 2, botPlans.length - 2));
  };

  return (
    <div style={{background: 'none', width: '100%', margin: '0', maxWidth: '100%', padding: '0'}} className='home-botplans-mega-container'>
    
    
       
    
    <div className="carousel-container">
      <button style={{marginLeft: '20px', marginRight: '20px'}} onClick={handlePrevClick} disabled={currentIndex === 0}>
      ◄
      </button>
      <div className="carousel-content">
        {botPlans.slice(currentIndex, currentIndex + 2).map((plan) => { 
          
          let title = plan.BotPlanName;
          if (plan.BotPlanID === selectedBotPlanID) {
            title = `👉🏻 ${title}`;
          }
          return (
           
          <div onClick={(e) => handleProcessChangePlan(plan)} key={plan.BotPlanID} style={{maxWidth: '30%', minWidth: '30%'}} className={selectedBotPlanID === plan.BotPlanID ?  "bot-plan-card orgadmin-plan bot-plan-card-selected-plan my-botplan-card" : "bot-plan-card orgadmin-plan my-botplan-card"}>
            <h3 style={{ alignSelf: 'left', fontWeight: 'bolder', fontSize: '16px', margin: '5px', marginBottom: '0px' }} >{title}</h3>
            {plan.BotPlanRule.find(rule => rule.BotPlanRuleName.toLowerCase() === 'payment required' && rule.BotPlanRuleValue === 'Yes') && (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <h1 style={{ margin: 0, fontSize: '2.3rem' }}>
                  ${plan.BotPlanRule.find(rule => rule.BotPlanRuleName.toLowerCase() === 'payment amount')?.BotPlanRuleValue} 
                </h1>
                <p style={{ margin: 0, paddingLeft: '5px', fontSize: '0.7rem' }}>per month</p>
                </div>
            )}
                <p>{plan.BotPlanDescription}</p>
            <p className="home-botplan-rules"><b>✓</b> {plan.Embedder.EmbedderName} embedder</p>
            <p className="home-botplan-rules"><b>✓</b> {plan.Model.ModelName} LLM model</p>
            <p className="home-botplan-rules"><b>✓</b> supports {plan.Language.LanguageName}</p>
            {plan.BotPlanRule.map((rule) => {
              if (rule.BotPlanRuleName.toLowerCase() === 'payment required' && rule.BotPlanRuleValue === 'Yes') {
                return null;
              } else if (rule.BotPlanRuleName.toLowerCase() === 'payment amount' && rule.BotPlanRuleValue !== '0') {
                return null;
              }
              return (
                <p className="home-botplan-rules" key={rule.BotPlanRuleID}><b>✓</b> {rule.BotPlanRuleValue} {rule.BotPlanRuleName}</p>
              );
            })}
          </div>
        )})}
      </div>
      <button style={{marginLeft: '20px', marginRight: '20px'}} onClick={handleNextClick} disabled={currentIndex >= botPlans.length - 2}>
      ►
      </button>
    </div>
    </div>
  );
};

export default BotPlan;
