import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import "../cssStyling/layout.css"; // Import a CSS file for styling
import CA from "../logos/AIB-rmg.png"; // Import an image file
import personAndRobot from "../logos/person3.png";
import SmartAssistName from "../logos/SmartAssistAINameR.png";
import SmartAssist from "../logos/SALogo.png";
import { useEffect, useState } from "react";
import config from "../config";
import BotPlanCarousel from "./BotPlanCarousel";
import BotPlanCarouselResponsive from "./BotPlanCarouselResponsive";
import HomeStepsCarousel from "./HomeStepsCarousel";

const Home: React.FC = () => {
  const [botPlans, setBotPlans] = useState<any>(null);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const fetchBotPlans = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/getBotPlans`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log("Bot plans:", { data });
        setBotPlans(data);
      } catch (error) {
        console.error("Error fetching bot plans:", error);
      }
    };

    fetchBotPlans();
  }, []);
  return (
    <div className="home-container">
      <header className="home-header">
        <div className="home-logo">
          <img src={SmartAssist} alt="SmartAssist Logo" />
          <img src={SmartAssistName} alt="SmartAssist AI" />
        </div>

        <nav className="home-nav">
          <div>
            <Link
              style={{
                fontWeight: "bold",
                color: "var(--pure-white)",
                fontSize: "1.3rem",
              }}
              to="/"
            >
              Home
            </Link>
            <hr
              style={{
                border: "1px solid var(--pure-white)",
                width: "60%",
                marginTop: "0",
              }}
            />
          </div>

          <Link
            style={{ color: "var(--pure-white)", fontSize: "1.2rem" }}
            to="/features"
          >
            Features
          </Link>
          <Link
            style={{ color: "var(--pure-white)", fontSize: "1.2rem" }}
            to="/contact"
          >
            Contact Us
          </Link>
        </nav>
        <div className="home-auth-buttons">
          <Link to="/signup" className="home-signin-button">
            Sign Up
          </Link>
          <Link to="/login" className="home-signup-button">
            Login
          </Link>
        </div>

        <div
          onClick={(e) => setShowNav(!showNav)}
          className="home-header-responsive-menu"
        >
          ☰
        </div>
      </header>

      {showNav && (
        <nav className="home-responsive-nav">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "calc(100vw - 60px)",
              marginBottom: "30px",
              alignSelf: "center",
            }}
          >
            <div className="home-logo">
              <img src={SmartAssist} alt="SmartAssist Logo" />
              <img src={SmartAssistName} alt="SmartAssist AI" />
            </div>
            <div
              onClick={(e) => setShowNav(!showNav)}
              className="home-header-responsive-menu"
            >
              ☰
            </div>
          </div>

          <Link
            className="home-landing-page-responsive-link"
            style={{
              marginBottom: "15px",
              fontWeight: "bold",
              color: "var(--pure-white)",
              fontSize: "1.9rem",
              marginLeft: "30px",
            }}
            to="/"
          >
            Home
          </Link>
          <Link
            className="home-landing-page-responsive-link"
            style={{
              marginBottom: "15px",
              color: "var(--pure-white)",
              fontSize: "1.6rem",
              marginLeft: "30px",
            }}
            to="/features"
          >
            Features
          </Link>
          <Link
            className="home-landing-page-responsive-link"
            style={{
              color: "var(--pure-white)",
              fontSize: "1.6rem",
              marginLeft: "30px",
            }}
            to="/contact"
          >
            Contact Us
          </Link>
        </nav>
      )}

      <section className="home-hero">
        <div className="home-hero-content">
          <h2
            style={{
              lineHeight: "0.7",
              fontWeight: "bolder",
              fontSize: "4rem",
              fontFamily: "Open Sans",
            }}
          >
            AI-POWERED SUPPORT,
          </h2>
          <h2
            className="responsive-h1-show"
            style={{
              lineHeight: "0.7",
              fontWeight: "bolder",
              fontSize: "4rem",
              color: "var(--light-blue)",
              fontFamily: "Open Sans",
            }}
          >
            ANYTIME.
          </h2>
          <h2
            className="responsive-h1-hide"
            style={{
              lineHeight: "0.7",
              fontWeight: "bolder",
              fontSize: "4rem",
              color: "var(--light-blue)",
              fontFamily: "Open Sans",
            }}
          >
            A N Y T I M E.
          </h2>
          <p
            style={{
              marginTop: "50px",
              fontWeight: "bold",
              fontSize: "1.35rem",
            }}
          >
            SmarrtAssist: The ultimate AI assistant to streamline operations.
          </p>
          <button
            className="home-hero-try-now-button"
            onClick={(e) => (window.location.href = "/login")}
          >
            Try Now!
          </button>
          <button
            className="home-hero-see-features-button"
            onClick={(e) => (window.location.href = "/features")}
          >
            See Features!
          </button>
        </div>
        <div style={{ marginLeft: "80px" }} className="home-hero-image">
          <img src={CA} alt="AI Assistant" />
        </div>
      </section>

      <section className="home-steps responsive-h1-hide">
        <h2>4 Simple Steps to Launch 🚀</h2>
        <p>
          It only takes a grand total of 20 minutes to have all of our services
          at your fingertips. Don't hesitate, sign up now!
        </p>
        <HomeStepsCarousel />
        <div className="home-steps-container">
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#fafafa"
                  d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z"
                />
              </svg>
            </div>
            <h3>Join Our Platform</h3>
            <p>
              Sign up and become a part of the SmartAssist AI community. Gain
              access to all the tools and resources you need to get started.
            </p>
          </div>
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 576 512"
              >
                <path
                  fill="#ffffff"
                  d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"
                />
              </svg>
            </div>
            <h3>Customize UI</h3>
            <p>
              Personalize your AI assistant's interface to match your brand's
              unique style. Choose from a variety of colors, fonts, and layouts
              to create a seamless user experience.
            </p>
          </div>
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 384 512"
              >
                <path
                  fill="#ffffff"
                  d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-102.1-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31L216 408z"
                />
              </svg>
            </div>
            <h3>Upload Training Data</h3>
            <p>
              Easily upload your company's data, content, and any relevant
              information. Our platform supports various formats to ensure a
              smooth and quick setup.
            </p>
          </div>
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 640 512"
              >
                <path
                  fill="#ffffff"
                  d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"
                />
              </svg>
            </div>
            <h3>Start Using</h3>
            <p>
              Activate your AI assistant and start enjoying the benefits.
              Whether for customer support, internal assistance, or specialized
              training, SmartAssist AI is ready to enhance your operations.
            </p>
          </div>
        </div>
      </section>

      <section className="home-business responsive-h1-show">
        <h2 style={{ textDecoration: "underline" }}>
          Comprehensive Solutions for Your Business Needs
        </h2>
        <p>
          Our platform offers a wide range of features tailored to meet the
          unique requirements of your business.
        </p>
        <button
          onClick={(e) => (window.location.href = "/features")}
          style={{ marginTop: "0px", marginBottom: "20px" }}
          className="responsive-h1-show"
        >
          Learn More
        </button>
        <div className="home-business-container">
          <div className="home-business-feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ textAlign: "center", alignSelf: "center" }}
              height="80"
              width="80"
              viewBox="0 0 640 512"
            >
              <path
                fill="#55708a"
                d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2s0 0 0 0s0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.2-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9c0 0 0 0 0 0s0 0 0 0l-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z"
              />
            </svg>
            <h3>Customer Support Chatbots </h3>
            <p>
              Automate customer interactions to enhance support efficiency and
              satisfaction.
            </p>
          </div>
          <div className="home-business-feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                textAlign: "center",
                alignSelf: "center",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              height="60"
              width="60"
              viewBox="0 0 512 512"
            >
              <path
                fill="#55708a"
                d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"
              />
            </svg>
            <h3>Service Representative Assistance</h3>
            <p>
              Provide real-time support for service representatives to improve
              response times and accuracy.
            </p>
          </div>
          <div className="home-business-feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                textAlign: "center",
                alignSelf: "center",
                marginTop: "5px",
              }}
              height="70"
              width="70"
              viewBox="0 0 640 512"
            >
              <path
                fill="#55708a"
                d="M320 0c17.7 0 32 14.3 32 32l0 64 120 0c39.8 0 72 32.2 72 72l0 272c0 39.8-32.2 72-72 72l-304 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l120 0 0-64c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224l16 0 0 192-16 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-16 0 0-192 16 0z"
              />
            </svg>
            <h3>Specialized Trained Bots </h3>
            <p>
              Deploy tailored bots trained for specific tasks to optimize
              specialized business processes.
            </p>
          </div>
        </div>
      </section>

      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
        className="home-choose-us-container"
      >
        <div style={{ display: "flex" }}>
          <img
            src={personAndRobot}
            className="person-and-robot-img"
            alt="Person Talking to Robot"
          />
        </div>

        <div className="home-choose-us-text-container">
          <h2>
            Why You Should&nbsp;<b>Choose Us</b>
          </h2>

          <div className="home-choose-us-reason-container">
            <div
              style={{ height: "35px" }}
              className="home-icon-wrapper home-icon-wrapper-choose-us"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#ffffff"
                  d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"
                />
              </svg>
            </div>
            <div className="home-choose-us-reason-text">
              <h3>Effortless Client Management</h3>
              <p>
                SmartAssist AI simplifies client interactions, handling multiple
                queries efficiently to keep your clients informed and satisfied.
              </p>
            </div>
          </div>

          <div className="home-choose-us-reason-container">
            <div
              style={{ height: "35px" }}
              className="home-icon-wrapper home-icon-wrapper-choose-us"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 576 512"
              >
                <path
                  fill="#ffffff"
                  d="M465.4 409.4l87.1-150.2-32-.3-55.1 95L259.2 0 23 407.4l32 .3L259.2 55.6zm-355.3-44.1h32.1l117.4-202.5L463 511.9l32.5 .1-235.8-404.6z"
                />
              </svg>
            </div>
            <div className="home-choose-us-reason-text">
              <h3>Enhance Decision Making</h3>
              <p>
                Gain valuable insights with SmartAssist AI, helping you make
                data-driven decisions that improve business outcomes.
              </p>
            </div>
          </div>

          <div className="home-choose-us-reason-container">
            <div
              style={{ height: "35px" }}
              className="home-icon-wrapper home-icon-wrapper-choose-us"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#ffffff"
                  d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288l111.5 0L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7l-111.5 0L349.4 44.6z"
                />
              </svg>
            </div>
            <div className="home-choose-us-reason-text">
              <h3>Boost productivity</h3>
              <p>
                Our AI assistant streamlines workflows, allowing your team to
                focus on critical tasks, driving productivity to new heights.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-business responsive-h1-hide ">
        <h2 style={{ textDecoration: "underline" }}>
          Comprehensive Solutions for Your Business Needs
        </h2>
        <p>
          Our platform offers a wide range of features tailored to meet the
          unique requirements of your business.
        </p>
        <div className="home-business-container">
          <div className="home-business-feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ textAlign: "center", alignSelf: "center" }}
              height="80"
              width="80"
              viewBox="0 0 640 512"
            >
              <path
                fill="#55708a"
                d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2s0 0 0 0s0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.2-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9c0 0 0 0 0 0s0 0 0 0l-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z"
              />
            </svg>
            <h3>Customer Support Chatbots </h3>
            <p>
              Automate customer interactions to enhance support efficiency and
              satisfaction.
            </p>
          </div>
          <div className="home-business-feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                textAlign: "center",
                alignSelf: "center",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              height="60"
              width="60"
              viewBox="0 0 512 512"
            >
              <path
                fill="#55708a"
                d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"
              />
            </svg>
            <h3>Service Representative Assistance</h3>
            <p>
              Provide real-time support for service representatives to improve
              response times and accuracy.
            </p>
          </div>
          <div className="home-business-feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                textAlign: "center",
                alignSelf: "center",
                marginTop: "5px",
              }}
              height="70"
              width="70"
              viewBox="0 0 640 512"
            >
              <path
                fill="#55708a"
                d="M320 0c17.7 0 32 14.3 32 32l0 64 120 0c39.8 0 72 32.2 72 72l0 272c0 39.8-32.2 72-72 72l-304 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l120 0 0-64c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224l16 0 0 192-16 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-16 0 0-192 16 0z"
              />
            </svg>
            <h3>Specialized Trained Bots </h3>
            <p>
              Deploy tailored bots trained for specific tasks to optimize
              specialized business processes.
            </p>
          </div>
        </div>
        <button
          onClick={(e) => (window.location.href = "/features")}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          Learn More
        </button>
      </section>

      <section className="home-steps responsive-h1-show">
        <h2>4 Simple Steps to Launch 🚀</h2>
        <p>
          It only takes a grand total of 20 minutes to have all of our services
          at your fingertips. Don't hesitate, sign up now!
        </p>
        <HomeStepsCarousel />
        <div className="home-steps-container">
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#fafafa"
                  d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z"
                />
              </svg>
            </div>
            <h3>Join Our Platform</h3>
            <p>
              Sign up and become a part of the SmartAssist AI community. Gain
              access to all the tools and resources you need to get started.
            </p>
          </div>
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 576 512"
              >
                <path
                  fill="#ffffff"
                  d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"
                />
              </svg>
            </div>
            <h3>Customize UI</h3>
            <p>
              Personalize your AI assistant's interface to match your brand's
              unique style. Choose from a variety of colors, fonts, and layouts
              to create a seamless user experience.
            </p>
          </div>
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 384 512"
              >
                <path
                  fill="#ffffff"
                  d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-102.1-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31L216 408z"
                />
              </svg>
            </div>
            <h3>Upload Training Data</h3>
            <p>
              Easily upload your company's data, content, and any relevant
              information. Our platform supports various formats to ensure a
              smooth and quick setup.
            </p>
          </div>
          <div className="home-step">
            <div className="home-icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="54"
                width="54"
                viewBox="0 0 640 512"
              >
                <path
                  fill="#ffffff"
                  d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"
                />
              </svg>
            </div>
            <h3>Start Using</h3>
            <p>
              Activate your AI assistant and start enjoying the benefits.
              Whether for customer support, internal assistance, or specialized
              training, SmartAssist AI is ready to enhance your operations.
            </p>
          </div>
        </div>
      </section>

      {botPlans && botPlans.length > 0 && (
        <>
          <BotPlanCarousel botPlans={botPlans} />

          <BotPlanCarouselResponsive botPlans={botPlans} />
        </>
      )}


      <section style={{paddingBottom: '35px'}} className="home-steps">
      
<h2  style={{display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', marginTop: '0', paddingTop: '0'}}>Getting Started</h2>
<video width="60%" height="auto" controls>
  <source src="/ProductDemo.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>
      </section>

      
      <section className="home-get-in-touch home-my-get-in-touch">
        <div className="home-get-in-touch-text">
          <h2>Curious?</h2>
        </div>
        <button
          className="home-get-in-touch-signup-button"
          onClick={(e) => (window.location.href = "/login")}
        >
          Sign Up
        </button>
        <button onClick={(e) => (window.location.href = "/contact")}>
          Contact Us
        </button>
      </section>

      <footer className="home-footer-content">
        <div>
          <div className="home-footer-logo">
            <img
              className="home-footer-logo-logo-img"
              src={SmartAssist}
              alt="SmartAssist Logo"
            />
            <img
              className="home-footer-logo-logo-text"
              src={SmartAssistName}
              alt="SmartAssist AI"
            />
          </div>
          <nav className="home-footer-nav">
            <Link className="home-footer-link" to="/">
              Home
            </Link>
            <Link className="home-footer-link" to="/features">
              Features
            </Link>
            <Link className="home-footer-link" to="/contact">
              Contact Us
            </Link>
            <Link
              className="home-footer-link home-footer-link-signin"
              to="/login"
            >
              Sign In
            </Link>
          </nav>
        </div>
        <hr
          style={{
            border: "1px solid var(--pure-white)",
            width: "100%",
            margin: "20px auto",
            marginBottom: "5px",
          }}
        />
        <div className="home-footer-bottom">
          <p style={{ color: "var(--pure-white)" }}>
            &copy; 2024 SmartAssist AI. All rights reserved.
          </p>
          <div className="home-footer-socials">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              href="https://www.linkedin.com/in/mzmtechnologies/"
              height="25"
              width="22"
              viewBox="0 0 448 512"
            >
              <path
                fill="#ffffff"
                d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
              />
            </svg>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
