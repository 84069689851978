import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useEffect, useState } from 'react';
import loading  from '../logos/loading.gif';
import '../cssStyling/layout.css';
import config from '../config';

interface AnalyticsProps {
    info: any;
    orgSite: any;
    user: any;
    displayAnalytics: boolean;
    knowledgeBaseContentCharacterCount: number;
    handleShowBotPlanPage: any;
    setDisplayAnalytics: any;
    setDisplaySettings: any;
}

interface User {
    chatSession: {
        ChatSessionID: number;
        OrganizationSiteID: number;
        ChatSessionUserName: string;
        ChatSessionUserEmail: string;
        ChatSessionIPaddress: string;
        CreateDate: Date;
        city: string;
        country: string;
        continent: string;
    },
    chatSessionDetails?: {
        ChatSessionDetailID: number;
        UserQuestion: string;
        Response: string;
        NumberofPromptTokens: number;
        NumberofResponseTokens: number;
        CreateDate: Date;
    }[],
    chatSessionFeedback?: {
        ChatSessionFeedbackID: number;
        FeedbackType: string;
        Response: string | null;
        CreateDate: Date;
    }[],
}

interface FeedbackCounts {
    liked: number;
    disliked: number;
    noAnswer: number;
}

interface CredentialCounts {
    name: number;
    email: number;
    both: number;
}


const Analytics: React.FC<AnalyticsProps> = ({ setDisplaySettings, handleShowBotPlanPage, knowledgeBaseContentCharacterCount, info, orgSite, user, displayAnalytics, setDisplayAnalytics }) => {
    const [analytics, setAnalytics] = useState<User[]>([]);
    const [feedbackCounts, setFeedbackCounts] = useState<FeedbackCounts | null>(null);
    const [credentialCounts, setCredentialCounts] = useState<CredentialCounts | null>(null);
    const [chosenYear, setChosenYear] = useState<string | null>('All');
    const [chosenMonth, setChosenMonth] = useState<string | null > (null);
    const [chosenDay, setChosenDay] = useState<string | null>(null);
    const [cityStats, setCityStats] = useState<any>([]);
    const [countryStats, setCountryStats] = useState<any>([]);
    const [continentStats, setContinentStats] = useState<any>([]);
    const [hourStats, setHourStats] = useState<any>([]);
    const [dayStats, setDayStats] = useState<any>([]);
    const [monthStats, setMonthStats] = useState<any>([]);
    const [yearStats, setYearStats] = useState<any>([]);
    const [chosenContinent, setChosenContinent] = useState<string | null>('All');
    const [chosenCountry, setChosenCountry] = useState<string | null>(null);
    const [filterByTokens, setFilterByTokens] = useState<boolean>(false);
    const [filterByTokens2, setFilterByTokens2] = useState<boolean>(false);
    const [chosenCity, setChosenCity] = useState<string | null>('');    
    const [emptyArray, setEmptyArray] = useState<boolean>(false);

     useEffect(() => {
        if (chosenContinent === 'All') {
            setChosenCountry(null);
            setChosenCity(null);
        } else {
            setChosenCountry('All');
            setChosenCity(null);
        }
    }, [chosenContinent]);
    
    useEffect(() => {
        if (chosenCountry === 'All') {
            setChosenCity(null);
        } else if (chosenCountry) {
            setChosenCity('All');

        }else {
            setChosenCity(null);
        }
    }, [chosenCountry]);

    useEffect(() => {
        if (chosenMonth === 'All') {
            setChosenDay(null);
        } else if (chosenMonth && chosenMonth !== '') {
            setChosenDay('All');
        }
    }, [chosenMonth]);

    useEffect(() => {
        if (chosenYear === 'All') {
            setChosenMonth(null);
            setChosenDay(null);
        } else {
            setChosenMonth('All');
            setChosenDay(null);
        }
    }, [chosenYear]);

    function getMonthNameFromString(yearMonth: string): string {
        const monthIndex = parseInt(yearMonth.split('-')[1], 10) - 1;
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return monthNames[monthIndex];
    }
    
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${config.apiUrl}/getAnalytics`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        OrganizationSiteID: orgSite.OrganizationSiteID,
                    }),
                });
                const data = await response.json();
                console.log("RAW FETCHED DATA:", data);
                if (data.users.length === 0) {
                    setEmptyArray(true);
                } else {
                setAnalytics(data.users);
                }
            } catch (error) {
                console.error('Failed to fetch analytics', error);
            }
        }
        fetchData();
    }, [orgSite.OrganizationSiteID]);

    useEffect(() => {
        if (!Array.isArray(analytics) || analytics.length === 0) {
            return;
        }
    
        interface ContinentData {
            continent: string;
            userQueries: number;
            tokensUsed: number;
            chatSessions: number;
        }
    
        interface CountryData { 
            continent: string;
            country: string;
            userQueries: number;
            tokensUsed: number;
            chatSessions: number;   
        }
    
        interface CityData {
            country: string;
            city: string;
            userQueries: number;
            tokensUsed: number;
            chatSessions: number;
        }
    
        interface YearData {
            year: string;
            userQueries: number;
            tokensUsed: number;
            chatSessions: number;
        }
    
        interface MonthData {
            yearMonth: string;
            yearMonthName: string;
            userQueries: number;
            tokensUsed: number;
            chatSessions: number;
        }
    
        interface DayData {
            yearMonthDay: string;
            userQueries: number;
            tokensUsed: number;
            chatSessions: number;
        }
    
        interface HourData {
            yearMonthDayHour: string;
            userQueries: number;
            tokensUsed: number;
            chatSessions: number;
        }
    
        const {
            feedbackCounts,
            credentialCounts,
            continentStats,
            countryStats,
            cityStats,
            yearStats,
            monthStats,
            dayStats,
            hourStats
        } = analytics.reduce((acc: {
            feedbackCounts: FeedbackCounts,
            credentialCounts: CredentialCounts,
            continentStats: Record<string, ContinentData>,
            countryStats: Record<string, CountryData>,
            cityStats: Record<string, CityData>,
            yearStats: Record<string, YearData>,
            monthStats: Record<string, MonthData>,
            dayStats: Record<string, DayData>,
            hourStats: Record<string, HourData>
        }, user: User) => {
    
            user.chatSession.CreateDate = new Date(user.chatSession.CreateDate);
    
            // Process feedback counts
            if (user.chatSessionFeedback && user.chatSessionFeedback.length > 0) {
                const firstFeedback = user.chatSessionFeedback.sort((a, b) => new Date(a.CreateDate).getTime() - new Date(b.CreateDate).getTime())[0];
                if (firstFeedback.FeedbackType === 'Liked') {
                    acc.feedbackCounts.liked++;
                } else if (firstFeedback.FeedbackType === 'Disliked') {
                    acc.feedbackCounts.disliked++;
                } else {
                    acc.feedbackCounts.noAnswer++;
                }
            } else {
                acc.feedbackCounts.noAnswer++;
            }
    
            // Process credential counts
            if (user.chatSession.ChatSessionUserEmail && user.chatSession.ChatSessionUserName) {
                acc.credentialCounts.both++;
            } else if (user.chatSession.ChatSessionUserEmail) {
                acc.credentialCounts.email++;
            } else if (user.chatSession.ChatSessionUserName) {
                acc.credentialCounts.name++;
            }
    
           
            // Process continent stats
            const continent = user.chatSession.continent;
            if (!acc.continentStats[continent]) {
                acc.continentStats[continent] = {
                    continent,
                    userQueries: 0,
                    tokensUsed: 0,
                    chatSessions: 0
                };
            }
            acc.continentStats[continent].userQueries += user.chatSessionDetails ? user.chatSessionDetails.length : 0;
            acc.continentStats[continent].tokensUsed += user.chatSessionDetails ? user.chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0) : 0;
            acc.continentStats[continent].chatSessions++;
    
            // Process country stats
            const country = user.chatSession.country;
            if (!acc.countryStats[country]) {
                acc.countryStats[country] = {
                    continent,
                    country,
                    userQueries: 0,
                    tokensUsed: 0,
                    chatSessions: 0
                };
            }
            acc.countryStats[country].userQueries += user.chatSessionDetails ? user.chatSessionDetails.length : 0;
            acc.countryStats[country].tokensUsed += user.chatSessionDetails ? user.chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0) : 0;
            acc.countryStats[country].chatSessions++;
    
            // Process city stats
            const city = user.chatSession.city;
            if (!acc.cityStats[city]) {
                acc.cityStats[city] = {
                    country,
                    city,
                    userQueries: 0,
                    tokensUsed: 0,
                    chatSessions: 0
                };
            }
            acc.cityStats[city].userQueries += user.chatSessionDetails ? user.chatSessionDetails.length : 0;
            acc.cityStats[city].tokensUsed += user.chatSessionDetails ? user.chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0) : 0;
            acc.cityStats[city].chatSessions++;
    
            // Process year stats
            const year = user.chatSession.CreateDate.getFullYear().toString();
            if (!acc.yearStats[year]) {
                acc.yearStats[year] = {
                    year,
                    userQueries: 0,
                    tokensUsed: 0,
                    chatSessions: 0
                };
            }
            acc.yearStats[year].userQueries += user.chatSessionDetails ? user.chatSessionDetails.length : 0;
            acc.yearStats[year].tokensUsed += user.chatSessionDetails ? user.chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0) : 0;
            acc.yearStats[year].chatSessions++;
    
            // Process month stats
            const month = `${year}-${user.chatSession.CreateDate.getMonth() + 1}`; // "YYYY-MM"
            if (!acc.monthStats[month]) {
                acc.monthStats[month] = {
                    yearMonth: month,
                    yearMonthName: getMonthNameFromString(month),
                    userQueries: 0,
                    tokensUsed: 0,
                    chatSessions: 0
                };
            }
            acc.monthStats[month].userQueries += user.chatSessionDetails ? user.chatSessionDetails.length : 0;
            acc.monthStats[month].tokensUsed += user.chatSessionDetails ? user.chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0) : 0;
            acc.monthStats[month].chatSessions++;
    
            // Process day stats
            const day = `${month}-${user.chatSession.CreateDate.getDate()}`; // "YYYY-MM-DD"
            if (!acc.dayStats[day]) {
                acc.dayStats[day] = {
                    yearMonthDay: day,
                    userQueries: 0,
                    tokensUsed: 0,
                    chatSessions: 0
                };
            }
            acc.dayStats[day].userQueries += user.chatSessionDetails ? user.chatSessionDetails.length : 0;
            acc.dayStats[day].tokensUsed += user.chatSessionDetails ? user.chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0) : 0;
            acc.dayStats[day].chatSessions++;
    
            // Process hour stats
            const hour = `${day}-${user.chatSession.CreateDate.getHours()}`; // "YYYY-MM-DD-HH"
            if (!acc.hourStats[hour]) {
                acc.hourStats[hour] = {
                    yearMonthDayHour: hour,
                    userQueries: 0,
                    tokensUsed: 0,
                    chatSessions: 0
                };
            }
            acc.hourStats[hour].userQueries += user.chatSessionDetails ? user.chatSessionDetails.length : 0;
            acc.hourStats[hour].tokensUsed += user.chatSessionDetails ? user.chatSessionDetails.reduce((acc, detail) => acc + detail.NumberofPromptTokens + detail.NumberofResponseTokens, 0) : 0;
            acc.hourStats[hour].chatSessions++;
    
            // Ensure all 24 hours are filled
            for (let i = 0; i < 24; i++) {
                const hourKey = `${day}-${i}`;
                if (!acc.hourStats[hourKey]) {
                    acc.hourStats[hourKey] = {
                        yearMonthDayHour: hourKey,
                        userQueries: 0,
                        tokensUsed: 0,
                        chatSessions: 0
                    };
                }
            }
    
            return acc;
        }, {
            feedbackCounts: { liked: 0, disliked: 0, noAnswer: 0 },
            credentialCounts: { name: 0, email: 0, both: 0 },
            continentStats: {},
            countryStats: {},
            cityStats: {},
            yearStats: {},
            monthStats: {},
            dayStats: {},
            hourStats: {}
        });

        console.log('Reduced Data:', { feedbackCounts, credentialCounts, continentStats, countryStats, cityStats, yearStats, monthStats, dayStats, hourStats});

        
        setFeedbackCounts(feedbackCounts);
        setCredentialCounts(credentialCounts);
        setContinentStats(Object.values(continentStats));
        setCountryStats(Object.values(countryStats));
        setCityStats(Object.values(cityStats));
        setYearStats(Object.values(yearStats).sort((a: any, b: any) => parseInt(a.year) - parseInt(b.year)));
        setMonthStats(Object.values(monthStats));
        setDayStats(Object.values(dayStats));
        setHourStats(Object.values(hourStats));
    }, [analytics, chosenMonth, chosenYear]);



    const preprocessData = (data: any, type: any) => {
        return data.map((item: any) => {
            if (type === 'month') {
                return { ...item, label: item.yearMonthName };
            } else if (type === 'day') {
                return { ...item, label: item.yearMonthDay.split('-')[2] };
            } else if (type === 'hour') {
                return { ...item, label: item.yearMonthDayHour.split('-')[3] };
            }
            return item;
        });
    };


 

    

    const chartSetting = {
        yAxis: [
          {
            label: 'Count',
          },
        ],
        width: 500,
        height: 300,
        sx: {
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-40px, 0)',
          },
        },
      };
      const valueFormatter = (value: number | null) => `${value}`;


    return (
        <>
            <h1 style={{ backgroundColor: 'var(--dark-blue)', border: '3px solid var(--light-blue)', textShadow: '-1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue)', color: 'white', width: '100%', textAlign: 'center', padding: '10px', marginTop: '0px' }} className="orgadmin-heading">Analytics</h1>
            
            {!emptyArray && (!feedbackCounts || !credentialCounts )? 
            <div className='loading-analytics-container'>
            <img src={loading} alt="loading" className="loading-analytics" />
            <p className='loading-analytics-text'>Loading one moment...</p> 
            </div>
            : 
            <>
            {emptyArray? <p style={{textAlign: 'center'}} className='loading-analytics-text'>No data available</p> : <>
            <div style={{display: 'flex', alignContent: 'flex-start', justifyContent: 'flex-start', marginBottom: '0', paddingBottom: '0'}}>
              <h3 style={{marginBottom: '0', paddingBottom: '0'}} className="orgadmin-subheading">Current Site:</h3>
              <h1 style={{marginBottom: '0', paddingBottom: '0', color: 'var(--light-blue)', marginLeft: '5px', marginTop: '23px', fontWeight: 'bold'}}>{orgSite.OrganizationSiteName}</h1>
                <h1 style={{marginBottom: '0', paddingBottom: '0', color: 'var(--dark-blue)', marginLeft: '5px', fontWeight: 'bold', fontSize: '1.8rem', marginTop: '11px', cursor: 'pointer'}} onClick={(e) => {
                  setDisplayAnalytics(false)
                  setDisplaySettings(true)
                }}>⇆</h1>
              </div>
            <h3 className="orgadmin-subheading">Plan Limitations</h3>
            <ul className='analytics-plan-limitations-ul'>
            
                <li><b>Token Usage This Month: </b> {info.user.TokenCount}/{info.user.BotPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase().includes('tokens')).BotPlanRuleValue} tokens</li>
                <li><b>Site Knowledge Base Usage: </b> {knowledgeBaseContentCharacterCount} characters</li>
                <li><b>Organization Knowledge Base Usage: </b>{info.user.ContentSize}/{info.user.BotPlan.BotPlanRule.find((rule: any) => rule.BotPlanRuleName.toLowerCase().includes('content')).BotPlanRuleValue} characters</li>
                <li style={{ display: 'flex', placeContent: 'center' }}>
  Need more usage?{' '}
  <span onClick={handleShowBotPlanPage} className="click-here" style={{ textDecoration: 'underline', marginLeft: '5px' }}>
    Click here
  </span>
  &nbsp;to upgrade your plan!
</li>
                
            </ul>
            <h3 className="orgadmin-subheading">Interaction Metrics</h3>
            <div className = 'piecharts-container'>
                <div className="piecharts-container-mini">
            <h3 className="orgadmin-subheading-subheading">Feedback Summary</h3>
            {feedbackCounts ?
            
                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: feedbackCounts.liked, label: 'Liked', color: '#779cc0' },
                                { id: 1, value: feedbackCounts.disliked, label: 'Disliked', color: '#55708a' },
                                { id: 2, value: feedbackCounts.noAnswer, label: 'No Answer', color: '#a5bfd4' }
                            ]
                        }
                    ]}
                    width={420}
                    height={250}
                /> : <p>Loading feedback counts...</p>
            }
            </div>
            <div className="piecharts-container-mini">
            <h3 className="orgadmin-subheading-subheading">Credential Usage</h3>
            {credentialCounts?
                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: credentialCounts.name, label: 'Name only', color: '#a5bfd4' },
                                { id: 1, value: credentialCounts.email, label: 'Email only', color: '#55708a' },
                                { id: 2, value: credentialCounts.both, label: 'Name & Email', color: '#779cc0' }
                            ]
                        }
                    ]}
                    width={420}
                    height={250}
                /> : <p>Loading credential counts...</p>
            }
            </div>
            </div>


<h3 style={{marginTop: '60px'}} className="orgadmin-subheading">Usage by Time</h3>
<div className="analytics-filter-container">
    <p>Filter: </p>
    <div>
        <label>Year: </label>
        <select value={chosenYear || ''} onChange={(e) => setChosenYear(e.target.value)}>
            <option value="All">All</option>
            <option hidden={true} value=""></option>
            {yearStats && yearStats.map((year: any) => 
                <option key={year.year} value={year.year}>{year.year}</option>
            )}
        </select>
    </div>

    <div>
        <label>Month: </label>
        <select value={chosenMonth || ''} onChange={(e) => setChosenMonth(e.target.value)}
            disabled={chosenYear === 'All'}
            >
            <option value="All">All</option>
            <option hidden={true} value=""></option>
            {chosenYear !== 'All' && monthStats && monthStats
                .filter((month: any) => month.yearMonth.startsWith(chosenYear))
                .map((month: any) => 
                    <option key={month.yearMonth} value={month.yearMonth}>{month.yearMonthName}</option>
                )
            }
        </select>
    </div>

    <div>
        <label>Day: </label>
        <select value={chosenDay || ''} onChange={(e) => setChosenDay(e.target.value)}
            disabled={chosenMonth === 'All' || chosenYear === 'All'}
            >
            <option value="All">All</option>
            <option hidden={true} value=""></option>
            {chosenMonth !== 'All' && dayStats && dayStats
                .filter((day: any) => day.yearMonthDay.startsWith(chosenMonth))
                .map((day: any) => 
                    <option key={day.yearMonthDay} value={day.yearMonthDay}>{day.yearMonthDay.split('-')[2]}</option>
                )
            }
        </select>
    </div>
</div>

{chosenYear === 'All'? (
    <div className='BarChart'>
    <BarChart
        dataset={yearStats}   
        xAxis={[{ scaleType: 'band', dataKey: 'year', label: 'Years' }]}
        series={ !filterByTokens2 ? [
            { dataKey: 'userQueries', label: `User Queries\u00A0\u00A0\u00A0\u00A0`, valueFormatter, color: '#55708a' },
            { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
        ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }] }
        {...chartSetting}
    />
    </div>
) : chosenMonth === 'All'? (
    <div className='BarChart'>
    <BarChart
        dataset={preprocessData(monthStats.filter((month: any) => month.yearMonth.startsWith(chosenYear)), 'month')}   
        xAxis={[{ scaleType: 'band', dataKey: 'label', label: 'Months' }]}
        series={ !filterByTokens2 ? [
            { dataKey: 'userQueries', label: 'User Queries\u00A0\u00A0\u00A0\u00A0', valueFormatter, color: '#55708a' },
            { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
        ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }] }
        {...chartSetting}
    />
    </div >
) : chosenDay === 'All'? (
    <div className='BarChart'>
    <BarChart
        dataset={preprocessData(dayStats.filter((day: any) => day.yearMonthDay.startsWith(chosenMonth)), 'day')}   
        xAxis={[{ scaleType: 'band', dataKey: 'label', label: 'Days' }]}
        series={ !filterByTokens2 ? [
            { dataKey: 'userQueries', label: 'User Queries\u00A0\u00A0\u00A0\u00A0', valueFormatter, color: '#55708a' },
            { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
        ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }] }
        {...chartSetting}
    />
    </div>
) : (
    <div className='BarChart'>
    <BarChart
        dataset={preprocessData(hourStats.filter((hour: any) => hour.yearMonthDayHour.startsWith(chosenDay)), 'hour').sort((a: any, b: any) => parseInt(a.label) - parseInt(b.label))}   
        xAxis={[{ scaleType: 'band', dataKey: 'label', label: 'Hour of the Day' }]}
        series={ !filterByTokens2 ? [
            { dataKey: 'userQueries', label: 'User Queries\u00A0\u00A0\u00A0\u00A0', valueFormatter, color: '#55708a' },
            { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
        ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }] }
        {...chartSetting}
    />
    </div>
)}

{!filterByTokens2 && <button className='analytics-switch-button' onClick={(e) => setFilterByTokens2(true)}>⇆ By Tokens</button>}
{filterByTokens2 && <button className='analytics-switch-button' onClick={(e) => setFilterByTokens2(false)}>⇆ By Sessions</button>}

    <h3 style={{marginTop: '50px'}} className="orgadmin-subheading">Usage by Location</h3>
    <div className="analytics-filter-container">
            <p>Filter: </p>
            <div>
            <label>Continent: </label>
            <select 
    value={chosenContinent || ''} 
    onChange={(e) => {
        if (chosenContinent === 'All') {
            setChosenCountry('');
            setChosenCity('');
        }
        setChosenContinent(e.target.value);
    }}
>
    <option value="All">All</option>
    {continentStats && continentStats.map((continent: any) => 
        <option key={continent.continent} value={continent.continent}>{continent.continent}</option>
    )}
</select>
            </div>

           

            <div>
         
    <label>Country: </label>
    <select 
    value={chosenCountry || ''} 
    onChange={(e) => {
        if (chosenCountry === '') {
            setChosenCity('');
        }
        setChosenCountry(e.target.value);
    }} 
    disabled={chosenContinent === 'All'}
>
<option hidden={true} value=""></option>
    <option value="All">All</option>
    {chosenContinent !== 'All' && countryStats && countryStats
        .filter((country: any) => country.continent === chosenContinent)
        .map((country: any) => 
            <option key={country.country} value={country.country}>{country.country}</option>
        )
    }
</select>
</div>

<div>
    <label>City: </label>
    <select 
    value={chosenCity || ''} 
    onChange={(e) => {if (chosenCountry === '' || chosenContinent === 'All') {
        setChosenCity('');
    }setChosenCity(e.target.value)}} 
    disabled={chosenCountry === 'All' || chosenContinent === 'All'}
>
    <option hidden={true} value=""></option>
    <option value="All">All</option>
    {chosenCountry !== 'All' && chosenContinent !== 'All' && cityStats && cityStats
        .filter((city: any) => city.country === chosenCountry)
        .map((city: any) => 
            <option key={city.city} value={city.city}>{city.city}</option>
        )
    }
</select>
</div>
</div>

{
    chosenContinent === 'All'? (
        <div className='BarChart'>
        <BarChart
        dataset={continentStats}   
        xAxis={[{ scaleType: 'band', dataKey: 'continent', label: 'Continents' }]}
        series={ !filterByTokens ? [
            { dataKey: 'userQueries', label: 'User Queries\u00A0\u00A0\u00A0\u00A0', valueFormatter, color: '#55708a' },
            { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
        ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }]}
        {...chartSetting}
    />
    </div>
) : chosenCountry === 'All'? (
    <div className='BarChart'>
    <BarChart
        dataset={countryStats.filter ((country: any) => country.continent === chosenContinent)}   
        xAxis={[{ scaleType: 'band', dataKey: 'country', label: 'Countries' }]}
        series={ !filterByTokens ? [
            { dataKey: 'userQueries', label: 'User Queries\u00A0\u00A0\u00A0\u00A0', valueFormatter, color: '#55708a' },
            { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
        ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }]}
        {...chartSetting}
    />
    </div>
) : chosenCity === 'All'? (
    <div className='BarChart'>
    <BarChart
        dataset={cityStats.filter ((city: any) => city.country === chosenCountry)}   
        xAxis={[{ scaleType: 'band', dataKey: 'city', label: 'Cities' }]}
        series={ !filterByTokens ? [
            { dataKey: 'userQueries', label: 'User Queries\u00A0\u00A0\u00A0\u00A0', valueFormatter, color: '#55708a' },
            { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
        ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }]}
        {...chartSetting}
    />
    </div>
    ) : (
        <div className='BarChart'>
        <BarChart
            dataset={cityStats.filter((city: any) => city.city === chosenCity)}
            xAxis={[{ scaleType: 'band', dataKey: 'city', label: 'City' }]}
            series={ !filterByTokens ? [
                { dataKey: 'userQueries', label: 'User Queries\u00A0\u00A0\u00A0\u00A0', valueFormatter, color: '#55708a' },
                { dataKey: 'chatSessions', label: 'Chat Sessions', valueFormatter, color: '#779cc0' }
            ] : [{ dataKey: 'tokensUsed', label: 'Tokens Used', valueFormatter, color: '#a5bfd4' }]}
            {...chartSetting}
        />
        </div>
    )
}

{!filterByTokens && <button className='analytics-switch-button' onClick={(e) => setFilterByTokens(true)}>⇆ By Tokens</button>}
{filterByTokens && <button className='analytics-switch-button' onClick={(e) => setFilterByTokens(false)}>⇆ By Sessions</button>}


           </>}
            </> }
           
        </>
    );
};

export default Analytics;
