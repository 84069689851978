import React, { useState } from 'react';
import config from '../config';


interface ChangePasswordProps {
    changePassword: boolean;
    setChangePassword: (value: boolean) => void;
    fullUser: any;
}

const ChangePasswordComponent: React.FC<ChangePasswordProps> = ({ changePassword, setChangePassword, fullUser }) => {
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    let user: any = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') as string) : fullUser;
    console.log({user});
    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/])[A-Za-z\d!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/]{8,}$/;
        return passwordRegex.test(password);
    };
    const handleSave = async () => {
        // Reset messages
        setError('');
        setSuccess('');

        // Validate old password
        const isOldPasswordCorrect = currentPassword === user.UserPassword;
        if (!isOldPasswordCorrect) {
            setError('Old password is incorrect.');
            return;
        }

        // Validate new password guidelines
        if (!validatePassword(newPassword)) {
            setError('Password must be at least 8 characters, contain an upper and lowercase letter, a number, and a special character.');
            return;
        }

        // Validate new password confirmation
        if (newPassword !== confirmNewPassword) {
            setError('New passwords do not match.');
            return;
        }

        if (newPassword === currentPassword) {
            setError('New password cannot be the same as the old password.');
            return;
        }

        // Proceed with password change logic here
        // For example, make an API call to update the password
        const response: any = await changeUserPassword(newPassword);
        if (response && response.message && response.message === 'Password updated successfully') {
            setSuccess('Password changed successfully.');
            sessionStorage.setItem('user', JSON.stringify({ ...fullUser, UserPassword: newPassword }));
            setChangePassword(false);
        } else {
            console.log({response});
            setError('Failed to change password.');
        }
    };


    const changeUserPassword = async (newPassword: string): Promise<{ success: boolean }> => {
        // Implement the API call to change the user's password
        const response = await fetch(`${config.apiUrl}/updatePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ UserID: user.UserCredentialID, UserPassword: newPassword }),
        });
        return await response.json();
    };

    return (
        <>
            {changePassword && (
                <>
                    <div className="orgadmin-personal-info">
                        <h3 className="orgadmin-subheading" onClick={() => setChangePassword(false)}>Change Password</h3>
                        <svg
                            onClick={() => setChangePassword(false)}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
                        </svg>
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                    <div>
                        <h5 style={{ color: 'var(--dark-blue)' }}>Current Password: </h5>
                        <input
                            type="password"
                            placeholder="Enter current password..."
                            value={currentPassword}
                            maxLength={49}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <h5 style={{ color: 'var(--dark-blue)' }}>New Password: </h5>
                        <input
                            type="password"
                            maxLength={49}
                            placeholder="Enter new password..."
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <h5 style={{ color: '#55708a' }}>Confirm New Password: </h5>
                        <input
                            type="password"
                            placeholder="Confirm new password..."
                            value={confirmNewPassword}
                            maxLength={49}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                    </div>
                    
                    <div style={{ marginTop: '20px' }} className="orgadmin-button-container">
                        <button className="orgadmin-button-save" onClick={handleSave}>Save</button>
                        <button onClick={() => {
                            setChangePassword(false);
                        }} className="orgadmin-button-cancel">Cancel</button>
                    </div>
                </>
            )}
        </>
    );
};

export default ChangePasswordComponent;
