import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../cssStyling/ValidateEmail.css'; // Create this CSS file based on your styling preferences
import config from '../config';

function ValidateEmail() {
    const location = useLocation();
    const navigate = useNavigate();
    const info1 = location.state?.info1;
    let validEntry: boolean = false;
    if (location.state) {
        validEntry = true;
    }

    const [countdown, setCountdown] = useState(59);
    const [code, setCode] = useState(Array(6).fill(''));
    const [error, setError] = useState('');

    useEffect(() => {
        if (validEntry) {
        if (info1.code === 'error sending email') {
            alert('Error sending email. Please try again.');
        }
    }
    }, [validEntry ? info1.code : '']);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    if (!validEntry) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <h1 style={{fontSize: '30px'}}>Unauthorized</h1>
            </div>
        );
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
        }
    };

    const handleVerify = async () => {
        console.log('code is: ', code.join(''));
        console.log('info1.code is: ', info1.code);
        console.log( code.join('') == info1.code);
        if (code.join('') == info1.code) {
            const response = await fetch(`${config.apiUrl}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: info1.UserEmail,
                    firstName: info1.UserFirstName,
                    lastName: info1.UserLastName,
                    siteURL: info1.OrganizationSiteURL,
                    temperature: info1.temperature,
                    topP: info1.topP,
                    password: info1.UserPassword,
                    jobTitle: info1.UserJobTitle,
                    organizationName: info1.OrganizationName,
                    organizationDescription: info1.OrganizationDescription,
                    site1Name: info1.OrganizationSiteName,
                    site1Description: info1.OrganizationSiteDescription
                }),
            });
          const res = await response.json();
            console.log('res is: ', res);
          navigate('/orgadmin', { state: { user: res.user, info: res.info } });
        } else {
            setError('Invalid code. Please try again.');
        }
    };

    const handleResend = async () => {
        setCountdown(59);
        const code1 = await fetch (`${config.apiUrl}/sendEmailCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: info1.UserEmail,
            }),
        }).then((res) => res.json()).then((data) => {
            return data.code;
        });
        info1.code = code1;
    };

    return (
        <div className="validate-email-container">
            <div className="header-background">
                <div className="icon-container">
                    <svg className="validate-email-mail-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M215.4 96H144 107.8 96v8.8V144v40.4 89L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3V96c0-26.5 21.5-48 48-48h76.6l49.9-36.9C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11L339.4 48H416c26.5 0 48 21.5 48 48v44.3l22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4v-89V144 104.8 96H404.2 368 296.6 215.4zM0 448V242.1L217.6 403.3c11.1 8.2 24.6 12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1V448v0c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64v0zM176 160H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                </div>
                <h2>Verify Your Account</h2>
                <p>Please enter the six digit code that was sent to your email address.</p>
                <div className="code-input-container">
                    {code.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            className="code-input"
                            value={digit}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    ))}
                </div>
                {error && <p style={{color: 'red'}} className="error-message">{error}</p>}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <button className="verify-button" onClick={handleVerify}>Verify & Sign Up</button>
                <button className="resend-button" onClick={handleResend} disabled={countdown !== 0}>Resend Code</button>
                <p className="resend-email">
                    Resend Email in {countdown}s
                </p>
                </div>
                
            </div>
        </div>
    );
}

export default ValidateEmail;
