import React, { useState, useEffect } from 'react';
import icon from '../logos/CyberChatIconWhite.png';
import waterDrop from '../logos/WaterDropNoBackground.png';
import '../cssStyling/layout.css';
import { useNavigate } from 'react-router-dom';
import config from '../config';

function SignUp() {
    const navigate = useNavigate(); 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [emails, setEmails] = useState<any>([]); 
    const [organizationName, setOrganizationName] = useState('');
    const [organizationDescription, setOrganizationDescription] = useState('');
    const [error, setError] = useState('');
    const [botPlans, setBotPlans] = useState<string[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // Adding steps state to track progress
    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        const fetchBotPlans = async () => {
            try {
                const response = await fetch(`${config.apiUrl}/getBotPlans`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const res = await fetch (`${config.apiUrl}/getEmails`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data1 = await res.json();
                setEmails(data1);
                console.log("Emails received!");
                console.log({data1});
                const data = await response.json();
                console.log("Bot plans received: ", data);
                setBotPlans(data);
            } catch (error) {
                // Handle error
            }
        };
        fetchBotPlans();
    }, []);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (isPersonalEmail(email)) {
            return false;
        }
        return emailRegex.test(email);
    };

    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/])[A-Za-z\d!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/]{8,}$/;
        return passwordRegex.test(password);
    };
    
    const personalEmailDomains = [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "outlook.com",
        "aol.com",
        "icloud.com",
        "mail.com",
        "protonmail.com",
        "zoho.com",
        "yandex.com",
        "gmx.com",
        "msn.com",
        "live.com",
        "me.com",
        "rocketmail.com",
        "qq.com",
        "rediffmail.com"
    ];
    const getEmailDomain = (email: string): string => {
        return email.substring(email.lastIndexOf("@") + 1).toLowerCase();
    };
    const isPersonalEmail = (email: string): boolean => {
        const emailDomain = getEmailDomain(email);
        return personalEmailDomains.includes(emailDomain);
    };

    const validateForm = () => {
        if (
            email === '' ||
            password === '' ||
            confirmPassword === '' ||
            firstName === '' ||
            lastName === '' ||
            jobTitle === '' ||
            organizationName === '' ||
            organizationDescription === '' 
        ) {
            setError('Please fill in all fields.');
            return false;
        }

        if (!validateEmail(email)) {
            setError('Please enter a valid organization email address.');
            return false;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return false;
        }

        if (!validatePassword(password)) {
            setError('Password must be at least 8 characters, contain an upper and lowercase letter, a number, and a special character.');
            return false;
        }

        for (const e of emails) {
            if (e.UserEmail.toLowerCase() === email.toLowerCase()) {
                setError('Email already exists. Please use a different email or login.');
                return false;
            } else if (e.UserEmail.split('@')[1].toLowerCase() === email.split('@')[1].toLowerCase()) {
                setError('Someone from your organization has already signed up. Please login or use a different email.');
                return false;
            }
        }

        setError('');
        return true;
    };

    const handleSignUp = async () => {

        if (!validateForm()) {
            return;
        }
          // Enable the button
    setIsButtonDisabled(true);
        const info = {
            UserFirstName: firstName,
            UserLastName: lastName,
            UserJobTitle: jobTitle,
            OrganizationName: organizationName,
            OrganizationDescription: organizationDescription,
            UserEmail: email, 
            UserPassword: password,
            botPlans: botPlans,
        };
        // Set next step after validation
        setCurrentStep(2);
        navigate('/signupcont', { state: { info } });
    };



    return (
        <>
        <div style={{cursor: isButtonDisabled? 'wait' : 'default'}} className='signup-container'>
            <div className='signup-container-left'>
                <img src={waterDrop} alt="Water Drop" className="signup-water-drop-img" />
                <h1 style={{marginBottom: '0'}}>Let's elevate your business,</h1>
                <h1 style={{marginTop: '0'}}>T O G E T H E R.</h1>
            </div>

            {/* Side Progress Panel */}
            <div className="signup-progress-panel">
                    <ul className="signup-progress-steps">
                    <li style={{color: 'var(--pure-white)'}} className={currentStep >= 1 ? 'active' : ''}>
  Personal Information<br /> <br />
  Organizational Information
</li>

                        <li style={{color: 'var(--dark-blue)'}}  className={currentStep >= 3 ? 'active' : ''}>Sites <br /> <br /> Bot Configurations</li>
                        <li style={{color: 'var(--dark-blue)'}}  className={currentStep >= 5 ? 'active last-signup-progress-steps' : 'last-signup-progress-steps'}>Verify Your Account</li>
                    </ul>
                </div>

            <div className='signup-container-right'>
                <div className='signup-header'>
                    <div className="signup-logo-container">
                        <img src={icon} alt="Cyber Chat Icon" className="signup-icon-img" />
                    </div>
                    <p className='signup-signin-text'>Already have an account? <a href="/login">Sign in here!</a></p>
                </div>
                <div className='signup-form'>
                    <h1>Account Onboarding.</h1>
                    {error && <p style={{color: 'red', fontSize: '0.7rem', margin: '0px', lineHeight: '1.5'}} className="error-message">{error}</p>}
                    <h5 style={{marginTop: '0px'}}>Personal Information</h5>
                    <div>
                        <div>
                            <label>First Name</label>
                            <input value={firstName} maxLength={49}  onChange={(e) => setFirstName(e.target.value)} type="text" />
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input value={lastName} maxLength={49}  onChange={(e) => setLastName(e.target.value)} type="text" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>Work Email</label>
                            <input value={email} maxLength={99}  onChange={(e) => setEmail(e.target.value)} type="text" />
                        </div>
                        <div>
                            <label>Job Title</label>
                            <input value={jobTitle} maxLength={49}  onChange={(e) => setJobTitle(e.target.value)} type="text" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>Password</label>
                            <input value={password} maxLength={49}  onChange={(e) => setPassword(e.target.value)} type="password" />
                        </div>
                        <div>
                            <label>Confirm Password</label>
                            <input value={confirmPassword} maxLength={49}  onChange={(e) => setConfirmPassword(e.target.value)} type="password" />
                        </div>
                    </div>
                    <h5>Organization Information</h5>
                    <div>
                        <div>
                            <label>Organization Name</label>
                            <input value={organizationName} maxLength={99}  onChange={(e) => setOrganizationName(e.target.value)} type="text" />
                        </div>
                        <div>
                            <label>Organization Description</label>
                            <input value={organizationDescription} maxLength={248}  onChange={(e) => setOrganizationDescription(e.target.value)} type="text" />
                        </div>
                    </div>
                    <div className="signup-buttons-container">
                    <button
        className='signup-buttons-signup'
        onClick={handleSignUp}
        disabled={isButtonDisabled}
        style={ {backgroundColor: isButtonDisabled ? 'grey' : 'var(--pure-white)',
            color: isButtonDisabled ? 'white' : 'var(--light-blue)',
            cursor: isButtonDisabled ? 'not-allowed' : 'pointer'}}
    >
        Next
    </button>

                        <button className='signup-buttons-cancel' onClick={(e) => window.location.href='/login'}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <h1 className="only-show-on-small-devices">For the best experience, please use a larger device to initiate the signup process, as the following screens are optimized for larger displays.</h1>

        </>
    );
}

export default SignUp;
